import { Mutation, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import { store } from "@/store";
import { userModule } from "./user";
import { addMobTimeOfDeath, loginPost, logoutPost } from "../../libs/serverProxy"
import { settingsModule } from "./settings";
import { worldModule } from "./world";
import { client } from "@/libs/GroupClient";
import { DEFAULTZONE, Zone, zones } from "ffxivhuntdata";
import Vue from "vue";
import { fieldinstancesModule } from "./fieldinstances";
import { overlaySettingsModule } from "./overlaySettings";
import { ICharacter, ILocationNotifiedEvent, MobStateChangedEvent } from "@/libs/centurionEvents";
import { IMobTimeOfDeathAddRequestPayload } from "../../../../src/interfaces/mobtods";

const DEFAULTPLAYER: ICharacter = {
    jobId: 0,
    level: 0,
    worldID: 0,
    worldName: '',
    currentHp: 0,
    maxHp: 0,
    hpp: 0,
    partyType: 0,
    order: 0,
    posX: 0,
    posY: 0,
    posZ: 0,
    x: 0,
    y: 0,
    z: 0,
    heading: 0,
    distance: 0,
    id: 0,
    name: '',
    ownerId: 0,
    targetId: 0,
    bNpcId: 0,
    bNpcNameId: 0,
    type: 0
}

export interface NotifiedLocation {
    zoneId: number;
    x: number;
    y: number;
    nearMobLocationIndex: number;
    timestamp: Date;
}

interface IMapLocation {
    zoneId: number;
    x: number;
    y: number;
}

interface IElite {
    id: number;
    mobId: number;
    zoneId: number;
    instance: number;
    locationIndex: number;
    addedDate: Date;
    lost: boolean;
}

// state's interface
export interface IOverlayState {
    playerName: string;
    player: ICharacter;
    party: ICharacter[];
    targets: ICharacter[];
    worldId: number;
    zoneId: number;
    instance: number;
    location: {
        x: number;
        y: number;
        z: number;
        heading: number;
    };
    elites: {
        [id: number]: IElite
    };
    eliteStateChangedEvents: MobStateChangedEvent[];
    notifiedLocations: NotifiedLocation[];
    lastNotifiedLocation: IMapLocation;
    zoneChangedAt: number;
    refreshedAt: number;
    absenceStartedAtMap: { [index: number]: Date };
}

const name = `overlay`

/*
try {
  store.unregisterModule(name)
} catch (error) {
  console.warn(`ignore ${error}`)
}
*/

@Module({ dynamic: true, store, name, namespaced: true, preserveState: false })
class OverlayState extends VuexModule implements IOverlayState {
    // state
    playerName = "";
    player = DEFAULTPLAYER;
    party: ICharacter[] = [];
    targets: ICharacter[] = [];
    worldId = 0;
    zoneId = 0;
    instance = 0;
    location = {
        x: 0,
        y: 0,
        z: 0,
        heading: 0
    };
    elites: {
        [id: number]: IElite
    } = {};
    eliteStateChangedEvents: MobStateChangedEvent[] = [];
    notifiedLocations: NotifiedLocation[] = [];
    lastNotifiedLocation: IMapLocation = { zoneId: 0, x: 0, y: 0 };
    zoneChangedAt = Date.now();
    refreshedAt = this.zoneChangedAt;
    absenceStartedAtMap: { [index: number]: Date } = {};

    public get zone(): Zone {
        const zo = zones.find(z => z.id === this.zoneId);
        return zo ?? DEFAULTZONE;
    }

    public get fieldInstanceIcon(): string {
        return fieldinstancesModule.getInstanceCount(this.zoneId) > 1 && this.instance > 0 ? `mdi-numeric-${this.instance}-box` : '';
    }

    public get displayFieldInstanceWarning(): boolean {
        return fieldinstancesModule.getInstanceCount(this.zoneId) > 1 &&
            this.instance === 0 &&
            this.refreshedAt > this.zoneChangedAt + 5000;
    }

    // mutation
    @Mutation
    public SET_PLAYER_NAME(name: string) {
        this.playerName = name;
    }

    @Mutation
    public SET_PLAYER(player: ICharacter | null) {
        this.player = player != null ? player : DEFAULTPLAYER;
    }

    @Mutation
    public SET_PARTY(party: ICharacter[]) {
        this.party = party;
    }

    @Mutation
    public SET_TARGETS(targets: ICharacter[]) {
        this.targets = targets;
    }

    @Mutation
    public SET_WORLD_ID(value: number) {
        this.worldId = value;
    }

    @Mutation
    public SET_ZONE_ID(value: number) {
        this.zoneId = value;
        this.zoneChangedAt = Date.now();
    }

    @Mutation
    public REFRESH() {
        this.refreshedAt = Date.now();
    }

    @Mutation
    public SET_INSTANCE(value: number) {
        this.instance = value;
    }

    @Mutation
    public SET_LOCATION(payload: { x: number, y: number, z: number, heading: number }) {
        this.location = payload;
    }

    @Mutation
    public SET_ELITE(elite: IElite) {
        Vue.set(this.elites, elite.id, elite);
    }

    @Mutation
    public DELETE_ELITE(eliteId: number) {
        Vue.delete(this.elites, eliteId);
    }

    @Mutation
    public CLEAR_ELITES() {
        Vue.set(this, "elites", {});
    }

    @Mutation
    public SET_ELITE_STATE_CHANGED_EVENTS(events: MobStateChangedEvent[]) {
        Vue.set(this, "eliteStateChangedEvents", events);
    }

    @Mutation
    public ADD_ELITE_STATE_CHANGED_EVENT(event: MobStateChangedEvent) {
        this.eliteStateChangedEvents.push(event);
    }

    @Mutation
    public SET_NOTIFIED_LOCATIONS(notifiedLocations: NotifiedLocation[]) {
        Vue.set(this, "notifiedLocations", notifiedLocations);
    }

    @Mutation
    public SET_LAST_NOTIFIED_LOCATION(location: IMapLocation) {
        this.lastNotifiedLocation = location;
    }

    @Mutation
    public SET_ABSENCE_STARTED_AT_MAP(value: { [index: number]: Date }) {
        Vue.set(this, "absenceStartedAtMap", value);
    }

    @Action({ rawError: true })
    public async login(playerName: string) {
        /*
        this.SET_WORLD_ID(payload.worldId);
        this.SET_ZONE_ID(payload.zoneId);
        this.SET_INSTANCE(payload.instance);
        settingsModule.SET_DISPLAY_MOBMAPS(payload);

        worldModule.setWorld(payload.worldId);*/
        this.SET_PLAYER_NAME(playerName);
        const token = overlaySettingsModule.getToken(playerName) ?? "";
        if (token != "") {
            const newUser = await loginPost(token);
            userModule.loadUser(newUser);
        }
    }

    @Action({ rawError: true })
    public async logout() {
        this.SET_WORLD_ID(0);
        this.SET_ZONE_ID(0);
        this.SET_ELITE_STATE_CHANGED_EVENTS([]);
        this.CLEAR_ELITES();
        this.SET_INSTANCE(0);
        this.SET_PLAYER_NAME("");
        this.SET_PLAYER(DEFAULTPLAYER);
        const nullUser = await logoutPost();
        userModule.loadUser(nullUser);
    }

    @Action({ rawError: true })
    public async setZone(payload: { worldId: number, zoneId: number }) {
        this.SET_WORLD_ID(payload.worldId);
        this.SET_ZONE_ID(payload.zoneId);
        this.SET_ELITE_STATE_CHANGED_EVENTS([]);
        this.CLEAR_ELITES();
        const instance = fieldinstancesModule.getInstanceCount(this.zoneId) === 1 ? 1 : 0;
        this.SET_INSTANCE(instance);
        settingsModule.SET_DISPLAY_MOBMAPS({ worldId: this.worldId, zoneId: this.zoneId, instance });

        const worldChanged = await worldModule.setWorld(payload.worldId)
        if (!worldChanged) {
            client.updateZoneInstances();
        }
    }

    @Action({ rawError: true })
    public async setZoneInstance(instance: number) {
        this.SET_INSTANCE(instance);
        settingsModule.SET_DISPLAY_MOBMAPS({ worldId: this.worldId, zoneId: this.zoneId, instance });
        client.updateZoneInstances();

        // elitesを全操作
        Object.values(this.elites).filter(elite => elite.instance === 0).forEach((elite) => {
            elite.instance = instance;
            console.log(`mobId: ${elite.mobId}, instance: ${elite.instance}`);
            this.SET_ELITE(elite);
            this.processEliteStateChangedEvents(elite);
        });
        // instance が 0 のもの以外はスルー
        // instance が 0 の instance を 更新
        // elite の instance が 1 以上 かつ index が 0 以上だったら、processEventsを実行
    }

    @Action({ rawError: true })
    public async notifyLocation(item: ILocationNotifiedEvent) {
        if (!overlaySettingsModule.posNotify) {
            //ignore
            return;
        }
        const zone = zones.find(z => z.name === item.zoneName);
        if (zone) {
            this.SET_LAST_NOTIFIED_LOCATION({ zoneId: zone.id, x: item.x, y: item.y });
            const index = zone.getEliteLocationIndex(item, 1.0);
            const a = {
                zoneId: zone.id, x:
                    item.x, y: item.y, nearMobLocationIndex: index, timestamp: new Date()
            }
            // 今回のPointに近い過去報告Pointは削除(連続報告の対策)
            const notifiedLocations = this.notifiedLocations.filter(v => {
                const distance = Math.sqrt((v.x - item.x) * (v.x - item.x) +
                    (v.y - item.y) * (v.y - item.y));
                return distance > 1.0
            });
            notifiedLocations.push(a);
            this.SET_NOTIFIED_LOCATIONS(notifiedLocations);
        }
        else {
            //console.debug('ignore NotifiedLocation');
        }
    }

    @Action({ rawError: true })
    public async refreshNotifiedLocations() {
        // 一定時間過ぎたPointをリストから削除
        const now = new Date();
        now.setSeconds(now.getSeconds() - overlaySettingsModule.posNotifyMarkingSeconds); //5 minutes
        this.SET_NOTIFIED_LOCATIONS(this.notifiedLocations.filter(v => {
            return (v.timestamp.getTime() > now.getTime());
        }));
    }

    @Action({ rawError: true })
    public async refresh() {
        this.REFRESH();
    }

    @Action({ rawError: true })
    public async updateNotifiedLocations(payload: { zoneId: number, targets: ICharacter[] }) {
        // Index付きPointにEliteが実在していたらリストから削除(別途管理するため)
        // 毎描画ごとに更新
        const zone = zones.find(z => z.id === payload.zoneId);
        if (zone) {
            const eliteMobIds = zone.mobs.filter(mob => mob.type === 'elite').map(mob => mob.id);
            const targetIndexList = payload.targets.filter(target => eliteMobIds.includes(target.bNpcNameId)).map(
                target => zone.getEliteLocationIndex(target));
            this.SET_NOTIFIED_LOCATIONS(this.notifiedLocations.filter(v => {
                return v.zoneId != payload.zoneId || !targetIndexList.includes(v.nearMobLocationIndex);
            }));
        }
    }

    @Action({ rawError: true })
    public async processEliteState(ev: MobStateChangedEvent) {
        if (!ev.mob || ev.mob.type !== 'elite') {
            return;
        }

        let elite: IElite = this.elites[ev.id];
        if (!elite) {
            elite = {
                id: ev.id,
                mobId: ev.mobId,
                zoneId: ev.zoneId,
                instance: fieldinstancesModule.getInstanceCount(ev.zoneId) > 1 ? 0 : 1,
                locationIndex: ev.locationIndex,
                addedDate: new Date(),
                lost: ev.state === "Lost"
            };
            this.SET_ELITE(elite);
        }
        // validation
        if (elite.mobId !== ev.mobId || elite.zoneId !== ev.zoneId) {
            const msg = `Mismatch in MobStateChangedEvent and stored elite`
            console.error(msg, elite.mobId, ev.mobId, elite.zoneId, ev.zoneId);
            throw msg;
        }

        if (elite.instance > 0 && elite.locationIndex >= 0) {
            // キューイングせずに即時処理
            // 1, "Find", "Killed" ならsetRecordを実行
            // 2, "Killed" かつ S,A ならToDEventを発行

            if (ev.state === 'Found') {
                worldModule.setRecord({
                    zoneId: ev.zoneId,
                    instance: elite.instance,
                    index: elite.locationIndex,
                    record: {
                        date: ev.timestamp,
                        mobId: ev.mobId,
                        state: 100
                    }
                });
                elite.lost = false;
                this.SET_ELITE(elite);
            }
            else if (ev.state === 'Killed') {
                worldModule.setRecord({
                    zoneId: ev.zoneId,
                    instance: elite.instance,
                    index: elite.locationIndex,
                    record: {
                        date: ev.timestamp,
                        mobId: ev.mobId,
                        state: 101
                    }
                });
                if (ev.mob && overlaySettingsModule.notifyHuntNet.includes(ev.mob.rank)) {
                    const payload: IMobTimeOfDeathAddRequestPayload = {
                        targetId: ev.id,
                        worldId: ev.worldId,
                        zoneId: ev.zoneId,
                        instance: elite.instance,
                        mobId: ev.mobId,
                        index: elite.locationIndex
                    };
                    addMobTimeOfDeath(payload);
                }
                this.DELETE_ELITE(ev.id);
            }
            else if (ev.state === 'Lost') {
                elite.lost = true;
                this.SET_ELITE(elite);
            }
        }
        else {
            // いったんキューに詰む
            this.ADD_ELITE_STATE_CHANGED_EVENT(ev);
        }
    }

    @Action({ rawError: true })
    public async refreshElites() {
        if (fieldinstancesModule.getInstanceCount(this.zoneId) === 1) {
            //instance of the elites should be 1
            return;
        }
        if (this.instance === 0) {
            //instance not fixed yet
            return;
        }
        const currentInstance = this.instance;
        // elitesを全操作
        // instance が 0 のもの以外はスルー
        // instance が 0 でも Add から一定期間が過ぎていないものはスルー
        // instance が 0 で Add から一定期間過ぎたものに currentInstance を割り当てる
        // elite の instance が 1 以上 かつ index が 0 以上だったら、processEventsを実行
        // elitesを全操作
        const now = new Date();
        Object.values(this.elites).filter(elite => elite.instance === 0 && elite.addedDate.getTime() + 2000 < now.getTime()).forEach((elite) => {
            elite.instance = currentInstance;
            console.log(`mobId: ${elite.mobId}, instance: ${elite.instance}`);
            this.SET_ELITE(elite);
            this.processEliteStateChangedEvents(elite);
        });
    }

    @Action({ rawError: true })
    public async updateEliteLocationIndex(payload: { id: number, zoneId: number, mobId: number, locationIndex: number }) {
        const elite = this.elites[payload.id];
        if (!elite) {
            throw `elite ${payload.id} does not exist`;
        }
        if (elite.locationIndex === payload.locationIndex) {
            return; // Already up to date
        }
        else if (elite.locationIndex === -1) {
            elite.locationIndex = payload.locationIndex;
            this.SET_ELITE(elite);
            this.processEliteStateChangedEvents(elite);
        }
        else { // elite.locationIndex changed
            throw `elite ${payload.id} location mismatch ${elite.locationIndex} and ${payload.locationIndex}`;
        }
    }

    @Action({ rawError: true })
    public async processEliteStateChangedEvents(elite: IElite) {
        // validation
        if (elite.locationIndex >= 0 && elite.instance > 0) {
            const eventsToBeProcessed = this.eliteStateChangedEvents.filter(ev => ev.id === elite.id);
            const eventsToBeRemained = this.eliteStateChangedEvents.filter(ev => ev.id !== elite.id);
            this.SET_ELITE_STATE_CHANGED_EVENTS(eventsToBeRemained);
            eventsToBeProcessed.forEach(ev => {
                if (ev.state === 'Found') {
                    worldModule.setRecord({
                        zoneId: ev.zoneId,
                        instance: elite.instance,
                        index: elite.locationIndex,
                        record: {
                            date: ev.timestamp,
                            mobId: ev.mobId,
                            state: 100
                        }
                    });
                }
                else if (ev.state === 'Killed') {
                    worldModule.setRecord({
                        zoneId: ev.zoneId,
                        instance: elite.instance,
                        index: elite.locationIndex,
                        record: {
                            date: ev.timestamp,
                            mobId: ev.mobId,
                            state: 101
                        }
                    });
                    if (ev.mob && overlaySettingsModule.notifyHuntNet.includes(ev.mob.rank)) {
                        const payload: IMobTimeOfDeathAddRequestPayload = {
                            targetId: ev.id,
                            worldId: ev.worldId,
                            zoneId: ev.zoneId,
                            instance: elite.instance,
                            mobId: ev.mobId,
                            index: elite.locationIndex
                        };
                        addMobTimeOfDeath(payload);
                    }
                    this.DELETE_ELITE(ev.id);
                }
            })
        }
    }

    @Action({ rawError: true })
    public async refreshAbsence() {
        if (this.instance === 0) {
            return;
        }
        const threshold = 1.7;
        const now = new Date();
        const zone = zones.find(zone => zone.id === this.zoneId);
        if (zone) {
            const absenceMap: { [index: number]: Date } = {};
            zone.mobLocations.forEach((loc, index) => {
                // 範囲外は除外
                if (loc.type !== "elite" ||
                    threshold < Math.abs(loc.x - this.player.x) ||
                    threshold < Math.abs(loc.y - this.player.y)) {
                    return;
                }
                // そのindexで認識しているeliteがいたら除外
                const liveElite = Object.values(this.elites).find(elite => {
                    return elite.zoneId === this.zoneId &&
                        elite.instance === this.instance &&
                        elite.locationIndex === index &&
                        !elite.lost
                });
                if (liveElite) {
                    return;
                }
                // そのindexのLocationRecordの最新がstate2000だったら除外
                const reports = worldModule.getLocationRecord(this.zoneId, this.instance, index).reports;
                if (reports.length > 0 && reports[0].state === 2000) {
                    return;
                }
                absenceMap[index] = this.absenceStartedAtMap[index] ?? now;
            });
            // 3秒経っていたら、setAbsenceを発行して除外
            Object.keys(absenceMap).map(Number).forEach(index => {
                if (absenceMap[index].getTime() + 3000 < now.getTime()) {
                    worldModule.setAbsence({
                        zoneId: this.zoneId,
                        instance: this.instance,
                        index: index,
                        absenceAt: now
                    })
                    delete absenceMap[index];
                }
            });
            this.SET_ABSENCE_STARTED_AT_MAP(absenceMap);
        }
        // 索敵範囲のmobLocationのうち、不在が確認できたIndexをリスト
        // absenceStartedAtMapを更新する


        // そのIndexのStartedAtMapから2秒以上経過していたら
        // getLocationRecordでリストを取得して、その最新データ(0番目)が自身のMob不在レコードでなければ、
        // 不在を発行する
    }

}

export const overlayModule = getModule(OverlayState);