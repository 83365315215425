import { Mutation, VuexModule, getModule, Module, Action } from "vuex-module-decorators";
import { store } from "@/store";
import Vue from "vue";
import { settingsModule } from "./settings";
import { client } from "@/libs/GroupClient";

interface IGroupCandidate {
  id: string,
  name: string,
  iconUrl: string
}

export interface IUserState {
  id: string; // Mongo ObjectId
  type: string; // "discord" or "token"
  userId: string; // dicord user id or token
  userName: string; // discord username or guest name
  avatarUrl: string; // discord avatarURL or groups dircord iconURL
  group: string;
  isAdmin: boolean;
  isValid: boolean;
  groups: IGroupCandidate[];
  socket: string;
}

const name = `user`

/*
try {
  store.unregisterModule(name)
} catch (error) {
  console.warn(`ignore ${error}`)
}
*/

@Module({ dynamic: true, store, name, namespaced: true, preserveState: false })
class UserState extends VuexModule implements IUserState {
  // state
  id = "";
  type = "";
  userId = "";
  userName = "";
  avatarUrl = "";
  group = "";
  isAdmin = false;
  isValid = false;
  groups: IGroupCandidate[] = [];
  socket = "";

  public get groupName(): string {
    const group = this.groups.find(g=>g.id === this.group);
    return group ? group.name : '';
  }

  // mutation
  @Mutation
  public SET_USER(newUser: IUserState) {
    Vue.set(this, "id", newUser.id)
    Vue.set(this, "type", newUser.type)
    Vue.set(this, "userId", newUser.userId)
    Vue.set(this, "userName", newUser.userName)
    Vue.set(this, "avatarUrl", newUser.avatarUrl)
    Vue.set(this, "group", newUser.group)
    Vue.set(this, "isAdmin", newUser.isAdmin)
    Vue.set(this, "isValid", newUser.isValid)
    Vue.set(this, "groups", newUser.groups)
  }

  @Mutation
  public SET_GROUP(value: string) {
    Vue.set(this, "group", value)
  }

  @Mutation
  public SET_SOCKET(value: string) {
    Vue.set(this, "socket", value)
  }

  @Action({ rawError: true })
  public selectGroup(value: string) {
    if (this.type == "token") {
      return; // group is fixed for token user
    }
    if (this.groups.map(g => g.id).includes(value)) {
      this.SET_GROUP(value);
      client.connect();
      settingsModule.SET_PREFERRED_GROUP(value);
    }
    else {
      this.SET_GROUP("");
      client.disconnect();
      settingsModule.SET_PREFERRED_GROUP("");

    }
  }

  @Action({ rawError: true })
  public loadUser(newUser: IUserState) {
    this.SET_USER(newUser);
    if (this.type == "token") {
      this.SET_GROUP(this.group);
      client.connect();
      settingsModule.SET_PREFERRED_GROUP(this.group)
    }
    else if (this.groups.map(g => g.id).includes(settingsModule.preferredGroup)) {
      this.SET_GROUP(settingsModule.preferredGroup);
      client.connect();
    }
    else {
      settingsModule.SET_PREFERRED_GROUP("");
      client.disconnect();
    }
  }

}

export const userModule = getModule(UserState);