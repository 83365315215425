

















































































import Vue from "vue";
import { login, logout } from "@/libs/serverProxy";
import { userModule } from "@/store/modules/user";
import { sharelocationrecordsModule, UserCache } from "@/store/modules/sharelocationrecords";
import { settingsModule } from "@/store/modules/settings";
interface Data {
  menu: boolean;
  group: string;

}

export default Vue.extend({
  data(): Data {
    return {
      menu: false,
      group: "",
    }
  },
  async mounted() {
    this.group = settingsModule.preferredGroup;
  },
  computed: {
    username(): string {
      return userModule.userName;
    },
    isLoggedIn(): boolean {
      console.debug("loggedIn", userModule.id)
      return userModule && userModule.id != "";
    },
    thumbnail(): string {
      return userModule.id != "" ? userModule.avatarUrl != '' ? userModule.avatarUrl : this.defaultUserPng : this.loginPng;
    },
    groupIconUrl(): string {
      if (userModule.group != "") {
        const groupItem = userModule.groups.find(g => g.id == userModule.group);
        if (groupItem) {
          return groupItem.iconUrl != "" ? groupItem.iconUrl : this.defaultGroupPng
        }
      }
      return "";
    },
    groupItems(): { text: string, value: string }[] {
      const list = userModule.groups.map(g => { return { value: g.id, text: g.name, iconUrl: g.iconUrl } });
      list.unshift({
        value: "",
        text: this.$t('doesNotSpecify') as string,
        iconUrl: ""
      })
      return list;
    },
    showGroupItems(): boolean {
      return userModule.groups.length > 0;
    },
    isDiscordUser(): boolean {
      return userModule.type == 'discord'
    },
    members(): UserCache[] {
      return sharelocationrecordsModule.getMembers;
    },
    defaultGroupPng(): string {
      return `${window.location.origin}/defaultGroup.png`;
    },
    defaultUserPng(): string {
      return `${window.location.origin}/defaultUser.png`;
    },
    loginPng(): string {
      return `${window.location.origin}/login.png`;
    },

  },
  methods: {
    async login() {
      login()
    },
    async logout() {
      logout()
    },
    userSettings() {
      console.debug("user settings clicked")
    },
    selectGroup() {
      console.debug("group selected", this.group)
      userModule.selectGroup(this.group)
    }
  },
})
