import { Mutation, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import { store, canPreserveDefault } from "@/store";
import Vue from "vue";

// state's interface
export interface IIndexAbsenceMap {
    [index: number]: string;
}

export interface IZoneInstanceIndexMap {
    [zoneInstance: string]: IIndexAbsenceMap;
}

export interface ILocationAbsenceState {
    vrs: string;
    [worldId: number]: IZoneInstanceIndexMap;
}

const name = 'locationabsence';
const vrs = '0.1';

/*
try {
    store.unregisterModule(name)
} catch (error) {
    console.warn(`ignore ${error}`)
}
*/

@Module({ dynamic: true, store, name, namespaced: true, preserveState: canPreserveDefault(name, vrs, "locations") })
class LocationAbsenceState extends VuexModule implements ILocationAbsenceState {
    // state
    vrs = vrs;
    [worldId: number]: IZoneInstanceIndexMap;

    // getter with params
    public get getAbsenceAt() {
        return (worldId: number, zoneId: number, instance: number, index: number): string | undefined => {
            const zoneInstanceIndexMap = this[worldId];
            if (zoneInstanceIndexMap) {
                const indexAbsenceMap = zoneInstanceIndexMap[`${zoneId}_${instance}`];
                if (indexAbsenceMap) {
                    return indexAbsenceMap[index];
                }
            }
            return undefined;
        }
    }

    public get getZoneInstanceIndexMap() {
        return (worldId: number): IZoneInstanceIndexMap => {
            return this[worldId] ?? {};
        }
    }

    public get getIndexAbsenceMap() {
        return (worldId: number, zoneId: number, instance: number): IIndexAbsenceMap => {
            const zoneInstanceIndexMap = this[worldId];
            if (zoneInstanceIndexMap) {
                return zoneInstanceIndexMap[`${zoneId}_${instance}`] ?? {}
            }
            return {};
        }
    }

    // mutation
    @Mutation
    public SET_ABSENCE_AT(payload: { worldId: number, zoneId: number, instance: number, index: number, absenceAt: Date }) {
        if (!this[payload.worldId]) {
            Vue.set(this, payload.worldId, {})
        }
        const zoneInstance = `${payload.zoneId}_${payload.instance}`;
        if (!this[payload.worldId][zoneInstance]) {
            Vue.set(this[payload.worldId], zoneInstance, {})
        }
        Vue.set(this[payload.worldId][zoneInstance], payload.index, payload.absenceAt.toISOString());
    }
/*
    @Mutation
    public SET_RECORDS_OF_WORLD(payload: { worldId: number, records: IZoneInstanceIndexMap }) {
        Vue.set(this, payload.worldId, payload.records)
        if (Object.keys(this[payload.worldId]).length == 0) {
            Vue.delete(this, payload.worldId);
        }
    }

    @Mutation
    public SET_RECORDS_OF_ZONE_INSTANCE(payload: { worldId: number, zoneId: number, instance: number, records: IIndexRecordMap }) {
        if (!this[payload.worldId]) {
            Vue.set(this, payload.worldId, {})
        }
        const zoneInstance = `${payload.zoneId}_${payload.instance}`;
        Vue.set(this[payload.worldId], zoneInstance, payload.records)
        if (Object.keys(this[payload.worldId][zoneInstance]).length == 0) {
            Vue.delete(this[payload.worldId], zoneInstance);
            if (Object.keys(this[payload.worldId]).length == 0) {
                Vue.delete(this, payload.worldId);
            }
        }
    }
*/
    @Mutation
    public DELETE_ABSENCE_AT(payload: { worldId: number, zoneId: number, instance: number, index: number }) {
        const zoneInstance = `${payload.zoneId}_${payload.instance}`;
        if (this[payload.worldId] && this[payload.worldId][zoneInstance]) {
            Vue.delete(this[payload.worldId][zoneInstance], payload.index);
            if (Object.keys(this[payload.worldId][zoneInstance]).length == 0) {
                Vue.delete(this[payload.worldId], zoneInstance);
                if (Object.keys(this[payload.worldId]).length == 0) {
                    Vue.delete(this, payload.worldId);
                }
            }
        }
    }

    @Mutation
    public DELETE_ALL() {
        Object.keys(this).filter(key => key != 'vrs').map(Number).forEach(key => {
            Vue.delete(this, key)
        })
    }

    // actions
    @Action({})
    public async deleteAll() {
        // TBD
    }

}

export const locationabsenceModule = getModule(LocationAbsenceState);