import axios from "axios";
import dayjs from "dayjs";
import { EOPeriod, getCroakadilePeriod, getGarlokPeriods, getLaidoronnettePeriods, getMindflayerPeriod, getOkinaPeriods, getBurfurlurPeriods, IMob, IZone, zones } from "ffxivhuntdata";
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import { resolveWorld } from "./huntdataresolver";
import { IUserState } from "@/store/modules/user";
import { IGroupAddPayload, IGroupGetPayload, IGroupPayload } from "../../../src/interfaces/groups"
import { IMobTimeOfDeathAddRequestPayload, IMobTimeOfDeathPayload } from "../../../src/interfaces/mobtods"
import { IGuild, IGuildInfo } from "../../../src/interfaces/discord";
import { IMobRecordPayload } from "../../../src/interfaces/smobrecords";
import { IAssassinationPayload, ISetAssassinationPayload } from "../../../src/interfaces/assassinations";
import { ILocationClusterPayload, IMobLocationPayload } from "../../../src/interfaces/moblocations";
dayjs.extend(duration);
dayjs.extend(relativeTime);

const client = axios.create({
  baseURL: "/api/",
  headers: {
    "Content-Type": "application/json"
  }
})

export const NULL_USER: IUserState = {
  id: "",
  type: "",
  userId: "",
  userName: "",
  avatarUrl: "",
  group: "",
  isAdmin: false,
  isValid: false,
  groups: [],
  socket: ""
}

export interface FieldInstance {
  zoneId: number;
  instanceCount: number;
}
/*
export interface ISMobRecordPayload {
  worldId: number;
  mobId: number;
  instance: number;
  serverReset: string;
  respawnStart: string;
  respawnEnd: string;
  lastKilled: string;
  hitBlackList: boolean;
}
*/
export interface ISMobRecord {
  worldId: number;
  mobId: number;
  zone?: IZone;
  mob?: IMob;
  instance: number;
  serverReset: Date;
  respawnStart: Date;
  respawnEnd: Date;
  lastKilled: Date;
  assassinated: Date;
  hitBlackList: boolean;

  id: string;
  afterServerReset: boolean;
  isDefault: boolean;
  isAssassinated: boolean;
  isLeftLong: boolean;
  isKilledRecent: boolean;
  isFixedSpawn: boolean;
  chance: number;
  formattedWorld: string;
  formattedMobHtml: string;
  spawnComment: SpawnComment;
  timeToStart: string;
  compare(another: ISMobRecord): number;
  containsWord(word: string): boolean;
}

class Duration {
  toStart: number;
  toEnd: number;
  chance: number;
  type: number;
  key: number;
  constructor(record: ISMobRecord) {
    const now = dayjs();
    this.toStart = now.diff(record.respawnStart);
    this.toEnd = now.diff(record.respawnEnd);
    this.chance = record.chance;
    this.type = now.isAfter(record.respawnEnd) ? 0 : // 100% over
      now.isAfter(record.respawnStart) ? 1 : // 1-99%
        2; // 0%
    this.key = record.worldId;
  }

  keySort(another: Duration) {
    return this.key < another.key ? -1 : 1
  }

  compare(another: Duration) {
    if (this.type != another.type) {
      return this.type < another.type ? -1 : 1;
    }
    if (this.type == 0) {
      return this.toEnd == another.toEnd ?
        this.keySort(another) : this.toEnd > another.toEnd ? -1 : 1
    }
    else if (this.type == 1) {
      return this.chance == another.chance ?
        this.keySort(another) : this.chance > another.chance ? -1 : 1
    }
    else {
      return this.toStart == another.toStart ?
        this.keySort(another) : this.toStart > another.toStart ? -1 : 1
    }
  }
}
interface SpawnComment {
  fixedDate?: Date;
  lines: string[];
}

export class SMobRecord implements ISMobRecord {
  worldId: number;
  zone?: IZone;
  mobId: number;
  mob?: IMob;
  instance: number;
  serverReset: Date;
  assassinated: Date;
  lastKilled: Date;
  respawnStart: Date;
  respawnEnd: Date;

  constructor(payload: IMobRecordPayload) {
    this.worldId = payload.worldId;
    this.mobId = payload.mobId;
    this.zone = zones.find(zone => zone.id === payload.zoneId);
    this.mob = this.zone && this.zone.mobs.find(mob => mob.id === payload.mobId);
    this.instance = payload.instance;
    this.serverReset = new Date(payload.serverReset);
    this.lastKilled = new Date(payload.lastKilled);
    this.assassinated = new Date(payload.assassinated);

    const respawnMin = this.mob ? this.mob.respawnMin : 0;
    const respawnMax = this.mob ? this.mob.respawnMax : 0;
    this.respawnStart = new Date(Math.max(this.serverReset.getTime(), this.lastKilled.getTime(), this.assassinated.getTime()));
    this.respawnStart.setMinutes(
      this.respawnStart.getMinutes() + respawnMin * this.respawnTimeRate
    );
    this.respawnEnd = new Date(Math.max(this.serverReset.getTime(), this.lastKilled.getTime(), this.assassinated.getTime()));
    this.respawnEnd.setMinutes(
      this.respawnEnd.getMinutes() + respawnMax * this.respawnTimeRate
    );
    this.hitBlackList = payload.hitBlackList;

    this.isFixedSpawn = false;
    this.spawnComment = {
      fixedDate: undefined,
      lines: []
    }
    const now = new Date();
    if (this.mob?.id == 2963 || this.mob?.id == 2955) {
      // Croakadile, Mindflayer
      const period = this.mob?.id == 2963 ?
        getCroakadilePeriod(this.respawnStart) :
        getMindflayerPeriod(this.respawnStart);
      const maxDate = period.start.toDate().getTime() > this.respawnStart.getTime() ? period.start.toDate() : this.respawnStart
      this.respawnStart = period.start.toDate();
      this.respawnEnd = maxDate;
      this.isFixedSpawn = (this.respawnStart.getTime() == this.respawnEnd.getTime());
      if (this.isFixedSpawn) {
        this.spawnComment.fixedDate = this.respawnEnd
      }
    }
    else if (this.mob?.id == 5984 || this.mob?.id == 2964 || this.mob?.id == 2953 || this.mob?.id == 10617) {
      // Okina, Garlok, Laidoronnette
      const spawnPeriod = new EOPeriod(this.respawnStart, this.respawnEnd);
      const periods = this.mob?.id == 10617 ?
        getBurfurlurPeriods(spawnPeriod) : this.mob?.id == 5984 ?
          getOkinaPeriods(spawnPeriod) : this.mob?.id == 2964 ?
            getGarlokPeriods(spawnPeriod) :
            getLaidoronnettePeriods(spawnPeriod);
      if (periods.length == 1 || periods[periods.length - 2].end.toDate() < now) {
        const lastRange = periods[periods.length - 1];
        if (this.respawnEnd <= lastRange.start.toDate()) {
          // Fixed spawn
          this.respawnStart = lastRange.start.toDate();
          this.respawnEnd = lastRange.start.toDate();
        }
        else if (this.respawnEnd <= now) {
          // Fixed spawn
          this.respawnStart = this.respawnEnd;
          //this.respawnEnd = this.respawnEnd;
        }
        this.isFixedSpawn = (this.respawnStart.getTime() == this.respawnEnd.getTime());
        if (this.isFixedSpawn) {
          this.spawnComment.fixedDate = this.respawnEnd
        }
      }
      // Generate spawn comment
      const periodComments: { past: boolean, line: string }[] = periods.map(period => {
        const chanceAtStart = spawnPeriod.calculateChanceAt(period.start);
        const chanceAtEnd = spawnPeriod.calculateChanceAt(period.end);
        let past = false;
        if (chanceAtStart < chanceAtEnd) {
          let line = `[${dayjs(period.start.toDate()).format('MM/DD(ddd) HH:mm')}<em>(${chanceAtStart.toFixed(1)}%)</em> - ${dayjs(period.end.toDate()).format('HH:mm')}<em>(${chanceAtEnd.toFixed(1)}%)</em>]`
          if (period.start.toDate().getTime() < now.getTime() &&
            now.getTime() < period.end.toDate().getTime()) {
            line = `<b>${line}</b>`
          }
          else if (period.end.toDate().getTime() < now.getTime()) {
            past = true;
            line = `<span style="text-decoration: line-through">${line}</span>`
          }
          return { past, line }
        }
        else {
          let line = `[${dayjs(period.start.toDate()).format('MM/DD(ddd) HH:mm')}<em>(${chanceAtStart.toFixed(1)}%)</em>]`
          if (period.start.toDate().getTime() < now.getTime()) {
            line = `<b>${line}</b>`
          }
          return { past, line }
        }
      })
      this.spawnComment.lines = periodComments.filter(pc => !pc.past).map(pc => pc.line);
    }
  }
  hitBlackList: boolean;

  get id(): string {
    return `${this.worldId}_${this.mobId}_${this.instance}`;
  }

  get respawnTimeRate(): number {
    return this.serverReset.getTime() >= Math.max(this.lastKilled.getTime(), this.assassinated.getTime()) ? 0.6 : 1.0;
  }

  get afterServerReset(): boolean {
    return this.lastKilled.getTime() <= this.serverReset.getTime()
  }

  get baseDate(): string {
    let time = this.assassinated.getTime();
    let result = 'Assassinated';
    if (this.serverReset.getTime() >= time) {
      result = 'ServerReset'
      time = this.serverReset.getTime()
    }
    if (this.lastKilled.getTime() >= time) {
      result = 'LastKilled'
      time = this.lastKilled.getTime()
    }
    return result;
  }

  get isAssassinated(): boolean {
    return this.assassinated.getTime() >= Math.max(this.lastKilled.getTime(), this.serverReset.getTime())
  }

  get isDefault(): boolean {
    return dayjs(this.respawnEnd).add(5, 'hour').isAfter(new Date());
  }
  get isLeftLong(): boolean {
    return dayjs(this.respawnEnd).add(5, 'hour').isBefore(new Date());
  }
  get isKilledRecent(): boolean {
    return dayjs(this.lastKilled).add(3, 'hour').isAfter(new Date());
  }
  isFixedSpawn: boolean;
  spawnComment: SpawnComment;

  get chance(): number {
    const now = dayjs();
    return now.isBefore(this.respawnStart) ? 0 :
      now.isAfter(this.respawnEnd) ? 100 :
        Math.floor(100 * (now.toDate().getTime() - this.respawnStart.getTime())
          / (this.respawnEnd.getTime() - this.respawnStart.getTime()));
  }

  get timeToStart(): string {
    const now = dayjs();
    return now.isAfter(this.respawnStart) ? `-` : now.to(this.respawnStart)
  }

  compare(another: ISMobRecord): number {
    const d1 = new Duration(this);
    const d2 = new Duration(another);
    return d1.compare(d2);
  }

  get formattedWorld(): string {
    const world = resolveWorld(this.worldId);
    return world ? world.name : `world(${this.worldId})`;
  }

  get formattedMobHtml(): string {
    if (this.zone && this.mob) {
      let name = `${this.mob.name}<br/>@<a href="/mobmaps/${this.zone.id}/${this.instance}?worldid=${this.worldId}">${this.zone.name}</a>`;
      if (this.instance > 1) {
        name += `(${this.instance})`;
      }
      if (dayjs().isAfter(this.respawnStart)) {
        name += `<span class="right badge green white--text">${this.chance.toFixed(0)}%</span>`;
      }
      return name;
    }
    return `mob(${this.mob?.id})(${this.instance})`
  }

  containsWord(word: string): boolean {
    let wordMatched = false;
    if (this.zone?.name && this.mob?.name) {
      wordMatched = this.zone.name.indexOf(word) >= 0 || this.mob.name.indexOf(word) >= 0;
    }
    return wordMatched;
  }
}

export async function getFieldInstances(): Promise<FieldInstance[]> {
  const results = await client.get(`fieldinstances`);
  return results.data;
}

export async function setFieldInstances(data: FieldInstance[]): Promise<Record<string, unknown>> {
  const results = await client.post(`fieldinstances`, data);
  console.debug("setFieldInstance result", results.data)
  return results.data;
}

export async function getBlackList(): Promise<string[]> {
  const results = await client.get(`blacklist`);
  return results.data;
}

export async function setBlackList(data: string[]): Promise<Record<string, unknown>> {
  const results = await client.post(`blacklist`, data);
  console.debug("setBlackList result", results.data)
  return results.data;
}

export interface ServerReset {
  worldId: number;
  resetDate: Date;
}

export async function getServerResets(): Promise<ServerReset[]> {
  const results = await client.get(`serverresets`);
  return results.data;
}

export async function setServerResets(data: { worldIds: number[], resetDate: Date }): Promise<Record<string, unknown>> {
  const results = await client.post(`serverresets`, data);
  console.debug("setServerResets result", results.data)
  return results.data;
}

export async function getUser(): Promise<IUserState> {
  try {
    const results = await axios.get(`/auth/user`);
    results.data.socket = "";
    return results.data;
  } catch (error) {
    console.debug('getUser error', error);
  }
  return NULL_USER;
}

export async function getUserByToken(token: string): Promise<IUserState> {
  try {
    const results = await axios.get(`/auth/user/${token}`);
    if (results.data === "") {
      return NULL_USER;
    }
    results.data.socket = "";
    return results.data;
  } catch (error) {
    console.error('getUser error', error);
  }
  return NULL_USER;
}

export async function getGroupMembers(groupId: string): Promise<IUserState[]> {
  const results = await client.get(`groups/${groupId}/users`);
  return results.data;
}

export async function addGroupMember(groupId: string, data: { generateToken: boolean, userName: string, avatarUrl: string }): Promise<IUserState> {
  try {
    const result = await client.post(`groups/${groupId}/users`, data);
    result.data.socket = "";
    return result.data;
  } catch (error) {
    console.error('addGroupMember failed', error);
  }
  return NULL_USER;
}

export async function editGroupMember(groupId: string, userId: string, data: { generateToken: boolean, userName: string, avatarUrl: string }): Promise<IUserState> {
  try {
    const result = await client.put(`groups/${groupId}/users/${userId}`, data);
    result.data.socket = "";
    return result.data;
  } catch (error) {
    console.error('editGroupMember failed', error);
  }
  return NULL_USER;
}

export async function deleteGroupMember(groupId: string, userId: string): Promise<IUserState> {
  try {
    const result = await client.delete(`groups/${groupId}/users/${userId}`);
    result.data.socket = "";
    return result.data;
  } catch (error) {
    console.error('deleteGroupMember failed', error);
  }
  return NULL_USER;
}

export async function loginPost(token: string): Promise<IUserState> {
  try {
    const result = await axios.post(`/auth/login`, { token });
    result.data.socket = "";
    return result.data;
  } catch (error) {
    console.error('loginPost error', error);
  }
  return NULL_USER;
}

export async function logoutPost(): Promise<IUserState> {
  try {
    const result = await axios.post(`/auth/logout`);
    result.data.socket = "";
    return result.data;
  } catch (error) {
    console.error('loginPost error', error);
  }
  return NULL_USER;
}

export interface WorldIdAndEtag {
  worldId: number;
  etag: string;
}

export async function filterWorldsRequireUpdate(worlds: WorldIdAndEtag[]): Promise<WorldIdAndEtag[]> {
  try {
    const res = await client.post(
      `smobrecords`, worlds)
    return res.data;
  }
  catch (error) {
    console.error(`${error}`);
  }
  return worlds;
}

export interface IFetchResult {
  etag: string;
  records: IMobRecordPayload[];
  update: boolean;
}

export async function getSMobRecords(worldId: number, etag: string): Promise<IFetchResult> {
  let records = [];
  let update = false;
  try {
    const res = await client.get(
      `smobrecords/${worldId}`, {
      headers: {
        'If-None-Match': etag
      }
    }
    );
    etag = res.headers['etag'];
    records = res.data;
    update = true;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  catch (error: any) {
    if (error?.response?.status == 304) {
      // No need to update;
      etag = error.response.headers['etag'];
    }
    else {
      console.error(`fetch smob records for world(${worldId}) failed. ${error.response}`);
      etag = '';
      records = [];
      update = true;
    }
  }
  return { etag: etag, records: records, update: update }
}

export const login = (): void => {
  window.location.href = `/auth/discord/?redirect=${location.href}`
}

export const loginWithToken = (): void => {
  window.location.href = `/auth/?token=ABC&redirect=${location.href}`
}

export const logout = (): void => {
  const port = location.port ? `:${location.port}` : ``
  const origin = `${location.protocol}//${location.hostname}${port}`;
  window.location.href = `/auth/logout?redirect=${origin}`
}

export async function getGroups(): Promise<IGroupPayload[]> {
  const results = await client.get(`groups`);
  return results.data;
}

export async function getGroup(groupId: string): Promise<IGroupGetPayload> {
  const results = await client.get(`groups/${groupId}`);
  return results.data;
}

export async function setGroup(groupId: string, payload: IGroupAddPayload): Promise<IGroupGetPayload> {
  const results = await client.put(`groups/${groupId}`, payload);
  return results.data;
}

export async function addGroup(payload: IGroupAddPayload): Promise<IGroupGetPayload> {
  const results = await client.post(`groups`, payload);
  return results.data;
}

export async function deleteGroup(groupId: string): Promise<IGroupGetPayload> {
  const results = await client.delete(`groups/${groupId}`);
  return results.data;
}

export async function getGuilds(): Promise<IGuild[]> {
  const results = await client.get(`discord/guilds`);
  return results.data;
}

export async function getGuildInfo(guildId: string): Promise<IGuildInfo> {
  const results = await client.get(`discord/guilds/${guildId}`);
  return results.data;
}


export async function getMobTimeOfDeaths(worldId: number): Promise<IMobTimeOfDeathPayload[]> {
  const results = await client.get(`mobtods?worldid=${worldId}}`);
  return results.data;
}

export async function getMobTimeOfDeath(todid: string): Promise<IMobTimeOfDeathPayload> {
  const results = await client.get(`mobtods/${todid}`);
  return results.data;
}

export async function addMobTimeOfDeath(payload: IMobTimeOfDeathAddRequestPayload): Promise<IMobTimeOfDeathPayload> {
  const results = await client.post(`mobtods`, payload);
  return results.data;
}

export async function registerHuntLog(todid: string): Promise<IMobTimeOfDeathPayload> {
  const results = await client.post(`mobtods/${todid}/register`);
  return results.data;
}

export async function unregisterHuntLog(todid: string): Promise<IMobTimeOfDeathPayload> {
  const results = await client.post(`mobtods/${todid}/unregister`);
  return results.data;
}

export async function setAssassinatedAt(payload: ISetAssassinationPayload): Promise<IAssassinationPayload> {
  const results = await client.post(`assassinations`, payload);
  return results.data;
}

export async function getMobLocations(zoneid: number): Promise<IMobLocationPayload[]> {
  const results = await client.get(`zones/${zoneid}/moblocations`);
  const mobLocations: IMobLocationPayload[] = results.data as IMobLocationPayload[];
  return mobLocations;
}

export async function getMobLocationsWithQuery(zoneid: number, query: string): Promise<ILocationClusterPayload[]> {
  const results = await client.get(`zones/${zoneid}/moblocations/${query}?show=details`);
  const mobLocations: ILocationClusterPayload[] = results.data as ILocationClusterPayload[];
  return mobLocations;
}
