
























































































import { userModule } from "@/store/modules/user";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isAuthorized: userModule.isAdmin
    }
  },
  methods: {
  }
})
