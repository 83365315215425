







































import Vue from "vue";
import ServerResets from "@/components/ServerResets.vue";
import FieldInstances from "@/components/FieldInstances.vue";
import MobTimeOfDeaths from "@/components/MobTimeOfDeaths.vue"
import BlackList from "@/components/BlackList.vue"
import { settingsModule } from "@/store/modules/settings";

export default Vue.extend({
  name: 'ServerSettings',
  components: { ServerResets, FieldInstances, MobTimeOfDeaths, BlackList },
  data() {
    return {
    };
  },
  computed: {
    tab: {
      get(): string {
        return settingsModule.serverSettingsTab
      },
      set(value: string): void {
        settingsModule.SET_SERVER_SETTINGS_TAB(value)
      }
    },
  },
  watch: {
    tab: function (newValue, oldValue) {
      console.debug("tab", newValue, oldValue)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let serverResetsRef: any = this.$refs.serverResets;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let fieldInstancesRef: any = this.$refs.fieldInstances;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let mobTimeOfDeathsRef: any = this.$refs.mobTimeOfDeaths;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let blackListRef: any = this.$refs.blackList;
      switch (newValue) {
        case "tabServerResets":
          if (serverResetsRef) {
            console.debug("calling tabServerResets.load()")
            serverResetsRef.load();
          }
          break;
        case "tabFieldInstances":
          if (fieldInstancesRef) {
            console.debug("calling fieldInstances.load()")
            fieldInstancesRef.load();
          }
          break;
        case "tabMobTimeOfDeaths":
          if (blackListRef) {
            console.debug("calling mobTimeOfDeaths.load()")
            mobTimeOfDeathsRef.load();
          }
          break;
        case "tabBlackList":
          if (blackListRef) {
            console.debug("calling blackList.load()")
            blackListRef.load();
          }
          break;
      }
    },
  },
});
