




















































































































































































































































































import Vue, { PropType } from "vue";

import { IGroupAddPayload, IGroupGetPayload, IGroupPayload } from "../../../src/interfaces/groups";
import { IGuildTextChannel, IGuildRole } from "../../../src/interfaces/discord";
import { addGroupMember, deleteGroupMember, editGroupMember, getGroup, getGroupMembers, setGroup } from "@/libs/serverProxy";
import { IUserState } from "@/store/modules/user";
import { DataTableHeader } from "vuetify";
import { worlds } from "ffxivhuntdata";
import copy from 'copy-text-to-clipboard';

export interface IMethods {
  load: () => Promise<void>;
}

export interface ITableAction {
  create?: IGroupPayload;
  update?: IGroupPayload;
  delete?: string;
}


interface Data {
  // For Settings
  orgGroup?: IGroupGetPayload;
  groupId: string;
  ownerId: string;
  ownerName: string;
  guildName: string;
  name: string;
  guildId: string;
  roleId: string;
  channelId: string;
  logChannelId: string;
  description: string;
  activeWorldIds: number[];
  roleItems: IGuildRole[];
  channelItems: IGuildTextChannel[];

  // For Members
  headers: DataTableHeader[];
  memberDialog: boolean,
  memberDeleteDialog: boolean,
  member: {
    index: number,
    id: string,
    generateToken: boolean,
    userName: string,
    avatarUrl: string,
    avatarUrlValid: boolean,
  },
  groupMembers: IUserState[];
}

export default Vue.extend({
  name: 'EditGroup',
  props: {
    group: Object as PropType<IGroupPayload>,
  },
  data(): Data {
    return {
      orgGroup: undefined,
      // For Settings
      groupId: "",
      ownerId: "",
      ownerName: "",
      guildName: "",
      name: "",
      guildId: "",
      roleId: "",
      channelId: "",
      logChannelId: "",
      description: "",
      activeWorldIds: [],
      roleItems: [],
      channelItems: [],

      // For Members
      headers: [
        { text: this.$t('userName') as string, value: 'userName' },
        { text: this.$t('copyToClipBoard') as string, value: 'clipboard', sortable: false },
        { text: this.$t('actions') as string, value: 'actions', width: "100px", sortable: false },
      ],

      memberDialog: false,
      memberDeleteDialog: false,

      member: {
        index: -1,
        id: "",
        generateToken: false,
        userName: "",
        avatarUrl: "",
        avatarUrlValid: false,
      },

      groupMembers: [],
    }
  },
  methods: {
    async load() {
      this.orgGroup = await getGroup(this.group.id);
      this.guildName = this.group.guildName;
      this.groupMembers = await getGroupMembers(this.group.id);
      this.setDefault();
    },

    setDefault() {
      if (this.orgGroup) {
        this.name = this.orgGroup.name;
        this.ownerId = this.orgGroup.ownerId;
        this.ownerName = this.orgGroup.ownerName;
        this.guildId = this.orgGroup.guildId;
        this.roleId = this.orgGroup.roleId;
        this.channelId = this.orgGroup.channelId;
        this.logChannelId = this.orgGroup.logChannelId;
        this.description = this.orgGroup.description;
        this.activeWorldIds = this.orgGroup.activeWorldIds;
        this.roleItems = this.orgGroup.guildInfo.roles;
        this.roleItems.unshift({ id: "", name: this.$t('doesNotSpecify') as string })
        this.channelItems = this.orgGroup.guildInfo.channels;
        this.channelItems.unshift({ id: "", path: this.$t('doesNotSpecify') as string })
      }
    },

    async confirmEditGroup() {
      const payload: IGroupAddPayload = {
        name: this.name,
        ownerId: this.ownerId,
        guildId: this.guildId,
        roleId: this.roleId,
        channelId: this.channelId,
        logChannelId: this.logChannelId,
        description: this.description,
        activeWorldIds: this.activeWorldIds
      }
      await setGroup(this.group.id, payload)

      const group: IGroupPayload = {
        id: this.group.id,
        name: this.name,
        ownerId: this.ownerId,
        ownerName: this.ownerName,
        guildName: this.guildName,
        roleName: this.roleName,
      };
      // 親にemitして更新させる
      this.$emit('updateTable', group)
    },

    copyLoginUrl(item: IUserState) {
      copy(`${window.location.origin}/auth/?token=${item.userId}`);
    },

    copyToken(item: IUserState) {
      copy(item.userId);
    },

    imageLoadSuccess(event: { target: HTMLImageElement }) {
      if (event.target.src != `${window.location.origin}/defaultUser.png`) {
        this.member.avatarUrlValid = true;
      }
    },

    imageLoadFailed(event: { target: HTMLImageElement }) {
      console.debug("imageLoadFailed", this.member.avatarUrl)
      this.member.avatarUrlValid = false;
      event.target.src = "./defaultUser.png";
    },

    startAddEditUser(item?: IUserState) {
      this.member.index = item ? this.groupMembers.indexOf(item) : -1;
      this.member.id = item ? item.id : "";
      this.member.generateToken = item ? false : true;
      this.member.userName = item ? item.userName : "";
      this.member.avatarUrl = item ? item.avatarUrl : "";
      this.member.avatarUrlValid = true;
      this.memberDialog = true;
    },

    async confirmAddEditUser() {
      const data = {
        generateToken: this.member.generateToken,
        userName: this.member.userName,
        avatarUrl: this.member.avatarUrl
      }
      if (this.member.index >= 0) {
        // edit
        const user = await editGroupMember(this.group.id, this.member.id, data);
        Object.assign(this.groupMembers[this.member.index], user);
      } else {
        // add
        const user = await addGroupMember(this.group.id, data);
        this.groupMembers.push(user);
      }
      this.closeAddEditUser();
    },

    closeAddEditUser() {
      this.memberDialog = false;
      this.$nextTick(() => {
        this.member.index = -1;
        this.member.id = "";
        this.member.generateToken = false;
        this.member.userName = "";
        this.member.avatarUrl = "";
        this.member.avatarUrlValid = false;
      })
    },

    startDeleteUser(item: IUserState) {
      this.member.index = this.groupMembers.indexOf(item);
      this.member.id = item.id;
      this.memberDeleteDialog = true;
    },

    async confirmDeleteUser() {
      // call delete api
      await deleteGroupMember(this.group.id, this.member.id);
      // remove from table
      this.groupMembers.splice(this.member.index, 1);
      this.closeDeleteUser();
    },

    closeDeleteUser() {
      this.memberDeleteDialog = false;
      this.$nextTick(() => {
        this.member.index = -1;
        this.member.id = "";
      })
    },

  },
  computed: {
    worlds() {
      return worlds.filter(w => w.dcRegion == 1).map(w => {
        return {
          id: w.id,
          name: w.name,
          longName: `${w.name}(${w.dcName})`
        }
      });
    },

    roleName(): string {
      const role = this.roleItems.find(role => role.id == this.roleId)
      return role ? role.name : `Unknown<${this.roleId}>`
    },

    isGroupValid(): boolean {
      return (this.name != '' &&
        this.name != this.orgGroup?.name) ||
        this.roleId != this.orgGroup?.roleId ||
        this.channelId != this.orgGroup?.channelId ||
        this.logChannelId != this.orgGroup?.logChannelId;
    },

    imageInvalidMessage(): string {
      return (this.member.avatarUrl == '' || this.member.avatarUrlValid) ? '' : this.$t('avatarUrlError') as string;
    },

    isMemberValid(): boolean {
      return this.member.userName != '' && this.imageInvalidMessage == '';
    },

  },
});
