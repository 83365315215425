












































import Vue from "vue";
import { messages, getLang, WeatherPeriod, zones, zones2, regions } from 'ffxivhuntdata'
import dayjs from "dayjs";
import { settingsModule } from "@/store/modules/settings";
import ZoneSelectorForWeathers from "@/components/ZoneSelectorForWeathers.vue";

interface ITableHeader {
  text: string;
  value: string;
  sortable?: boolean
}

interface Record {
  ltime: string;
  etime: string;
  weather: string;
  comment: string;
  class: string;
  fontColor: string;
  flag: boolean;
  count: number;
}

interface Data {
  tableHeight: number;
  timerId: number;
  records: Record[]
}

export default Vue.extend({
  name: 'Weathers',
  components: {
    ZoneSelectorForWeathers
  },
  data(): Data {
    return {
      tableHeight: 0,
      timerId: 0,
      records: []
    }
  },
  created() {
    this.timerId = window.setInterval(() => {
      this.refresh();
    }, 60 * 1000);
  },
  mounted() {
    this.refresh();
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
  computed: {
    zoneId(): number {
        return settingsModule.weathersZoneId;
    },
    showCaution: {
      get(): boolean {
        return settingsModule.weathersSmobFilter;
      },
      set(value: boolean): void {
        settingsModule.SET_WEATHERS_SMOB_FILTER(value);
      }
    },
    zoneName(): string {
      return messages[getLang()].zone[this.zoneId];
    },
    regionClass(): string {
      const region = regions.find(region => region.zoneIds.includes(this.zoneId));
      return region != null ? region.class : "";
    },
    isGarLed(): boolean {
      return this.zoneId === 137 || this.zoneId === 148
    },
    weatherRateId(): number {
      const zone = zones.find(z => z.id === this.zoneId);
      const zone2 = zones2.find(z => z.id === this.zoneId);
      return zone != null ? zone.weatherRate : zone2 != null ? zone2.weatherRate : 0;
    },
    headers(): ITableHeader[] {
      const headers = [
        { text: this.$t('localTime') as string, value: "ltime", sortable: false },
        { text: this.$t('eorzeaTime') as string, value: "etime", sortable: false },
        { text: this.$t('weather') as string, value: "weather", sortable: false },
        { text: this.$t('comment') as string, value: "comment", sortable: false }
      ];
      if (this.zoneId === 137) {
        headers[3].text = this.$t('countOfNoneRains') as string;
      } else if (this.zoneId === 148) {
        headers[3].text = this.$t('countOfRains') as string;
      } else {
        headers.pop();
      }
      return headers;
    },
    filteredRecords(): Record[] {
      return this.showCaution ? this.records.filter(r => r.flag == true) : this.records
    }
  },
  watch: {
    zoneId: function () {
      this.refresh()
    },
  },
  methods: {
    refresh() {
      try {
        this.records = [];
        let period = new WeatherPeriod(new Date(), this.weatherRateId);
        let prev = period.previous;

        // calculate count
        let count = 0;
        if (this.zoneId === 137) {
          while (![7, 8].includes(prev.weather.id)) {
            count += 1;
            prev = prev.previous;
          }
        }
        else if (this.zoneId === 148) {
          while ([7, 8].includes(prev.weather.id)) {
            count += 1;
            prev = prev.previous;
          }
        }

        for (let i = 0; i < 300; i++) {
          const record = {
            ltime: dayjs(period.start.toDate()).format("MM/DD(ddd) HH:mm:ss"),
            etime: period.start.toTimeString(),
            iconClass: `image${period.weather.icon}`,
            weather: period.weather.name,
            comment: "",
            class: period.start.hour == 0 ? "indigo lighten-5" :
              period.start.hour == 8 ? "blue lighten-5" : "lime lighten-5",
            fontColor: "",
            flag: true,
            count: count
          }
          if (this.zoneId === 140) {
            record.fontColor = [1, 2].includes(period.weather.id) ? 'green' : 'red'
          }
          else if (this.zoneId === 137) {
            if (![7, 8].includes(period.weather.id)) {
              record.fontColor = 'green';
              count += 1;
            }
            else {
              record.fontColor = 'red';
              count = 0;
            }
            record.flag = (count > 8);
            record.count = count;
            const d1 = dayjs(period.start.toDate()).add(800, 'seconds');
            const d2 = period.start.clone().add(274, "MINUTES")
            record.comment = (count) === 9 ?
              `${count}, LT${d1.format('HH:mm:ss')}, ET${d2.toTimeString()}` : `${count}`;
          }
          else if (this.zoneId === 148) {
            if ([7, 8].includes(period.weather.id)) {
              record.fontColor = 'green'
              count += 1;
            }
            else {
              record.fontColor = 'red'
              count = 0;
            }
            record.flag = (count > 1);
            record.count = count
            const d1 = dayjs(period.start.toDate()).add(400, 'seconds');
            const d2 = period.start.clone().add(137, "MINUTES")
            record.comment = (count) === 2 ?
              `${count}, LT${d1.format('HH:mm:ss')}, ET${d2.toTimeString()}` : `${count}`;
          }

          this.records.push(record);
          period = period.next;
        }
      } catch (err) {
        console.error("err", err);
      }
    },
        onResize() {
      const clientHeight = document.documentElement.clientHeight;
      const headerElement = document.querySelector("header") as HTMLElement;
      const footerElement = document.querySelector("footer") as HTMLElement;
      const otherRows = document.querySelectorAll<HTMLElement>(".nonetable, .v-data-footer");
      this.tableHeight = clientHeight - headerElement.offsetHeight - footerElement.offsetHeight;
      for (let i = 0; i < otherRows.length; i++) {
        this.tableHeight -= otherRows[i].offsetHeight;
      }
    },

  }
});
