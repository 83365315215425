



























































































































































































































































































import Vue from "vue";
import { messages, getLang, EODate } from 'ffxivhuntdata'
import dayjs from "dayjs";
import SMobFilter from "@/components/SMobFilter.vue"
import { ISMobRecord, setAssassinatedAt } from "@/libs/serverProxy";
import { worldModule } from "@/store/modules/world";
import { sTimeTableViewModule } from "@/store/modules/sTimeTableView";
import { userModule } from "@/store/modules/user";
import { ISetAssassinationPayload } from "../../../src/interfaces/assassinations";

interface ITableHeader {
  text: string;
  value?: string;
  sortable?: boolean
}

interface MobFilter {
  key: string;
  name: string;
  info?: string;
  filterDefault?: boolean;
  filterLeftLong?: boolean;
  filterKilledRecent?: boolean;
  filterFixedSpawn?: boolean;
  filterAssassinated?: boolean;
  filterByMobIds?: number[];
}

interface Group {
  name: string;
  selected: MobFilter[];
  items: MobFilter[];
}

interface Data {
  selectedFilters: MobFilter[] | MobFilter;
  defaultItems: MobFilter[];
  groups: Group[];
  searchWord: string | null;
  tableHeight: number;
  timerId: number;
  expanded: ISMobRecord[],
  assassinatedAtDialog: boolean,
  assassinatedAtDeleteDialog: boolean,
  editRecord?: ISMobRecord,
  dialogDate: boolean,
  dialogTime: boolean,
  date: string,
  time: string,
  expandedComment: {
    worldId: number,
    mobId: number,
    instance: number
  } | undefined
}
/*
interface STimeTableBrowserCache {
  availableFilterKeys: string[];
  selectedFilterKeys: string[] | string;
}
*/
const getMobNames = (mobIds: number[]): string => {
  return mobIds.map(mobId => messages[getLang()].mob[mobId]).join(", ");
}

export default Vue.extend({
  name: 'STimeTable',
  components: {
    SMobFilter
  },
  props: {
  },
  data(): Data {
    return {
      //      multiFilter: false,
      selectedFilters: [],
      defaultItems: [
        {
          key: "default",
          name: this.$t("smobFilters.fDefault") as string,
          info: this.$t("smobFilters.iDefault") as string,
          filterDefault: true
        },
        {
          key: "leftLong",
          name: this.$t("smobFilters.fLeftLong") as string,
          info: this.$t("smobFilters.iLeftLong") as string,
          filterLeftLong: true
        },
        {
          key: "killedRecent",
          name: this.$t("smobFilters.fKilledRecent") as string,
          info: this.$t("smobFilters.iKilledRecent") as string,
          filterKilledRecent: true
        },
        {
          key: "assassin",
          name: this.$t("smobFilters.fAssassin") as string,
          info: this.$t("smobFilters.iAssassin") as string,
          filterAssassinated: true
        },
        {
          key: "fixedSpawn",
          name: this.$t("smobFilters.fFixedSpawn") as string,
          info: this.$t("smobFilters.iFixedSpawn") as string,
          filterFixedSpawn: true
        },
      ],
      groups: [
        {
          name: this.$t("smobFilters.gGroup") as string,
          selected: [],
          items: [
            {
              key: "EW",
              name: this.$t("smobFilters.fEW") as string,
              filterByMobIds: [10617, 10618, 10619, 10620, 10621, 10622],
            },
            {
              key: "ShB",
              name: this.$t("smobFilters.fShB") as string,
              filterByMobIds: [8905, 8910, 8900, 8653, 8890, 8895],
            },
            {
              key: "SB",
              name: this.$t("smobFilters.fSB") as string,
              filterByMobIds: [5987, 5988, 5989, 5984, 5985, 5986],
            },
            {
              key: "HW",
              name: this.$t("smobFilters.fHW") as string,
              filterByMobIds: [4374, 4375, 4376, 4377, 4378, 4380],
            },
            {
              key: "ARR",
              name: this.$t("smobFilters.fARR") as string,
              filterByMobIds: [2962, 2963, 2964, 2965, 2966, 2967, 2957, 2958, 2959, 2960, 2961, 2953, 2954, 2955, 2956, 2968, 2969],
            },
            {
              key: "Moon",
              name: this.$t("smobFilters.fMoon") as string,
              info: getMobNames([2963, 2955, 5984]),
              filterByMobIds: [2963, 2955, 5984]
            },
            {
              key: "Weather",
              name: this.$t("smobFilters.fWeather") as string,
              info: getMobNames([2953, 2964]),
              filterByMobIds: [2953, 2964]
            },
            {
              key: "Fishing",
              name: this.$t("smobFilters.fFishing") as string,
              info: getMobNames([2957, 2956]),
              filterByMobIds: [2957, 2956]
            },
            {
              key: "Gathering",
              name: this.$t("smobFilters.fGathering") as string,
              info: getMobNames([8910, 4377, 2962, 2965]),
              filterByMobIds: [8910, 4377, 2962, 2965]
            },
            {
              key: "StepOn",
              name: this.$t("smobFilters.fStepOn") as string,
              info: getMobNames([8900, 8653, 5985, 2964, 2955, 2958]),
              filterByMobIds: [8900, 8653, 5985, 2964, 2955, 2958]
            },
            {
              key: "Treasure",
              name: this.$t("smobFilters.fTreasure") as string,
              info: getMobNames([4376, 2969]),
              filterByMobIds: [4376, 2969]
            },
            {
              key: "Leve",
              name: this.$t("smobFilters.fLeve") as string,
              info: getMobNames([2954, 2959]),
              filterByMobIds: [2954, 2959]
            },
            {
              key: "KillCount",
              name: this.$t("smobFilters.fKillCount") as string,
              info: getMobNames([8890, 5984, 5987, 4380]),
              filterByMobIds: [8890, 5984, 5987, 4380]
            },
            {
              key: "Fate",
              name: this.$t("smobFilters.fFate") as string,
              info: getMobNames([5986, 5992, 4375]),
              filterByMobIds: [5986, 5992, 4375]
            }
          ]
        },
      ],
      searchWord: '',
      tableHeight: 0,
      timerId: 0,
      expanded: [],
      assassinatedAtDialog: false,
      assassinatedAtDeleteDialog: false,
      editRecord: undefined,
      dialogDate: false,
      dialogTime: false,
      date: '',
      time: '',
      expandedComment: undefined
    }
  },
  created() {
    // Extend Filters
    this.addGroup(this.$t("smobFilters.gEW") as string, [10617, 10618, 10619, 10620, 10621, 10622]);
    this.addGroup(this.$t("smobFilters.gShB") as string, [8905, 8910, 8900, 8653, 8890, 8895]);
    this.addGroup(this.$t("smobFilters.gSB") as string, [5987, 5988, 5989, 5984, 5985, 5986]);
    this.addGroup(this.$t("smobFilters.gHW") as string, [4374, 4375, 4376, 4377, 4378, 4380]);
    this.addGroup(this.$t("smobFilters.gARR") as string, [2962, 2963, 2964, 2965, 2966, 2967, 2957, 2958, 2959, 2960, 2961, 2953, 2954, 2955, 2956, 2968, 2969]);
    //    smobrecordsModule.loadFromLocalStorage();
  },
  async mounted() {
    // this.loadBrowserCache();
    this.initFilters();
    await this.refresh();
    this.timerId = window.setInterval(() => {
      this.refresh();
    }, 60 * 1000);
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "hidden";
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
  destroyed() {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "";
  },
  computed: {
    isDcMode: {
      get(): boolean {
        return sTimeTableViewModule.isDcMode;
      },
      set(value: boolean): void {
        sTimeTableViewModule.SET_DC_MODE(value);
      }
    },
    multiFilter: {
      get(): boolean {
        return sTimeTableViewModule.multiFilter;
      },
      set(value: boolean): void {
        sTimeTableViewModule.SET_MULTI_FILTER(value);
      }
    },
    isAdmin(): boolean {
      return userModule.isAdmin;
    },
    availableFilterKeys(): string[] {
      return sTimeTableViewModule.availableFilterKeys;
    },
    selectedFilterKeys(): string[] {
      return sTimeTableViewModule.selectedFilterKeys;
    },
    availableFilters(): MobFilter[] {
      return this.defaultItems.concat(this.groups.map(g => {
        return g.selected.sort(function (a, b) {
          return g.items.indexOf(a) - g.items.indexOf(b);
        })
      }).flat());
    },
    resultFilter(): MobFilter {
      const result: MobFilter = {
        key: 'Summary',
        name: 'Summary'
      }
      if (Array.isArray(this.selectedFilters)) {
        const uniqIds = [...new Set(this.selectedFilters.filter(f => f.filterByMobIds).map(f => f.filterByMobIds as number[]).flat())];
        result.filterDefault = this.selectedFilters.some(f => f.filterDefault);
        result.filterLeftLong = this.selectedFilters.some(f => f.filterLeftLong);
        result.filterKilledRecent = this.selectedFilters.some(f => f.filterKilledRecent);
        result.filterFixedSpawn = this.selectedFilters.some(f => f.filterFixedSpawn);
        result.filterAssassinated = this.selectedFilters.some(f => f.filterAssassinated);
        result.filterByMobIds = uniqIds;
      }
      else {
        result.filterDefault = this.selectedFilters.filterDefault;
        result.filterLeftLong = this.selectedFilters.filterLeftLong;
        result.filterKilledRecent = this.selectedFilters.filterKilledRecent;
        result.filterFixedSpawn = this.selectedFilters.filterFixedSpawn;
        result.filterAssassinated = this.selectedFilters.filterAssassinated;
        result.filterByMobIds = this.selectedFilters.filterByMobIds;
      }
      return result;
    },
    headers(): ITableHeader[] {
      const headers = this.isDcMode ? [
        { text: this.$t('smobTable.world') as string, sortable: false, width: "70px" },
        { text: this.$t('smobTable.mob') as string, sortable: false, width: "180px" },
        { text: this.$t('smobTable.toStart') as string, sortable: false, width: "70px" },
        { text: this.$t('smobTable.start') as string, sortable: false, width: "75px" },
        { text: this.$t('smobTable.end') as string, sortable: false, width: "75px" },
        { text: this.$t('smobTable.comment') as string, sortable: false },
        { text: '', sortable: false, width: "40px" },
      ] : [
        { text: this.$t('smobTable.mob') as string, sortable: false, width: "180px" },
        { text: this.$t('smobTable.toStart') as string, sortable: false, width: "70px" },
        { text: this.$t('smobTable.start') as string, sortable: false, width: "75px" },
        { text: this.$t('smobTable.end') as string, sortable: false, width: "75px" },
        { text: this.$t('smobTable.comment') as string, sortable: false },
        { text: '', sortable: false, width: "40px" },
      ];
      return headers;
    },
    filteredRecords(): ISMobRecord[] {
      let records = worldModule.getSMobRecords;
      if (this.searchWordIsNotActive) {
        records = records.filter(r =>
          (this.resultFilter.filterDefault && r.isDefault) ||
          (this.resultFilter.filterLeftLong && r.isLeftLong) ||
          (this.resultFilter.filterKilledRecent && r.isKilledRecent) ||
          (this.resultFilter.filterFixedSpawn && r.isFixedSpawn) ||
          (this.resultFilter.filterAssassinated && r.isAssassinated) ||
          (this.resultFilter.filterByMobIds &&
            this.resultFilter.filterByMobIds.length > 0 &&
            this.resultFilter.filterByMobIds.includes(r.mobId))
        ).sort((a, b) => a.compare(b));
        return records;
      }
      else {
        return records.filter(r =>
          r.containsWord(this.searchWord as string)
        ).sort((a, b) => a.compare(b));
      }
    },
    searchWordIsNotActive(): boolean {
      return (this.searchWord == null || this.searchWord == '');
    },
    selectedWorldId(): number {
      return worldModule.id;
    },
    selectedDataCenter(): string {
      return worldModule.dcName;
    }

  },
  watch: {
    isDcMode: async function () {
      await this.refresh();
    },
    multiFilter: function (newItem) {
      // update selectedFilters
      if (!newItem && Array.isArray(this.selectedFilters)) {
        this.selectedFilters = this.selectedFilters.length > 0 ? this.selectedFilters[0] : this.availableFilters[0];
      }
      else if (newItem && !Array.isArray(this.selectedFilters)) {
        this.selectedFilters = [this.selectedFilters];
      }
      this.saveFilters();
    },
    selectedFilters: function () {
      this.saveFilters();
    },
    availableFilters: function () {
      this.saveFilters();
    },
    selectedWorldId: async function () {
      await this.refresh();
    },
    expanded: function () {
      console.debug(this.expanded);
    },
  },
  methods: {
    addGroup(name: string, mobIds: number[]) {
      this.groups.push({
        name: name,
        selected: [],
        items: mobIds.map(mobId => {
          return {
            key: `Mob${mobId}`,
            name: messages[getLang()].mob[mobId],
            filterByMobIds: [mobId]
          }
        })
      })
    },
    async refresh() {
      console.debug(`${dayjs().format('YYYY-MM-DD HH:mm:ss')} refresh called`);
      await worldModule.fetchSMobRecords()
    },
    formatDate(date: Date): string {
      const d = dayjs(date);
      return `${d.format('MM/DD(ddd) HH:mm')}`
    },
    formatLongDate(date: Date): string {
      if (date.getTime() === 0) {
        return '-';
      }
      const d = dayjs(date);
      return `${d.format('YYYY/MM/DD(ddd) HH:mm')}`
    },
    formatComment(record: ISMobRecord): string {
      let comment = ``;
      if (record.isAssassinated) {
        comment += `<span class="badge3 purple white--text">${this.$t('assassin')}</span><br/>`;
      }
      if (record.hitBlackList) {
        comment += `<span class="badge3 black white--text">${this.$t('hitBlackList')}</span><br/>`;
      }
      if (record.isKilledRecent) {
        const killedRecentDateLTString = `${dayjs(record.lastKilled).format('MM/DD(ddd) HH:mm')}`;
        comment += `<span class="badge2 red white--text">${this.$t('killedRecent')}</span>${killedRecentDateLTString}<br/>`;
      }
      if (record.isFixedSpawn) {
        const fixedDateLTString = `LT${dayjs(record.respawnEnd).format('HH:mm:ss')}`;
        const fixedDateETString = `ET${(new EODate(record.respawnEnd)).toTimeString()}`;
        comment += `<span class="badge3 blue white--text">${this.$t('fixedDate')}</span>${fixedDateLTString}, ${fixedDateETString}<br/>`;
      }
      else if (record.mobId == 2963 || record.mobId == 2955) {
        // Special warning for Croakadile and Mindflayer
        comment += `${this.$t('warningForCroakadileMindflayer')}<br/>`
      }
      if (record.spawnComment.lines.length > 5) {
        if (this.expandedComment && 
          this.expandedComment.worldId === record.worldId &&
          this.expandedComment.mobId === record.mobId &&
          this.expandedComment.instance === record.instance) {
          comment += record.spawnComment.lines.join(`<br/>`);
        }
        else {
          comment += record.spawnComment.lines.filter((value, index) => index < 5).join(`<br/>`);
          comment += `...`
        }
      }
      else {
        comment += record.spawnComment.lines.join(`<br/>`);
      }
      return comment;
    },
    expandComment(record: ISMobRecord): void {
        if (this.expandedComment && 
          this.expandedComment.worldId === record.worldId &&
          this.expandedComment.mobId === record.mobId &&
          this.expandedComment.instance === record.instance) {
          this.expandedComment = undefined;
        }
        else {
          this.expandedComment = {
            worldId: record.worldId,
            mobId: record.mobId,
            instance: record.instance
          }
        }
    },
    categoryColor(record: ISMobRecord): string {
      if ([10617, 10618, 10619, 10620, 10621, 10622].includes(record.mobId)) {
        return "amber lighten-3";
      }
      else if ([8905, 8910, 8900, 8653, 8890, 8895].includes(record.mobId)) {
        return "blue-grey lighten-4";
      }
      else if ([5987, 5988, 5989, 5984, 5985, 5986].includes(record.mobId)) {
        return "red lighten-4";
      }
      else if ([4374, 4375, 4376, 4377, 4378, 4380].includes(record.mobId)) {
        return "blue lighten-4";
      }
      else {
        return "lime lighten-4";
      }
    },
    initFilters() {
      const selectedFilters: MobFilter[] = [];
      this.defaultItems.forEach(f => {
        if (this.selectedFilterKeys.includes(f.key)) {
          selectedFilters.push(f);
        }
      })
      this.groups.forEach(g => g.items.forEach(f => {
        if (this.availableFilterKeys.includes(f.key)) {
          g.selected.push(f);
        }
        if (this.selectedFilterKeys.includes(f.key)) {
          selectedFilters.push(f);
        }
      }));
      this.selectedFilters = this.multiFilter ? selectedFilters : selectedFilters[0];
    },
    saveFilters() {/*
      const cache: STimeTableBrowserCache = {
        availableFilterKeys: this.availableFilters.map(f => f.key),
        selectedFilterKeys: Array.isArray(this.selectedFilters) ? this.selectedFilters.map(f => f.key) : [this.selectedFilters.key],
      }
      localStorage.setItem('smobtable', JSON.stringify(cache));
      */
      sTimeTableViewModule.SET_AVAILABLE_FILTER_KEYS(this.availableFilters.map(f => f.key));
      sTimeTableViewModule.SET_SELECTED_FILTER_KEYS(
        Array.isArray(this.selectedFilters) ? this.selectedFilters.map(f => f.key) : [this.selectedFilters.key]
      );
      this.$nextTick(() => this.onResize());
    },
    onResize() {
      const clientHeight = document.documentElement.clientHeight;
      const headerElement = document.querySelector("header") as HTMLElement;
      const footerElement = document.querySelector("footer") as HTMLElement;
      const otherRows = document.querySelectorAll<HTMLElement>(".nonetable, .v-data-footer");
      this.tableHeight = clientHeight - headerElement.offsetHeight - footerElement.offsetHeight;
      for (let i = 0; i < otherRows.length; i++) {
        this.tableHeight -= otherRows[i].offsetHeight;
      }
    },

    startEditAssassinatedAt(item: ISMobRecord) {
      console.debug("startEdit", item);
      this.editRecord = item;
      const dateTime = item.assassinated.getTime() === 0 ? item.respawnEnd : item.assassinated;
      this.date = dayjs(dateTime).format("YYYY-MM-DD");
      this.time = dayjs(dateTime).format("HH:mm");
      this.assassinatedAtDialog = true;
    },

    async confirmEditAssassinatedAt() {
      const newDateTime = dayjs(`${this.date} ${this.time}:00`,
        "YYYY-MM-DD HH:mm:ss");
      if (this.editRecord) {
        const payload: ISetAssassinationPayload = {
          worldId: this.editRecord.worldId,
          mobId: this.editRecord.mobId,
          instance: this.editRecord.instance,
          assassinatedAt: newDateTime.toDate()
        }
        await setAssassinatedAt(payload);
        this.refresh();
      }
      this.closeEditAssassinatedAt();
    },

    closeEditAssassinatedAt() {
      this.assassinatedAtDialog = false;
      this.$nextTick(() => {
        this.editRecord = undefined;
      })
    },

    startDeleteAssassinatedAt(item: ISMobRecord) {
      console.debug("startDelete", item);
      this.editRecord = item;
      this.assassinatedAtDeleteDialog = true;
    },

    async confirmDeleteAssassinatedAt() {
      if (this.editRecord) {
        const payload: ISetAssassinationPayload = {
          worldId: this.editRecord.worldId,
          mobId: this.editRecord.mobId,
          instance: this.editRecord.instance,
          assassinatedAt: new Date(0)
        }
        await setAssassinatedAt(payload);
        this.refresh();
      }
      this.closeDeleteAssassinatedAt();
    },

    closeDeleteAssassinatedAt() {
      this.assassinatedAtDeleteDialog = false;
      this.$nextTick(() => {
        this.editRecord = undefined;
      })
    },


  },

});
