import { IMob, IWorld, worlds, IZone, zones } from "ffxivhuntdata";

const _worldMap: { [id: number]: IWorld | undefined } = {}
export function resolveWorld(id: number): IWorld | undefined {
    if (!_worldMap[id]) {
        _worldMap[id] = worlds.find(w => w.id == id);
    }
    return _worldMap[id];
}

const _mobMap: { [id: number]: [IMob, IZone] | undefined } = {}
export function resolveMob(id: number): [IMob, IZone] | undefined {
    if (!_mobMap[id]) {
        const zone = zones.find(z => z.mobs.some(m => m.id == id));
        if (zone) {
            const mob = zone.mobs.find(m => m.id == id);
            if (mob) {
                _mobMap[id] = [mob, zone];
            }
        }
    }
    return _mobMap[id];
}