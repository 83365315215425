

















































































































import Vue from "vue";
import { Zone, zones } from 'ffxivhuntdata'
import { setFieldInstances } from "../libs/serverProxy"
import { fieldinstancesModule } from "@/store/modules/fieldinstances";

interface ITableHeader {
  text: string;
  value: string;
  sortable?: boolean
}

interface FieldInstance {
  zoneIds: number[];
  instanceCount: number;
}

interface Data {
  dialog: boolean,
  dialogDelete: boolean,
  headers: ITableHeader[],
  items: FieldInstance[]
  editedIndex: number,
  editedItem: FieldInstance,
  instanceCountCandidates: number[]
}

export default Vue.extend({
  name: 'FieldInstances',
  data(): Data {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: this.$t('zone') as string, value: 'zoneName' },
        { text: this.$t('region') as string, value: 'regionName' },
        { text: this.$t('instanceCount') as string, value: 'instanceCount' },
        { text: this.$t('actions') as string, value: 'actions', sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
        zoneIds: [],
        instanceCount: 3
      },
      instanceCountCandidates: [2, 3, 4, 5, 6, 7, 8, 9, 10]
    };
  },
  computed: {
    formTitle(): string {
      return this.editedIndex === -1 ?
        this.$t('add') as string :
        this.$t('edit') as string
    },
    zonesNotSpecified(): Zone[] {
      return zones.filter(
        z => !this.items.map(item => item.zoneIds).flat().includes(z.id)
      )
    },
    zonesSpecified(): Zone[] {
      return zones.filter(
        z => this.items.map(item => item.zoneIds).flat().includes(z.id)
      )
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      await fieldinstancesModule.fetch();
      this.items = zones
        .filter(zone => fieldinstancesModule.getInstanceCount(zone.id) > 1)
        .map(zone => {
          return {
            zoneIds: [zone.id],
            instanceCount: fieldinstancesModule.getInstanceCount(zone.id)
          }
        })
    },

    editItem(item: FieldInstance) {
      console.debug(item)
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item: FieldInstance) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1)
      this.applyOutput()
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { zoneIds: [], instanceCount: 3 }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { zoneIds: [], instanceCount: 3 }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem)
      } else {
        this.editedItem.zoneIds.forEach(zid => {
          console.debug('push', zid)
          this.items.push({
            zoneIds: [zid],
            instanceCount: this.editedItem.instanceCount
          });
        })
      }
      this.applyOutput()
      this.close()
    },

    regionName(zoneId: number): string {
      const zone = zones.find(z => z.id === zoneId);
      return zone != null ? zone.regionName : '';
    },

    zoneName(zoneId: number): string {
      const zone = zones.find(z => z.id === zoneId);
      return zone != null ? zone.name : '';
    },

    async applyOutput() {
      const requestBody = this.items.map(item => { return { zoneId: item.zoneIds[0], instanceCount: item.instanceCount } })
      fieldinstancesModule.SET(requestBody);
      console.debug("requestBody", requestBody);
      const result = await setFieldInstances(requestBody);
      console.debug("updated fieldinstances", result);
    }
  },


});
