import { Mutation, VuexModule, getModule, Module } from "vuex-module-decorators";
import { store } from "@/store";
import Vue from "vue";
import { userModule } from "./user";

// state's interface
export interface RecordState {
    reporter: string;
    reportedAt: string;
    mobId: number;
    state: number;
}

export interface ILocationRecordMap {
    [index: number]: RecordState[];
}

export interface IInstanceLocationMap {
    [instance: number]: ILocationRecordMap;
}

export interface IZoneInstanceMap {
    [zoneId: number]: IInstanceLocationMap;
}

export interface SocketCache {
    socketId: string;
    id: string;
    worldId: number;
    zoneInstances: string[];
}

export interface UserCache {
    id: string;
    type: string;
    userName: string;
    avatarUrl: string;
}

export interface SocketUser extends SocketCache, UserCache {
}

export interface LatestDatesMap {
    [zoneinstance: string]: string[]
}

export interface MobReport {
    reporter: string;
    reportedAt: string;
    mobId: number;
    state: number;
}

export interface ReportMap {
    [index: number]: MobReport[]
}

export interface IndexMap {
    [zoneinstance: string]: ReportMap
}

export interface IShareLocationRecordState {
    sockets: { [socketId: string]: SocketCache };
    members: { [userId: string]: UserCache };
    latestDates: LatestDatesMap;
    zoneInstances: IndexMap;
}

const name = `sharelocationrecords`

/*
try {
    store.unregisterModule(name)
} catch (error) {
    console.warn(`ignore ${error}`)
}
*/

@Module({ dynamic: true, store, name, namespaced: true, preserveState: false })
class ShareLocationRecordState extends VuexModule implements IShareLocationRecordState {
    // state
    sockets: { [socketId: string]: SocketCache } = {}
    members: { [userId: string]: UserCache } = {}
    latestDates: LatestDatesMap = {}
    zoneInstances: IndexMap = {}

    public get getLatestDates() {
        return (zoneId: number, instance: number): Date[] => {
            const zoneInstance = `${zoneId}_${instance}`;
            return this.latestDates[zoneInstance] ? this.latestDates[zoneInstance].map(latestDate=>new Date(latestDate)) : [];
        }
    }

    public get getMembers(): UserCache[] {
        const userIds = [...new Set(Object.values(this.sockets).map(socket=>socket.id))];
        return userIds.map(userId=>this.members[userId] ?? {
            id: "",
            type: "",
            userName: "",
            avatarUrl: "",
        });
    }

    public get getSocketUsers() {
        return (worldId: number, zoneId: number, instance: number): SocketUser[] => {
            const zoneInstance = `${zoneId}_${instance}`;
            const sockets = Object.values(this.sockets).filter(socket=> {
               return socket.worldId == worldId && socket.zoneInstances.includes(zoneInstance) && socket.socketId != userModule.socket
            });
            return sockets.map(socket=> {
                return {
                    socketId: socket.socketId,
                    id: socket.id,
                    worldId: socket.worldId,
                    zoneInstances: socket.zoneInstances,
                    type: this.members[socket.id].type,
                    avatarUrl: this.members[socket.id].avatarUrl,
                    userName: this.members[socket.id].userName,
                }
            })
        }
    }

    public get getReportMap() {
        return (zoneId: number, instance: number): ReportMap => {
            return this.zoneInstances[`${zoneId}_${instance}`];
        }
    }

    public get getLocationRecords() {
        return (zoneId: number, instance: number, index: number): MobReport[] => {
            const reportMap: ReportMap = this.zoneInstances[`${zoneId}_${instance}`];
            if (reportMap && reportMap[index]) {
                return reportMap[index];
            }
            return [];
        }
    }

    // mutation
    @Mutation
    public SET_SOCKET(socket: SocketCache) {
        Vue.set(this.sockets, socket.socketId, socket);
    }

    @Mutation
    public DELETE_SOCKET(socketId: string) {
        Vue.delete(this.sockets, socketId);
    }

    @Mutation
    public CLEAR_SOCKETS() {
        Vue.set(this, "sockets", {});
    }

    @Mutation
    public SET_USER(user: UserCache ) {
        Vue.set(this.members, user.id, user);
    }

    @Mutation
    public SET_LATEST_DATES_OF_ZONEINSTANCE(payload: { zoneInstance: string, dates: string[] }) {
        Vue.set(this.latestDates, payload.zoneInstance, payload.dates);
    }

    @Mutation
    public SET_LATEST_DATES(payload: LatestDatesMap) {
        Vue.set(this, "latestDates", payload);
    }

    @Mutation
    public CLEAR_LATEST_DATES() {
        Vue.set(this, "latestDates", {});
    }

    @Mutation
    public SET_ZONEINSTANCE(payload: { zoneInstance: string, data: ReportMap }) {
        Vue.set(this.zoneInstances, payload.zoneInstance, payload.data);
    }

    @Mutation
    public SET_ZONEINSTANCES(payload: IndexMap) {
        Vue.set(this, "zoneInstances", payload);
    }

    @Mutation
    public SET_RECORDS(payload: { zoneInstance: string, index: number, reports: MobReport[] }) {
        if (payload.reports.length > 0) {
            if (!this.zoneInstances[payload.zoneInstance]) {
                Vue.set(this.zoneInstances, payload.zoneInstance, {});
            }
            Vue.set(this.zoneInstances[payload.zoneInstance], payload.index, payload.reports);
        }
        else {
            if (this.zoneInstances[payload.zoneInstance] && this.zoneInstances[payload.zoneInstance][payload.index]) {
                Vue.delete(this.zoneInstances[payload.zoneInstance], payload.index);
                if (Object.keys(this.zoneInstances[payload.zoneInstance]).length == 0) {
                    Vue.delete(this.zoneInstances, payload.zoneInstance);
                }
            }
        }
    }

    @Mutation
    public CLEAR_ZONEINSTANCES() {
        Vue.set(this, "zoneInstances", {});
    }

}

export const sharelocationrecordsModule = getModule(ShareLocationRecordState);