









































import Vue from "vue";
import { World, worlds } from "ffxivhuntdata"

export default Vue.extend({
  name: "WorldSelector",
  props: {
    value: Number
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dataCenters(): { name: string, selectedIndex: number, worlds: World[] }[] {
      return [...new Set(worlds.filter(w => w.dcRegion === 1).map(w => w.dcName))].map(dc => {
        const worldsInDc = worlds.filter(w => w.dcName === dc)
        const worldIndex = worldsInDc.findIndex(w => w.id === this.innerWorldId);
        return {
          name: dc,
          selectedIndex: (worldIndex >= 0) ? worldIndex : -1,
          worlds: worldsInDc
        }
      });
    },
    selectedWorld(): World {
      const world = worlds.find(w => w.id === this.innerWorldId);
      return world != null ? world : worlds[0];
    },
    selectedDc(): string {
      const dc = this.dataCenters.find(dc => dc.worlds.map(w => w.id).includes(this.innerWorldId));
      return dc != null ? dc.name : '';
    },
    innerWorldId: {
      get(): number {
        return this.value;
      },
      set(value: number): void {
        if (this.value !== value) this.$emit('input', value);
      }
    }
  },
});
