import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ja'
import { getLang, IMob, messages, toFixedFloor, worlds, Zone, zones } from 'ffxivhuntdata';

dayjs.locale('ja');
dayjs.extend(utc)

function resolveWorld(worldId: number): string {
  const world = worlds.find(world => world.id === worldId);
  return world != null ? `${world.name}(${worldId})` : `Unknown World(${worldId})`;
}

function resolveZone(zoneId: number, zoneName = 'Unknown Zone'): string {
  const zone = zones.find(zone => zone.id === zoneId);
  return zone != null ? `${zone.name}(${zoneId})` : `${zoneName}(${zoneId})`;
}

function resolveMob(mobId: number): string {
  const mobName = messages[getLang()].mob[mobId];
  return mobName != null ? `${mobName}(${mobId})` : `Unknown Mob(${mobId})`;
}

export interface ICenturionEvent {
  type: string;
  timestamp: string;
}

export class CenturionEvent implements ICenturionEvent {
  type: string;
  timestamp: string;
  constructor(init: ICenturionEvent) {
    this.type = init.type;
    this.timestamp = init.timestamp;
  }
  toString(): string {
    return `${dayjs.utc(this.timestamp).local().format('HH:mm:ss')}: `;
  }
}

export class PlayerLoggedOutEvent extends CenturionEvent {
  [Symbol.toStringTag] = "PlayerLoggedOutEvent";
  constructor(init: ICenturionEvent) {
    super(init);
  }
  toString(): string {
    return `Player Logged Out`;
  }
}

export interface IZoneChangedEvent extends ICenturionEvent {
  worldId: number;
  zoneId: number;
  zoneName: string;
}

export class ZoneChangedEvent extends CenturionEvent implements IZoneChangedEvent {
  [Symbol.toStringTag] = "ZoneChangedEvent";
  worldId: number;
  zoneId: number;
  zoneName: string;
  constructor(init: IZoneChangedEvent) {
    super(init);
    this.worldId = init.worldId;
    this.zoneId = init.zoneId;
    this.zoneName = init.zoneName;
  }
  toString(): string {
    return `Zone Changed: ${resolveWorld(this.worldId)}, ${resolveZone(this.zoneId, this.zoneName)}`;
  }
}

export interface IZoneInstanceChangedEvent extends ICenturionEvent {
  zoneName: string;
  instance: number;
}

export class ZoneInstanceChangedEvent extends CenturionEvent implements IZoneInstanceChangedEvent {
  [Symbol.toStringTag] = "ZoneInstanceChangedEvent";
  zoneName: string;
  instance: number;
  constructor(init: IZoneInstanceChangedEvent) {
    super(init);
    this.zoneName = init.zoneName;
    this.instance = init.instance;
  }
  toString(): string {
    return `Zone Instance Changed: ${this.zoneName} - ${this.instance}`;
  }
}

export interface IPlayerLoggedInEvent extends ICenturionEvent {
  playerId: number;
  playerName: string;
  playerWorldId: number;
  worldId: number;
  zoneId: number;
  zoneName: string;
}

export class PlayerLoggedInEvent extends CenturionEvent implements IPlayerLoggedInEvent {
  [Symbol.toStringTag] = "PlayerLoggedInEvent";
  playerId: number;
  playerName: string;
  playerWorldId: number;
  worldId: number;
  zoneId: number;
  zoneName: string;
  constructor(init: IPlayerLoggedInEvent) {
    super(init);
    this.playerId = init.playerId;
    this.playerName = init.playerName;
    this.playerWorldId = init.playerWorldId;
    this.worldId = init.worldId;
    this.zoneId = init.zoneId;
    this.zoneName = init.zoneName;
  }
  toString(): string {
    return `Player Logged In: ${this.playerName}@${resolveWorld(this.playerWorldId)}`;
  }
}

export interface ILocationNotifiedEvent extends ICenturionEvent {
  logType: string;
  message: string;
  pc: string;
  zoneName: string;
  instance: number;
  x: number;
  y: number;
}

export class LocationNotifiedEvent extends CenturionEvent implements ILocationNotifiedEvent {
  [Symbol.toStringTag] = "LocationNotifiedEvent";
  logType: string;
  message: string;
  pc: string;
  zoneName: string;
  instance: number;
  x: number;
  y: number;
  constructor(init: ILocationNotifiedEvent) {
    super(init);
    this.logType = init.logType;
    this.message = init.message;
    this.pc = init.pc;
    this.zoneName = init.zoneName;
    this.instance = init.instance;
    this.x = init.x;
    this.y = init.y;
  }
  toString(): string {
    return `Location Notified: ${this.logType}, ${this.pc}, ${this.zoneName}(${this.x.toFixed(1)}, ${this.y.toFixed(1)})`;
  }
}

export interface IMobFAEvent extends ICenturionEvent {
  message: string;
  id: number;
  mobId: number;
  mobName: string;
  action: string;
  attacker: string;
}

export class MobFAEvent extends CenturionEvent implements IMobFAEvent {
  [Symbol.toStringTag] = "MobFAEvent";
  message: string;
  id: number;
  mobId: number;
  mobName: string;
  action: string;
  attacker: string;
  constructor(init: IMobFAEvent) {
    super(init);
    this.message = init.message;
    this.id = init.id;
    this.mobId = init.mobId;
    this.mobName = init.mobName;
    this.action = init.action;
    this.attacker = init.attacker;
  }
  toString(): string {
    return `First Attack: ${this.attacker} =(${this.action})=> ${this.mobName}`;
  }
}

export interface IMobTriggerEvent extends ICenturionEvent {
  triggerType: string;
  worldId: number;
  zoneId: number;
  message: string;
}

export class MobTriggerEvent extends CenturionEvent implements IMobTriggerEvent {
  [Symbol.toStringTag] = "MobTriggerEvent";
  triggerType: string;
  worldId: number;
  zoneId: number;
  message: string;
  constructor(init: IMobTriggerEvent) {
    super(init);
    this.triggerType = init.triggerType;
    this.worldId = init.worldId;
    this.zoneId = init.zoneId;
    this.message = init.message;
  }
  toString(): string {
    return `Mob Trigger: ${this.triggerType}, ${resolveWorld(this.worldId)}, ${resolveZone(this.zoneId)}`;
  }
}

export interface IMobLocationEvent extends ICenturionEvent {
  worldId: number;
  zoneId: number;
  id: number;
  mobId: number;
  x: number;
  y: number;
  z: number;
}

export class MobLocationEvent extends CenturionEvent implements IMobLocationEvent {
  [Symbol.toStringTag] = "MobLocationEvent";
  worldId: number;
  zoneId: number;
  id: number;
  mobId: number;
  x: number;
  y: number;
  z: number;
  constructor(init: IMobLocationEvent) {
    super(init);
    this.worldId = init.worldId;
    this.zoneId = init.zoneId;
    this.id = init.id;
    this.mobId = init.mobId;
    this.x = init.x;
    this.y = init.y;
    this.z = init.z;
  }
  get zone(): Zone|undefined {
    return zones.find(zone => zone.id === this.zoneId);
  }
  toString(): string {
    const pos = this.zone ? this.zone.toLocalPosition(this) : {x:this.x, y:this.y, z:this.z};
    return `Mob Location: ${resolveWorld(this.worldId)}, ${resolveZone(this.zoneId)}, ${this.id}, ${resolveMob(this.mobId)}, (${toFixedFloor(pos.x, 1)}, ${toFixedFloor(pos.y, 1)}, ${toFixedFloor(pos.z, 1)})`;
  }
}

export interface IMobStateChangedEvent extends ICenturionEvent {
  state: string;
  worldId: number;
  zoneId: number;
  id: number;
  mobId: number;
  x: number;
  y: number;
  z: number;
  distance: number;
  hpp: number;
}

export class MobStateChangedEvent extends CenturionEvent implements IMobStateChangedEvent {
  [Symbol.toStringTag] = "MobStateChangedEvent";
  state: string;
  worldId: number;
  zoneId: number;
  id: number;
  mobId: number;
  x: number;
  y: number;
  z: number;
  distance: number;
  hpp: number;
  constructor(init: IMobStateChangedEvent) {
    super(init);
    this.state = init.state;
    this.worldId = init.worldId;
    this.zoneId = init.zoneId;
    this.id = init.id;
    this.mobId = init.mobId;
    this.x = init.x;
    this.y = init.y;
    this.z = init.z;
    this.distance = init.distance;
    this.hpp = init.hpp;
  }
  get zone(): Zone|undefined {
    return zones.find(zone => zone.id === this.zoneId);
  }
  get mob(): IMob|undefined {
    return this.zone?.mobs.find(mob => mob.id === this.mobId);
  }
  get locationIndex(): number {
    if (this.zone) {
      const localPosition = this.zone.toLocalPosition(this);
      const indices = this.zone.getEliteLocationIndices(localPosition, 1.2);
      return indices.length === 1 ? indices[0] : -1;
    }
    return -1;
  }
  toString(): string {
    const pos = this.zone ? this.zone.toLocalPosition(this) : {x:this.x, y:this.y, z:this.z};
    return `Mob State(${this.state}): ${resolveWorld(this.worldId)}, ${resolveZone(this.zoneId)}, ${this.id}, ${resolveMob(this.mobId)}, (${toFixedFloor(pos.x, 1)}, ${toFixedFloor(pos.y, 1)}, ${toFixedFloor(pos.z, 1)})`;
  }
}

export interface BaseCombatant {
  ID: number;
  Name: string;
  OwnerID: number;
  TargetID: number;
  BNpcID: number;
  BNpcNameID: number;
  type: number;
}

export interface PosCombatant extends BaseCombatant {
  PosX: number;
  PosY: number;
  PosZ: number;
  Heading: number;
  Distance: number;
}

export interface CharCombatant extends PosCombatant {
  Job: number;
  Level: number;
  WorldID: number;
  WorldName: string;
  CurrentHP: number;
  MaxHP: number;
  HPP: number;
  PartyType: number;
  Order: number;
}

export interface IAetheryte {
  posX: number;
  posY: number;
  posZ: number;
  x: number;
  y: number;
  z: number;
  heading: number;
  distance: number;
  id: number;
  name: string;
  ownerId: number;
  targetId: number;
  bNpcId: number;
  bNpcNameId: number;
  type: number;
}

export interface ICharacter extends IAetheryte {
  jobId: number;
  level: number;
  worldID: number;
  worldName: string;
  currentHp: number;
  maxHp: number;
  hpp: number;
  partyType: number;
  order: number;
}

export function toCharacter(combatant: CharCombatant, zone: Zone|undefined): ICharacter {
  const pos = zone ? zone.toLocalPosition({x: combatant.PosX, y: combatant.PosY, z: combatant.PosZ }) : {x: combatant.PosX, y: combatant.PosY, z: combatant.PosZ };
  return {
    posX: combatant.PosX,
    posY: combatant.PosY,
    posZ: combatant.PosZ,
    x: pos.x,
    y: pos.y,
    z: pos.z,
    heading: combatant.Heading,
    distance: combatant.Distance,
    id: combatant.ID,
    name: combatant.Name,
    ownerId: combatant.OwnerID,
    targetId: combatant.TargetID,
    bNpcId: combatant.BNpcNameID,
    bNpcNameId: combatant.BNpcNameID,
    type: combatant.type,
    jobId: combatant.Job,
    level: combatant.Level,
    worldID: combatant.WorldID,
    worldName: combatant.WorldName,
    currentHp: combatant.CurrentHP,
    maxHp: combatant.MaxHP,
    hpp: combatant.HPP,
    partyType: combatant.PartyType,
    order: combatant.Order
  }
}


export interface ICombatDataEvent extends ICenturionEvent {
  self: CharCombatant;
  party: CharCombatant[];
  pcs: CharCombatant[];
  targets: CharCombatant[];
  aetherytes: PosCombatant[];
  zoneId: number;
  zoneName: string;
  countPC: number;
  countNPC: number;
  countChocobos: number;
  countPets: number;
  countTotal: number;
  isCrowded: boolean;
}

export class CombatDataEvent extends CenturionEvent implements ICombatDataEvent {
  [Symbol.toStringTag] = "CombatDataEvent";
  self: CharCombatant;
  party: CharCombatant[];
  pcs: CharCombatant[];
  targets: CharCombatant[];
  aetherytes: PosCombatant[];
  zoneId: number;
  zoneName: string;
  countPC: number;
  countNPC: number;
  countChocobos: number;
  countPets: number;
  countTotal: number;
  isCrowded: boolean;
  constructor(init: ICombatDataEvent) {
    super(init);
    this.self = init.self;
    this.party = init.pcs.filter(pc=>pc.PartyType===1 && pc.ID !== this.self.ID);
    this.pcs = init.pcs;
    this.targets = init.targets;
    this.aetherytes = init.aetherytes;
    this.zoneId = init.zoneId;
    this.zoneName = init.zoneName;
    this.countPC = init.countPC;
    this.countNPC = init.countNPC;
    this.countChocobos = init.countChocobos;
    this.countPets = init.countPets;
    this.countTotal = init.countTotal;
    this.isCrowded = init.isCrowded;
  }
  get zone(): Zone|undefined {
    return zones.find(zone => zone.id === this.zoneId);
  }
  get playerChar(): ICharacter|null {
    return this.self != null ? toCharacter(this.self, this.zone) : null;
  }
  get partyChars(): ICharacter[] {
    return this.party.map(p => toCharacter(p, this.zone));
  }
  get targetChars(): ICharacter[] {
    return this.targets.map(t => toCharacter(t, this.zone));
  }
  toString(): string {
    return `${super.toString()}CD ${this.self?.Name}, ${this.countTotal}`;
  }
}

export interface IMapLocation {
  zoneId: number;
  x: number;
  y: number;
}