





































































































































import Vue, { PropType } from "vue";

import { DEFAULTZONE } from "ffxivhuntdata";
import MapHeader from '@/components/MapHeader.vue'
import { IZoneInstance, MobMapHandler, ZoneInstance } from "@/libs/mobmapHandler"

import LocationEditDialog from "@/components/LocationEditDialog.vue"
import { LocationRecord, MobReport2, worldModule } from "@/store/modules/world";
import dayjs from "dayjs";
import { settingsModule } from "@/store/modules/settings"
import { sharelocationrecordsModule, SocketUser } from "@/store/modules/sharelocationrecords";

interface Data {
  mmh: MobMapHandler;
  dialog: boolean;
  timeline: boolean;
  menu: boolean;
  fab: boolean;
  self: { x: number, y: number }
  scale: number;
  zoneInstance: IZoneInstance;
  selectedIndex: number;
}

/*
interface MobDictionary {
  display: number;
  color: string;
  bgColor: string;
  mob: undefined | IMob;
}
*/

export default Vue.extend({
  name: "Map",
  components: {
    MapHeader,
    LocationEditDialog
  },
  props: {
    zoneInstances: Array as PropType<IZoneInstance[]>,
  },
  data(): Data {
    return {
      mmh: new MobMapHandler(),
      dialog: false,
      timeline: false,
      menu: false,
      fab: false,
      self: { x: 21, y: 21 },
      scale: 1.0,
      zoneInstance: new ZoneInstance(DEFAULTZONE, 1, false),
      //      zoneId: 0,
      //      instance: 1,
      selectedIndex: -1,
    };
  },
  async mounted() {
    const zoneId = parseInt(this.$route.params.zoneid, 10);
    const instance = parseInt(this.$route.params.instance, 10);
    const zoneInstance = this.zoneInstances.find(zi => zi.zone.id === zoneId && zi.instance === instance);
    if (zoneInstance) {
      this.zoneInstance = zoneInstance;
    }

    const parent = document.getElementById('canvasWrapper') as HTMLElement;
    parent.appendChild(this.mmh.canvas);
    this.mmh.showLabel = this.showLabel
    await this.mmh.initialize(this.display, this.onSelectLocation);

    window.addEventListener("resize", this.onResize);
    this.$nextTick(() => {
      this.onResize();
      this.mmh.setZoneInstance(this.zoneInstance);
      this.mmh.focusRect();

      this.$emit('addTab', zoneInstance)
    });
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "hidden";
  },
  beforeDestroy() {
    console.warn("should stop animation")
    this.mmh.animate(false)
    window.removeEventListener("resize", this.onResize);
  },
  destroyed() {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "";
  },

  computed: {
    display() {
      return settingsModule.display;
    },
    menuText(): string {
      let text = ''
      const smobInfo = this.zoneInstance.smobInfo
      if (smobInfo) {
        const now = dayjs();
        text = now.isAfter(smobInfo.respawnStart) ?
          `${this.$t('inSpawnPeriod')}[${smobInfo.chance}%]` :
          `${this.$t('toSpawnStart')}${smobInfo.timeToStart}`
        if (smobInfo.showNarrowDown) {
          text += ` - ${this.$t('narrowDown')} ${smobInfo.narrowDown}`
        }
      }
      return text;
    },
    showServerReset(): boolean {
      const smobInfo = this.zoneInstance.smobInfo
      if (smobInfo) {
        return smobInfo.afterServerReset
      }
      return false;

    },
    serverResetText(): string {
      const smobInfo = this.zoneInstance.smobInfo
      if (smobInfo) {
        return dayjs(smobInfo.serverReset).format(`YYYY/MM/DD HH:mm`)
      }
      return "";
    },
    showLastKilled(): boolean {
      const smobInfo = this.zoneInstance.smobInfo
      if (smobInfo) {
        return !smobInfo.afterServerReset
      }
      return false;
    },
    lastKilledText(): string {
      const smobInfo = this.zoneInstance.smobInfo
      if (smobInfo) {
        return dayjs(smobInfo.lastKilled).format(`YYYY/MM/DD HH:mm`)
      }
      return "";
    },
    showRespawnStart(): boolean {
      const smobInfo = this.zoneInstance.smobInfo
      if (smobInfo) {
        return true;
      }
      return false;
    },
    respawnStartText(): string {
      const smobInfo = this.zoneInstance.smobInfo
      if (smobInfo) {
        return dayjs(smobInfo.respawnStart).format(`YYYY/MM/DD HH:mm`)
      }
      return "";
    },
    mapCustomInput: {
      get(): boolean {
        return settingsModule.mapCustomInput;
      },
      set(value: boolean): void {
        settingsModule.SET_MAP_CUSTOM_INPUT(value)
      }
    },
    showLabel: {
      get(): boolean {
        return settingsModule.mapShowLabel;
      },
      set(value: boolean): void {
        settingsModule.SET_MAP_SHOW_LABEL(value)
        this.mmh.showLabel = this.showLabel
      }
    },
    activeSockets(): SocketUser[] {
      const sockets = sharelocationrecordsModule.getSocketUsers(worldModule.id, this.zoneInstance.zone.id, this.zoneInstance.instance);
      return sockets;
    },
    locationRecord(): LocationRecord {
      return worldModule.getLocationRecord(this.zoneInstance.zone.id, this.zoneInstance.instance, this.selectedIndex);
    },
    hasAbsence(): boolean {
      return worldModule.hasAbsence(this.zoneInstance.zone.id, this.zoneInstance.instance, this.selectedIndex);
    }

    /*    mobDictionary(): { S: MobDictionary, A: MobDictionary, B: MobDictionary, A2: MobDictionary, B2: MobDictionary, SS: MobDictionary } {
          const aList = this.zone.mobs.filter(m => m.type == 'elite' && m.rank == 'A')
          const bList = this.zone.mobs.filter(m => m.type == 'elite' && m.rank == 'B')
          const S = this.zone.mobs.find(m => m.type == 'elite' && m.rank == 'S');
          const A = aList.length > 0 ? aList[0] : undefined;
          const B = bList.length > 0 ? bList[0] : undefined;
          const A2 = aList.length > 1 ? aList[1] : undefined;
          const B2 = bList.length > 1 ? bList[1] : undefined;
          const SS = this.zone.mobs.find(m => m.type == 'ss' && m.rank == 'S');
          const result = {
            S: {
              display: this.display.S,
              color: "#f44336",
              bgColor: "#ca330f",
              mob: S
            },
            A: {
              display: this.display.A,
              color: "#ffeb3b",
              bgColor: "#dfa824",
              mob: A
            },
            B: {
              display: this.display.B,
              color: "#2196f3",
              bgColor: "#3171ba",
              mob: B
            },
            A2: {
              display: this.display.A2,
              color: "#8bc34a",
              bgColor: "#3c933b",
              mob: A2
            },
            B2: {
              display: this.display.B2,
              color: "#9c27b0",
              bgColor: "#a54674",
              mob: B2
            },
            SS: {
              display: this.display.SS,
              color: "#000000",
              bgColor: "#1f8cad",
              mob: SS
            }
          }
          return result;
        },*/
  },
  watch: {
    $route: function () {
      console.debug("$route Changed", this.$route.params);
      const zoneId = parseInt(this.$route.params.zoneid, 10);
      const instance = parseInt(this.$route.params.instance, 10);
      const zoneInstance = this.zoneInstances.find(zi => zi.zone.id === zoneId && zi.instance === instance);
      if (zoneInstance) {
        this.zoneInstance = zoneInstance;
        this.mmh.setZoneInstance(this.zoneInstance);
      }
    },
  },
  methods: {
    focus() {
      this.mmh.focusRect();
    },
    removeOld() {
      const payload = { zoneId: this.zoneInstance.zone.id, instance: this.zoneInstance.instance }
      worldModule.removeOldLocationRecords(payload);
    },
    closeDialog() {
      this.dialog = false;
    },
    onResize() {
      const clientWidth = document.documentElement.clientWidth;
      const clientHeight = document.documentElement.clientHeight;
      const headerElement = document.querySelector("header") as HTMLElement;
      const mapheader = document.querySelector("#mapheader") as HTMLElement;
      const footerElement = document.querySelector("footer") as HTMLElement;
      const canvasHeight = clientHeight - headerElement.offsetHeight - footerElement.offsetHeight - mapheader.offsetHeight;
      this.mmh.setSize(clientWidth, canvasHeight);
    },
    onSelectLocation(index: number) {
      this.selectedIndex = index;

      this.timeline = true;
    },
    formatMobState(mobReport: MobReport2): string {
      if (mobReport.state == 1000) {
        return this.$t('serverResets') as string;
      }
      else if (mobReport.state == 2000) {
        return this.$t('absent') as string;
      }
      const stateMap: { [code: number]: string } = {
        0: this.$t('found') as string,
        1: this.$t('defeated') as string,
        100: this.$t('found') as string,
        101: this.$t('defeated') as string,
        1001: this.$t('lastKilled') as string,
      }
      const mobName = mobReport.mobId != 0 ? mobReport.mobName : this.$t('mobNotSpecified') as string
      return `${mobName}<br/>${stateMap[mobReport.state]}`;
    },
    addReport() {
      if (settingsModule.mapCustomInput) {
        this.dialog = !this.dialog
      }
      else {
        worldModule.setRecord({
          zoneId: this.zoneInstance.zone.id,
          instance: this.zoneInstance.instance,
          index: this.selectedIndex,
          record: {
            date: (new Date()).toISOString(),
            mobId: 0,
            state: 0
          }
        })
      }
    },
    deleteReport() {
      worldModule.deleteRecord({
        zoneId: this.zoneInstance.zone.id,
        instance: this.zoneInstance.instance,
        index: this.selectedIndex
      })
    },
    setAbsence() {
      worldModule.setAbsence({
        zoneId: this.zoneInstance.zone.id,
        instance: this.zoneInstance.instance,
        index: this.selectedIndex,
        absenceAt: new Date()
      })
    },
    deleteAbsence() {
      worldModule.deleteAbsence({
        zoneId: this.zoneInstance.zone.id,
        instance: this.zoneInstance.instance,
        index: this.selectedIndex
      })
    },
    /*
    async loadImage(src: string): Promise<HTMLImageElement> {
      return await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    },
    getAlphaOfTimestamp(
      timestamp: number,
      frequency = 1.0,
      max = 1.0,
      min = 0.0
    ) {
      const rad =
        2 * Math.PI * frequency * 0.001 * (timestamp % (1000 / frequency));
      return (Math.cos(rad) / 2 + 0.5) * (max - min) + min;
    },
    getMobColor(mobId: number): string {
      let result = "black";
      const elites = this.zone.mobs.filter((m) => m.type == "elite");
      const index = elites.findIndex((m) => m.id == mobId);
      if (elites.length == 3) {
        result =
          index == 0
            ? "#ca330f"
            : index == 1
              ? "#dfa824"
              : index == 2
                ? "#3171ba"
                : "black";
      } else {
        result =
          index == 0
            ? "#ca330f"
            : index == 1
              ? "#dfa824"
              : index == 2
                ? "#3c933b"
                : index == 3
                  ? "#3171ba"
                  : index == 4
                    ? "#a54674"
                    : "black";
      }
      return result;
 
 
    },*/
  },
});
