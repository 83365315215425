import { render, staticRenderFns } from "./WorldSelector.vue?vue&type=template&id=d07e7e76&scoped=true&"
import script from "./WorldSelector.vue?vue&type=script&lang=ts&"
export * from "./WorldSelector.vue?vue&type=script&lang=ts&"
import style0 from "./WorldSelector.vue?vue&type=style&index=0&id=d07e7e76&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d07e7e76",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VList,VListGroup,VListItem,VListItemGroup,VListItemTitle,VMenu,VSubheader})
