



















































import Vue from "vue";
import { messages, regions, getLang } from 'ffxivhuntdata'
import { settingsModule } from "@/store/modules/settings";

interface Zone {
  id: number;
  name: string;
}

interface Region {
  name: string;
  class: string;
  selectedIndex: number;
  zones: Zone[];
}

export default Vue.extend({
  name: 'ZoneSelector',
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    regions(): Region[] {
      return regions.filter(r => r.key !== 'Eureka').map(r => {
        const zones = r.zoneIds.map(zid => {
          return {
            id: zid,
            name: messages[getLang()].zone[zid]
          }
        });
        const zoneIndex = zones.findIndex(zone => zone.id === this.zoneId);
        return {
          name: r.name,
          selectedIndex: (zoneIndex >= 0) ? zoneIndex : -1,
          class: r.class,
          zones: zones
        };
      });
    },
    zones(): Zone[] {
      const allZoneIds = regions.reduce((arr: number[], region) => { return arr.concat(region.zoneIds) }, []);
      return allZoneIds.map(zid => { return { id: zid, name: messages[getLang()].zone[zid] } });
    },
    selectedZone(): Zone {
      return { id: this.zoneId, name: messages[getLang()].zone[this.zoneId] }
    },
    selectedRegionName(): string {
      const region = this.regions.find(region => region.zones.map(z => z.id).includes(this.zoneId));
      return region != null ? region.name : '';
    },
    selectedRegionClass(): string {
      const region = this.regions.find(region => region.zones.map(z => z.id).includes(this.zoneId));
      return region != null ? region.class : '';
    },
    zoneId: {
      get(): number {
        return settingsModule.mobLocationsZoneId;
      },
      set(value: number): void {
        settingsModule.SET_MOB_LOCATIONS_ZONE_ID(value);
      }
    }
  },
});
