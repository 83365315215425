

































import { userModule } from "@/store/modules/user";
import Vue from "vue";

interface IMenuItem {
  to: string;
  icon: string;
  label: string;
  display: boolean;
}

export default Vue.extend({
  name: "NavigationDrawer",
  props: ['value'],
  data() {
    return {
      localDrawer: this.value,
    }
  },
  computed: {
    isAuthorized(): boolean {
      return (userModule.isAdmin);
    },
    items(): IMenuItem[] {
      return [
        {
          to: "/",
          icon: "mdi-alpha-s-box",
          label: this.$t('sTimeTable') as string,
          display: true
        },
        {
          to: "/mobmaps",
          icon: "mdi-map-check",
          label: this.$t('mobMaps') as string,
          display: true
        },
        {
          to: "/moblocations",
          icon: "mdi-map-marker",
          label: this.$t('spawnLocations') as string,
          display: true
        },
        {
          to: "/weathers",
          icon: "mdi-weather-sunny",
          label: this.$t('weathers') as string,
          display: true
        },
        {
          to: "/serversettings",
          icon: "mdi-wrench",
          label: this.$t('serverSettings') as string,
          display: this.isAuthorized
        },
        {
          to: "/manageGroups",
          icon: "mdi-account-multiple",
          label: this.$t('manageGroups') as string,
          display: this.isAuthorized
        },
        {
          to: "/guide",
          icon: "mdi-book-open-variant",
          label: this.$t('guide') as string,
          display: true
        },
        {
          to: "/readMe",
          icon: "mdi-exclamation-thick",
          label: this.$t('readMe') as string,
          display: true
        }
      ]
    }
  },
  watch: {
    value: function () {
      this.localDrawer = this.value;
    },
    localDrawer: function () {
      this.$emit('input', this.localDrawer);
    }
  }
});
