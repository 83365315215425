
































































































































import Vue from "vue";
import dayjs from 'dayjs'
import { worlds } from 'ffxivhuntdata'
import { getServerResets, setServerResets } from "../libs/serverProxy"

interface ITableHeader {
  text: string;
  value: string;
  sortable?: boolean
}

interface ServerReset {
  worldId: number;
  worldName: string;
  dcName: string;
  resetDate: Date;
}

interface Data {
  dcTabIndex: number,
  dialog: boolean,
  dialogDate: boolean,
  dialogTime: boolean,
  date: string,
  time: string,
  headers: ITableHeader[],
  items: ServerReset[],
  selectedItems: ServerReset[]
}

export default Vue.extend({
  name: 'ServerResets',
  components: {
  },
  data(): Data {
    return {
      dcTabIndex: -1,
      dialog: false,
      dialogDate: false,
      dialogTime: false,
      date: '',
      time: '',
      headers: [
        { text: this.$t("world") as string, value: 'worldName' },
        { text: this.$t("resetDate") as string, value: 'resetDate' },
      ],
      items: [],
      selectedItems: [],
    };
  },
  computed: {
    dcNames(): string[] {
      return [...new Set(worlds.filter(w => w.dcRegion === 1).map(w => w.dcName))]
    },
    selectedDC(): string {
      return this.dcTabIndex >= 0 ? this.dcNames[this.dcTabIndex] : '';
    },
    itemsOfSelectedDC(): ServerReset[] {
      return this.items.filter(w => {
        return w.dcName == this.selectedDC
      })
    },
    selectedItemsOfSelectedDC(): ServerReset[] {
      return this.selectedItems.filter(w => {
        return w.dcName == this.selectedDC
      })
    }
  },
  watch: {
    dcTabIndex: function (newValue, oldValue) {
      console.debug("dcTabIndex", newValue, oldValue)
    },
    dcNames: function (newValue, oldValue) {
      console.debug("dcNames", newValue, oldValue)
    },
  },
  methods: {
    async load() {
      const serverResets = await getServerResets();
      this.items = worlds.map(w => {
        const serverReset = serverResets.find((s) => s.worldId === w.id)
        return {
          worldId: w.id,
          worldName: w.name,
          dcName: w.dcName,
          resetDate: serverReset != null ? serverReset.resetDate : new Date()
        };
      })
    },


    close() {
      console.debug('close')
      this.dialog = false;

    },
    formatDate(date: Date): string {
      return dayjs(date).format('YYYY/MM/DD(ddd) HH:mm');
    },

    startEdit() {
      const dateTime = this.selectedItemsOfSelectedDC[0].resetDate
        ? this.selectedItemsOfSelectedDC[0].resetDate
        : new Date();
      this.date = dayjs(dateTime).format("YYYY-MM-DD");
      this.time = dayjs(dateTime).format("HH:mm");
      this.dialog = true;
    },
    async endEdit() {
      const newDateTime = dayjs(`${this.date} ${this.time}:00`,
        "YYYY-MM-DD HH:mm:ss");
      console.debug("newDateTime", newDateTime);
      this.selectedItemsOfSelectedDC.forEach(item => {
        item.resetDate = newDateTime.toDate();
      })
      const requestBody = {
        worldIds: this.selectedItemsOfSelectedDC.map(item => item.worldId),
        resetDate: newDateTime.toDate()
      }
      console.debug("requestBody", requestBody);
      const result = await setServerResets(requestBody);
      console.debug("updated serverresets", result);
      this.dialog = false;
    },
  },
  created() {
    this.load();
  }

});
