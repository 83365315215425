








































































































import { settingsModule } from "@/store/modules/settings";
import Vue from "vue";

export default Vue.extend({
  name: "MapHeader",
  components: {
  },
  data() {
    return {
    }
  },
  props: {
    zone: Object
  },
  computed: {
    displayS: {
      get(): number {
        return settingsModule.display.S;
      },
      set(value: number) {
        settingsModule.SET_DISPLAY_S(value);
      }
    },
    displayA: {
      get(): number {
        return settingsModule.display.A;
      },
      set(value: number) {
        settingsModule.SET_DISPLAY_A(value);
      }
    },
    displayB: {
      get(): number {
        return settingsModule.display.B;
      },
      set(value: number) {
        settingsModule.SET_DISPLAY_B(value);
      }
    },
    displayA2: {
      get(): number {
        return settingsModule.display.A2;
      },
      set(value: number) {
        settingsModule.SET_DISPLAY_A2(value);
      }
    },
    displayB2: {
      get(): number {
        return settingsModule.display.B2;
      },
      set(value: number) {
        settingsModule.SET_DISPLAY_B2(value);
      }
    },
    displaySS: {
      get(): number {
        return settingsModule.display.SS;
      },
      set(value: number) {
        settingsModule.SET_DISPLAY_SS(value);
      }
    },
  },
});
