











































































































































import dayjs from "dayjs";
import { IZone } from "ffxivhuntdata";
import Vue, { PropType } from "vue";

import { worldModule } from "@/store/modules/world"

export default Vue.extend({
  data() {
    return {
      dialogDate: false,
      dialogTime: false,
      hasLastData: false,
      tab: 0,
      date: '',
      time: '',
      mob: 0,
      state: 0,
    }
  },
  props: {
    dialog: Boolean,
    zone: Object as PropType<IZone>,
    instance: Number,
    index: Number
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    startEdit() {
      const now = new Date();
      this.date = dayjs(now).format("YYYY-MM-DD");
      this.time = dayjs(now).format("HH:mm");
      this.mob = 0;
      this.state = 0;

      const zoneId = this.zone.id;
      const instance = this.instance;
      const index = this.index;
      const record = worldModule.getLocationRecord(zoneId, instance, index);

      this.hasLastData = record.myReport != undefined;

    },
    add() {
      const date = dayjs(`${this.date} ${this.time}:00`,
        "YYYY-MM-DD HH:mm:ss").toDate();
      const zoneId = this.zone.id;
      const instance = this.instance;
      const index = this.index;
      const record = {
        date: date.toISOString(),
        mobId: this.mob,
        state: this.state
      }
      worldModule.setRecord(
        { zoneId, instance, index, record }
      )
      this.closeDialog();
    },
    del() {
      const zoneId = this.zone.id;
      const instance = this.instance;
      const index = this.index;
      worldModule.deleteRecord(
        { zoneId, instance, index }
      )
      this.closeDialog();
    },
  },
  computed: {
    mobItems(): { value: number, text: string, color: string, rank: string }[] {
      let items = [
        { value: 0, text: this.$t('doesNotSpecify') as string, color: "", rank: "" }
      ];
      const mobLocation = this.zone.mobLocations[this.index];
      if (mobLocation) {
        items = items.concat(mobLocation.mobIds.map(mobId => {
          const mobColors = this.zone.getMobColors(mobId);
          return {
            value: mobId,
            text: mobColors.mob ? mobColors.mob.name : "",
            rank: mobColors.mob ? mobColors.mob.rank : "",
            color: mobColors.color,
          }
        }))
      }
      return items;
    },
    stateItems(): { value: number, text: string }[] {
      return [
        { value: 0, text: this.$t('found') as string },
        { value: 1, text: this.$t('defeated') as string }
      ]
    },
    mobReports() {
      const lr = worldModule.getLocationRecord(this.zone.id, this.instance, this.index);
      return lr.reports;
    }
  },
  watch: {
    dialog: function (isShow) {
      if (isShow) {
        this.startEdit();
      }
    }
  },
});
