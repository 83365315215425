




























































































































































































































































































































































import { i18n } from "@/i18n";
import { setLang } from "ffxivhuntdata";
import Vue from "vue";
import { IPlayerToken, overlaySettingsModule } from "../store/modules/overlaySettings"
import draggable from 'vuedraggable'
import { getUserByToken, NULL_USER } from "@/libs/serverProxy";
import { IUserState } from "@/store/modules/user";

interface IData {
  mappingAddEditDialog: boolean;
  mappingDeleteDialog: boolean;
  editPlayerTokenMapIndex: number;
  editPlayerTokenMap: IPlayerToken;
  tokenUser: IUserState;
}

export default Vue.extend({
  name: "OverlaySettings",
  components: {
    draggable
  },
  data(): IData {
    return {
      mappingAddEditDialog: false,
      mappingDeleteDialog: false,
      editPlayerTokenMapIndex: -1,
      editPlayerTokenMap: {
        playerName: "",
        token: "",
        enabled: true
      },
      tokenUser: NULL_USER
    };
  },
  created() {
    i18n.locale = 'ja';
    setLang('ja');
  },
  mounted() {
    //
  },
  computed: {
    mapTransparent: {
      get: function (): boolean {
        return overlaySettingsModule.mapTransparent;
      },
      set: function (newValue: boolean) {
        overlaySettingsModule.SET_MAP_TRANSPARENT(newValue);
      }
    },
    displayUpDown: {
      get: function (): boolean {
        return overlaySettingsModule.displayUpDown;
      },
      set: function (newValue: boolean) {
        overlaySettingsModule.SET_DISPLAY_UP_DOWN(newValue);
      }
    },
    tts: {
      get: function (): string[] {
        return overlaySettingsModule.tts;
      },
      set: function (values: string[]) {
        overlaySettingsModule.SET_TTS(values);
      }
    },
    notifyHuntNet: {
      get: function (): string[] {
        return overlaySettingsModule.notifyHuntNet;
      },
      set: function (values: string[]) {
        overlaySettingsModule.SET_NOTIFY_HUNT_NET(values);
      }
    },
    posNotify: {
      get: function (): boolean {
        return overlaySettingsModule.posNotify;
      },
      set: function (newValue: boolean) {
        overlaySettingsModule.SET_POS_NOTIFY(newValue);
      }
    },
    posNotifyPopupSeconds: {
      get: function (): number {
        return overlaySettingsModule.posNotifyPopupSeconds;
      },
      set: function (newValue: number) {
        overlaySettingsModule.SET_POS_NOTIFY_POPUP_SECONDS(newValue);
      }
    },
    posNotifyMarkingSeconds: {
      get: function (): number {
        return overlaySettingsModule.posNotifyMarkingSeconds;
      },
      set: function (newValue: number) {
        overlaySettingsModule.SET_POS_NOTIFY_MARKING_SECONDS(newValue);
      }
    },
    narrowDown: {
      get: function (): boolean {
        return overlaySettingsModule.narrowDown;
      },
      set: function (newValue: boolean) {
        overlaySettingsModule.SET_NARROW_DOWN(newValue);
      }
    },
    displayAbandoned: {
      get: function (): boolean {
        return overlaySettingsModule.displayAbandoned;
      },
      set: function (newValue: boolean) {
        overlaySettingsModule.SET_DISPLAY_ABANDONED(newValue);
      }
    },
    displayAbandonedMinutes: {
      get: function (): number {
        return overlaySettingsModule.displayAbandonedMinutes ?? 120;
      },
      set: function (newValue: number) {
        overlaySettingsModule.SET_DISPLAY_ABANDONED_MINUTES(newValue);
      }
    },
    iconFontScale: {
      get: function (): number {
        return 100 * (overlaySettingsModule.iconFontScale ?? 1.0);
      },
      set: function (newValue: number) {
        overlaySettingsModule.SET_ICON_FONT_SCALE(newValue / 100);
      }
    },
    playerShareMap: {
      get: function (): IPlayerToken[] {
        return overlaySettingsModule.playerShareMap;
      },
      set: function (newValue: IPlayerToken[]) {
        overlaySettingsModule.SET_PLAYER_SHARE_MAP(newValue);
      }
    },
    isMappingValid(): boolean {
      return this.editPlayerTokenMap.playerName !== "" &&
        this.editPlayerTokenMap.token !== "" &&
        this.tokenUser.id !== ""
    },
    tokenInvalidMessage(): string {
      return this.editPlayerTokenMap.token !== "" && this.tokenUser.id === "" ? this.$t("tokenInvalid") as string : "";
    },
    debugMode: {
      get: function (): boolean {
        return overlaySettingsModule.debugMode;
      },
      set: function (newValue: boolean) {
        overlaySettingsModule.SET_DEBUG_MODE(newValue);
      }
    },
  },
  methods: {
    startAddEditMapping(item?: IPlayerToken) {
      if (item) {
        this.editPlayerTokenMapIndex = this.playerShareMap.indexOf(item);
        this.editPlayerTokenMap = item;
      }
      else {
        this.editPlayerTokenMapIndex = -1;
        this.editPlayerTokenMap = {
          playerName: "",
          token: "",
          enabled: true
        }
      }
      this.mappingAddEditDialog = true;
    },
    confirmAddEditMapping() {
      if (this.editPlayerTokenMapIndex >= 0) {
        // edit
        Object.assign(this.playerShareMap[this.editPlayerTokenMapIndex], this.editPlayerTokenMap);
        this.playerShareMap = this.playerShareMap.concat([]);
      } else {
        // add
        const copied = Object.assign({}, this.editPlayerTokenMap)
        this.playerShareMap = this.playerShareMap.concat([copied]);
      }
      this.closeAddEditMapping();
    },
    closeAddEditMapping() {
      this.mappingAddEditDialog = false;
      this.$nextTick(() => {
        this.editPlayerTokenMapIndex = -1;
        this.editPlayerTokenMap = {
          playerName: "",
          token: "",
          enabled: true
        }
        this.tokenUser = NULL_USER;
      })
    },
    startDeleteMapping(item: IPlayerToken) {
      this.editPlayerTokenMapIndex = this.playerShareMap.indexOf(item);
      this.editPlayerTokenMap = item;
      this.mappingDeleteDialog = true;
    },
    confirmDeleteMapping() {
      this.playerShareMap.splice(this.editPlayerTokenMapIndex, 1);
      this.playerShareMap = this.playerShareMap.concat([]);
      this.closeDeleteMapping();
    },
    closeDeleteMapping() {
      this.mappingDeleteDialog = false;
      this.$nextTick(() => {
        this.editPlayerTokenMapIndex = -1;
        this.editPlayerTokenMap = {
          playerName: "",
          token: "",
          enabled: true
        }
        this.tokenUser = NULL_USER;
      })
    },
  },
  watch: {
    'editPlayerTokenMap.token': async function (val) {
      this.tokenUser = val != "" ? await getUserByToken(val) : NULL_USER;
    }
  }
})
