import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Overlay from '../views/Overlay.vue'
import Index from '../views/Index.vue'
import ServerSettings from '../views/ServerSettings.vue'
import Weathers from '../views/Weathers.vue'
import MobLocations from '../views/MobLocations.vue'
import MobMaps from '@/views/MobMaps.vue'
import MobMapZones from '@/views/MobMapZones.vue'
import Map from '@/views/Map.vue'
import STimeTable from "@/views/STimeTable.vue"
import ManageGroups from "@/views/ManageGroups.vue"
import OverlaySettings from "@/views/OverlaySettings.vue"
import Guide from "@/views/Guide.vue"
import ReadMe from "@/views/ReadMe.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '',
        component: STimeTable,
        meta: { title: 'sTimeTable', displayWorldSelector: true }
      },
      {
        path: 'weathers',
        component: Weathers,
        meta: { title: 'weathers' }
      },
      {
        path: 'moblocations',
        component: MobLocations,
        meta: { title: 'spawnLocations' }
      },
      {
        path: 'serversettings',
        component: ServerSettings,
        meta: { title: 'serverSettings' }
      },
      {
        path: 'manageGroups',
        component: ManageGroups,
        meta: { title: 'manageGroups' }
      },
      {
        path: 'guide',
        component: Guide,
        meta: { title: 'guide' }
      },
      {
        path: 'readMe',
        component: ReadMe,
        meta: { title: 'readMe' }
      },
    ]
  },
  {
    path: '/mobmaps',
    component: MobMaps,
    children: [
      {
        path: '',
        component: MobMapZones,
        meta: { title: 'mobMaps' }
      },
      {
        path: ':zoneid/:instance',
        component: Map,
        meta: { title: 'mobMaps', disableWorldSelector: true }
      },
    ]
  },
  {
    path: '/overlay',
    component: Overlay
  },
  {
    path: '/overlaySettings',
    component: OverlaySettings
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
