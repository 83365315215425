





















































































import { getBlackList, setBlackList } from "@/libs/serverProxy";
import Vue from "vue";

interface HuntNetUser {
  id: string;
}

export default Vue.extend({
  name: 'BlackList',
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: this.$t('huntNetUserId') as string, value: 'id' },
        { text: this.$t('actions') as string, value: 'actions', sortable: false },
      ],
      items: [
        { id: "565d5ae8-3b75-..." },
        { id: "title2" },
        { id: "title3" }
      ],
      editedIndex: -1,
      editedUser: ""
    };
  },
  computed: {
    formTitle(): string {
      return this.editedIndex === -1 ?
        this.$t('add') as string :
        this.$t('edit') as string
    },
    isUserValid(): boolean {
      const existing = this.items.filter((item, index) => index != this.editedIndex).map(item => item.id)
      return this.editedUser != "" && !existing.includes(this.editedUser)
    }
  },
  watch: {
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      const data = await getBlackList();
      this.items = data.map(id => { return { id: id } })
    },

    editItem(item: HuntNetUser) {
      console.debug(item)
      this.editedIndex = this.items.indexOf(item)
      this.editedUser = item.id
      this.dialog = true
    },

    deleteItem(item: HuntNetUser) {
      this.editedIndex = this.items.indexOf(item)
      this.editedUser = item.id
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1)
      this.applyOutput()
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedUser = ""
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedUser = ""
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], { id: this.editedUser })
      }
      else {
        this.items.push({ id: this.editedUser })
      }
      this.applyOutput()
      this.close()
    },
    async applyOutput() {
      const requestBody = this.items.map(item => item.id)
      const result = await setBlackList(requestBody);
      console.debug("updated blackList", result);
    }
  }

});
