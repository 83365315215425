












import Vue from "vue";
import IndexHeader from "../components/IndexHeader.vue"
import IndexFooter from "../components/IndexFooter.vue";
import { getUser } from "@/libs/serverProxy";
import { userModule } from "@/store/modules/user";

export default Vue.extend({
  name: "Index",

  components: {
    IndexHeader,
    IndexFooter,
  },
  async mounted() {
    const user = await getUser();
    userModule.loadUser(user);
  },
  computed: {
    title(): string {
      return this.$route.meta != null ? this.$t(this.$route.meta.title) as string : '';
    },
    displayWorldSelector(): boolean {
      return this.$route.meta && this.$route.meta.displayWorldSelector
    }
  }
});
