
















































































import Vue from "vue";
import { messages, getLang, toFixedFloor, zones, regions, DEFAULTZONE, Zone } from 'ffxivhuntdata'
import { settingsModule } from "@/store/modules/settings";
import ZoneSelector from "@/components/ZoneSelector.vue";
import { ILocationClusterPayload } from "../../../src/interfaces/moblocations"
import { getMobLocationsWithQuery } from "@/libs/serverProxy";

interface ReportedLocation {
  x: number;
  y: number;
  zMin: number;
  zMax: number;
  reportCount: number;
  firstReportedAt: Date;
  lastReportedAt: Date;
}

class LocationCluster {
  locations: ReportedLocation[];
  index: number;
  constructor(payload: ILocationClusterPayload, index: number) {
    this.locations = payload.locations.map(loc => {
      return {
        x: loc.x,
        y: loc.y,
        zMin: loc.zMin,
        zMax: loc.zMax,
        reportCount: loc.reportCount,
        firstReportedAt: new Date(loc.firstReportedAt),
        lastReportedAt: new Date(loc.lastReportedAt)
      }
    });
    this.index = index;
  }
  get x(): number {
    let xs: number[] = this.locations.map((location) => location.x);
    return (Math.max(...xs) + Math.min(...xs) + 0.1) / 2.0;
  }
  get y(): number {
    let ys: number[] = this.locations.map((location) => location.y);
    return (Math.max(...ys) + Math.min(...ys) + 0.1) / 2.0;
  }
  get z(): number {
    let zs: number[] = this.locations
      .map((location) => location.zMin)
      .concat(this.locations.map((location) => location.zMax));
    return (Math.max(...zs) + Math.min(...zs)) / 2.0;
  }
  get rangeX(): number {
    let xs: number[] = this.locations.map((location) => location.x);
    return Math.max(...xs) - Math.min(...xs) + 0.1;
  }
  get rangeY(): number {
    let ys: number[] = this.locations.map((location) => location.y);
    return Math.max(...ys) - Math.min(...ys) + 0.1;
  }
  get rangeZ(): number {
    let zs: number[] = this.locations
      .map((location) => location.zMin)
      .concat(this.locations.map((location) => location.zMax));
    return Math.max(...zs) - Math.min(...zs);
  }
  get reportCount(): number {
    return this.locations.reduce((acc, location) => {
      return acc + location.reportCount;
    }, 0);
  }
  get uniqLocationCount(): number {
    return this.locations.length;
  }
  get firstReportedAt(): Date {
    return this.locations
      .map((location) => location.firstReportedAt)
      .reduce((acc, cur) => (acc.getTime() < cur.getTime() ? acc : cur));
  }
  get lastReportedAt(): Date {
    return this.locations
      .map((location) => location.lastReportedAt)
      .reduce((acc, cur) => (acc.getTime() > cur.getTime() ? acc : cur));
  }
  toString(): string {
    return `(${this.x.toFixed(2)}, ${this.y.toFixed(2)}, ${this.z.toFixed(
      2
    )})[${this.locations.length}][${this.reportCount}] (${this.rangeX.toFixed(
      2
    )} ${this.rangeY.toFixed(2)} ${this.rangeZ.toFixed(2)}) ${this.firstReportedAt}) ${this.lastReportedAt}) `;
  }
}

interface MobGroup {
  name: string;
  query: string;
}

interface ITableHeader {
  text: string;
  value: string;
  sortable?: boolean
}

interface Data {
  fullmapCanvas?: HTMLCanvasElement;
  partmapCanvas?: HTMLCanvasElement;
  zoneImage?: HTMLImageElement;
  mapWidth: number;
  tableHeight: number;
  timerId: number;
  mobLocations: LocationCluster[];
  expanded: LocationCluster[];
  selectedQuery: string;
  headers: ITableHeader[];
  subHeaders: ITableHeader[];
  expandIndex: number;
}

export default Vue.extend({
  name: 'MobLocations',
  components: {
    ZoneSelector
  },
  data(): Data {
    return {
      fullmapCanvas: undefined,
      partmapCanvas: undefined,
      zoneImage: undefined,
      mapWidth: 0,
      tableHeight: 0,
      timerId: 0,
      mobLocations: [],
      expanded: [],
      expandIndex: -1,
      selectedQuery: `elite`,
      headers: [{
        text: "X",
        value: "x",
        sortable: true
      }, {
        text: "Y",
        value: "y",
        sortable: true
      }, {
        text: "Z",
        value: "z",
        sortable: true
      }, {
        text: "SubLocations",
        value: "uniqLocationCount",
        sortable: true
      }],
      subHeaders: [{
        text: "X",
        value: "x",
        sortable: true
      }, {
        text: "Y",
        value: "y",
        sortable: true
      }, {
        text: "Count",
        value: "reportCount",
        sortable: true
      }]
    }
  },
  created() {
    this.timerId = window.setInterval(() => {
      this.refresh();
    }, 60 * 1000);
  },
  async mounted() {
    this.fullmapCanvas = this.$el.querySelector("#fullmap") as HTMLCanvasElement;
    this.partmapCanvas = this.$el.querySelector("#partmap") as HTMLCanvasElement;
    this.zoneImage = (await this.loadImage(
      `https://res.cloudinary.com/lanaklein14/image/upload/v1624232409/large/${this.zone.id}.jpg`
    ).catch((e) => {
      console.error("onload error", e);
    })) as HTMLImageElement;
    this.selectedQuery = `elite`;
    await this.refresh();
    this.animation();
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
  computed: {
    zoneId(): number {
      return settingsModule.mobLocationsZoneId;
    },
    zone(): Zone {
      return zones.find(zone => zone.id === this.zoneId) ?? DEFAULTZONE;
    },
    mobGroups(): MobGroup[] {
      const groups: MobGroup[] = [];
      if (this.zone.mobs.some(mob => mob.type === 'elite')) {
        groups.push({
          name: this.$t('elite') as string,
          query: `elite`
        });
        this.zone.mobs.filter(mob => mob.type === 'elite').forEach(mob => {
          groups.push({
            name: ' ' + mob.name,
            query: `elite/${mob.id}`
          });
        });
      }
      if (this.zone.mobs.some(mob => mob.type === 'ss')) {
        groups.push({
          name: this.$t('ss') as string,
          query: `ss`
        });
        this.zone.mobs.filter(mob => mob.type === 'ss').forEach(mob => {
          groups.push({
            name: ' ' + mob.name,
            query: `ss/${mob.id}`
          });
        });
      }
      if (this.zone.mobs.some(mob => mob.type === 'fate')) {
        groups.push({
          name: this.$t('fate') as string,
          query: `fate`
        });
        this.zone.mobs.filter(mob => mob.type === 'fate').forEach(mob => {
          groups.push({
            name: ' ' + mob.name,
            query: `fate/${mob.id}`
          });
        });
      }
      return groups;
    },
    zoneName(): string {
      return messages[getLang()].zone[this.zoneId];
    },
    regionClass(): string {
      const region = regions.find(region => region.zoneIds.includes(this.zoneId));
      return region != null ? region.class : "";
    },
    threshold(): number {
      const region = regions.find(region => region.zoneIds.includes(this.zoneId));
      return region != null && ['LaNoscea', 'Gridania', 'Thanalan', 'Frontier'].includes(region.key) ? 0.15 : 0.9;
    },
    selectedLocation(): LocationCluster | undefined {
      return this.expanded.length > 0 ? this.expanded[0] : undefined;
    },
    subLocations(): ReportedLocation[] {
      return this.selectedLocation ? this.selectedLocation.locations : [];
    }

  },
  watch: {
    zoneId: async function () {
      this.zoneImage = (await this.loadImage(
        `https://res.cloudinary.com/lanaklein14/image/upload/v1624232409/large/${this.zone.id}.jpg`
      ).catch((e) => {
        console.error("onload error", e);
      })) as HTMLImageElement;
      this.expanded = [];
      if (this.selectedQuery === `elite`) {
        this.refresh();
      }
      else {
        this.selectedQuery = `elite`;
      }
    },
    selectedQuery: async function () {
      this.refresh();
    }
  },
  methods: {
    async refresh() {
      this.expandIndex = this.expanded.length > 0 ? this.mobLocations.findIndex(i => i === this.expanded[0]) : -1;
      try {
        const payloads = await getMobLocationsWithQuery(this.zoneId, this.selectedQuery);
        this.mobLocations = payloads.map((payload, index) => new LocationCluster(payload, index));
      } catch (err) {
        console.error("err", err);
      }
      this.$nextTick(() => {
        const index = Math.min(this.expandIndex, this.mobLocations.length - 1);
        if (index >= 0) {
          this.expanded = [this.mobLocations[index]];
        }
        else {
          this.expanded = [];
        }
      });
    },
    onResize() {
      const clientHeight = document.documentElement.clientHeight;
      const headerElement = document.querySelector("header") as HTMLElement;
      const footerElement = document.querySelector("footer") as HTMLElement;
      const otherRows = document.querySelectorAll<HTMLElement>(".nonetable, .v-data-footer");
      this.tableHeight = clientHeight - headerElement.offsetHeight - footerElement.offsetHeight;
      for (let i = 0; i < otherRows.length; i++) {
        this.tableHeight -= otherRows[i].offsetHeight;
      }
      const mapElement = document.querySelector(".left") as HTMLElement;
      this.mapWidth = mapElement.offsetWidth;
    },
    async loadImage(src: string): Promise<HTMLImageElement> {
      return await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    },
    toFixedFloor(value: number, decimals = 1): string {
      return toFixedFloor(value, decimals);
    },
    animation() {
      if (this.zoneImage) {
        if (this.fullmapCanvas) {
          const cvs = this.fullmapCanvas;
          cvs.width = cvs.height = this.mapWidth;
          const ctx = cvs.getContext("2d");
          if (ctx) {
            ctx.clearRect(0, 0, cvs.width, cvs.height);
            const scale = this.zone.scale;
            const image = this.zoneImage;
            ctx.save();
            const scalec2a = cvs.width / scale.xRange;
            ctx.scale(scalec2a, scalec2a);
            ctx.translate(-scale.xMin, -scale.yMin);
            ctx.drawImage(image, image.x, image.y, image.width, image.height
              , scale.xMin, scale.yMin, scale.xRange, scale.yRange);
            this.mobLocations.forEach(loc => {
              ctx.fillStyle = "rgba(0, 0, 200, 1.0)";
              ctx.beginPath();
              ctx.arc(loc.x, loc.y, 0.3, 0, 2 * Math.PI, false);
              ctx.fill();
            })
            if (this.selectedLocation) {
              ctx.strokeStyle = "rgba(0, 0, 200, 1.0)";
              ctx.lineWidth = 0.07;
              ctx.beginPath();
              ctx.rect(this.selectedLocation.x - 1,
                this.selectedLocation.y - 1,
                2, 2);
              ctx.stroke();
            }
            ctx.restore();
          }
        }
        if (this.partmapCanvas) {
          const cvs = this.partmapCanvas;
          cvs.width = cvs.height = this.mapWidth;
          const ctx = cvs.getContext("2d");
          if (ctx) {
            ctx.fillStyle = "rgba(0, 255, 0, 1.0)";
            ctx.clearRect(0, 0, cvs.width, cvs.height);
            if (this.selectedLocation) {
              const loc = this.selectedLocation;
              const scale = this.zone.scale;
              const image = this.zoneImage;
              ctx.save();
              const scalec2a = cvs.width / scale.xRange;
              ctx.scale(scale.xRange / 2, scale.xRange / 2);
              ctx.scale(scalec2a, scalec2a);
              ctx.translate(-loc.x, -loc.y);
              ctx.translate(1.0, 1.0);
              //              ctx.translate(-scale.xMin, -scale.yMin);
              //              ctx.translate(-loc.x+scale.xMin, -loc.y+scale.yMin);
              //ctx.scale(2, 2);
              /*
                            ctx.translate(-this.selectedLocation.x, -this.selectedLocation.y);
                            const scalea2z = scale.xRange / 1.0;
                            ctx.scale(scalea2z, scalea2z);
              */
              ctx.drawImage(image, image.x, image.y, image.width, image.height
                , scale.xMin, scale.yMin, scale.xRange, scale.yRange);
              ctx.fillStyle = "rgba(0, 0, 200, 1.0)";
              ctx.strokeStyle = "rgba(0, 0, 200, 1.0)";
              ctx.lineWidth = 0.01;
              ctx.beginPath();
              ctx.arc(loc.x, loc.y, 0.3, 0, 2 * Math.PI, false);
              ctx.stroke();
              loc.locations.forEach(l => {
                const count = Math.min(l.reportCount, 10);
                ctx.globalAlpha = count * 0.7 / 10 + 0.3;
                ctx.beginPath();
                ctx.rect(l.x,
                  l.y,
                  0.1, 0.1);
                ctx.fill();
              });
              ctx.globalAlpha = 1.0;
              ctx.beginPath();
              ctx.rect(loc.x - 1,
                loc.y - 1,
                2, 2);
              ctx.stroke();

              ctx.restore();
            }
            //            ctx.fillRect(0, 0, cvs.width, cvs.height);
          }
        }
      }
      window.requestAnimationFrame(() => this.animation());
    },
    clickRow(item: LocationCluster, event: { isExpanded: boolean; }) {
      if (event.isExpanded) {
        const index = this.expanded.findIndex(i => i === item);
        this.expanded.splice(index, 1);
      } else {
        this.expanded = [item];
      }
    }

  },
});
