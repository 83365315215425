













































import Vue from "vue";
import { regions } from "ffxivhuntdata";

export default Vue.extend({
  name: "MobMapZones",
  props: {
    zoneInstances: Array,
    value: Boolean
  },
  data() {
    return {
      showAll: this.value,
    };
  },
  computed: {
    regions() {
      return regions.filter(r => r.key != "Eureka")
    }
  },
  watch: {
    value: function () {
      this.showAll = this.value;
    },
    showAll: function () {
      console.debug(this.showAll)
      this.$emit('input', this.showAll);
    }
  }
});
