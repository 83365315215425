var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fluid":""}},[_c('v-row',[_c('v-toolbar',{staticClass:"nonetable",class:_vm.regionClass,attrs:{"dark":"","dense":""}},[_c('zone-selector'),_c('v-spacer'),_c('v-select',{staticClass:"fit",attrs:{"items":_vm.mobGroups,"dense":"","dark":"","hide-details":"","item-value":"query","item-text":"name","solo":""},model:{value:(_vm.selectedQuery),callback:function ($$v) {_vm.selectedQuery=$$v},expression:"selectedQuery"}})],1)],1),_c('v-row',[_c('div',{staticClass:"left"},[_c('canvas',{attrs:{"id":"fullmap"}}),_c('canvas',{attrs:{"id":"partmap"}})]),_c('div',{staticClass:"right"},[_c('v-data-table',{attrs:{"height":_vm.tableHeight,"dense":"","headers":_vm.headers,"items":_vm.mobLocations,"single-expand":"","expanded":_vm.expanded,"hide-default-footer":"","disable-pagination":"","mobile-breakpoint":"0","item-key":"index"},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.x",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toFixedFloor(item.x, 2))+" ")]}},{key:"item.y",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toFixedFloor(item.y, 2))+" ")]}},{key:"item.z",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toFixedFloor(item.z, 2))+" ")]}},{key:"item.uniqLocationCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.uniqLocationCount)+"("+_vm._s(item.reportCount)+") ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('span',[_vm._v("X Range: ")]),_vm._v(_vm._s(item.rangeX.toFixed(1))+", "),_c('span',[_vm._v("Y Range: ")]),_vm._v(_vm._s(item.rangeY.toFixed(1))+" ")]),_c('h4',[_vm._v("Sub Locations")]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.subHeaders,"items":_vm.subLocations,"hide-default-footer":"","disable-pagination":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.x",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.x.toFixed(1))+" ")]}},{key:"item.y",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.y.toFixed(1))+" ")]}}],null,true)})],1)]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }