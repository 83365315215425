var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"name","dense":"","hide-default-footer":"","disable-pagination":"","mobile-breakpoint":"0","single-expand":"","expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.loadDetails},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("groupList")))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("add"))+" ")])]}}]),model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('groupName'),"hide-details":""},model:{value:(_vm.newGroupName),callback:function ($$v) {_vm.newGroupName=$$v},expression:"newGroupName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.guildItems,"item-text":"name","item-value":"id","dense":"","label":_vm.$t('guildName'),"hide-details":""},model:{value:(_vm.newGuildId),callback:function ($$v) {_vm.newGuildId=$$v},expression:"newGuildId"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeAddDialog}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.isGroupValid,"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.addGroup()}}},[_vm._v(_vm._s(_vm.$t("ok")))])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("confirmationDeleteGroup")))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t("ok")))]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('edit-group',{ref:"editGroup",attrs:{"group":item},on:{"updateTable":_vm.updateTable}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var item = ref.item;
return [(_vm.isOwner(item))?_c('v-icon',{on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.isOwner(item))?_c('v-icon',{on:{"click":function($event){return _vm.deleteGroup(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("thereIsNoBlackList"))+" ")]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }