import { Mutation, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import { store, canPreserveDefault } from "@/store";
import Vue from "vue";
import { zones } from "ffxivhuntdata";

// state's interface
export interface RecordState {
    date: string;
    mobId: number;
    state: number;
}

export interface IIndexRecordMap {
    [index: number]: RecordState;
}

export interface IZoneInstanceIndexMap {
    [zoneInstance: string]: IIndexRecordMap;
}

export interface ILocationRecordState {
    vrs: string;
    [worldId: number]: IZoneInstanceIndexMap;
}

const name = 'locationrecords';
const vrs = '0.1';

/*
try {
    store.unregisterModule(name)
} catch (error) {
    console.warn(`ignore ${error}`)
}
*/

@Module({ dynamic: true, store, name, namespaced: true, preserveState: canPreserveDefault(name, vrs, "locations") })
class LocationRecordState extends VuexModule implements ILocationRecordState {
    // state
    vrs = vrs;
    [worldId: number]: IZoneInstanceIndexMap;

    // getter with params
    public get getRecord() {
        return (worldId: number, zoneId: number, instance: number, index: number): RecordState | undefined => {
            const zoneInstanceIndexMap = this[worldId];
            if (zoneInstanceIndexMap) {
                const indexRecordMap = zoneInstanceIndexMap[`${zoneId}_${instance}`];
                if (indexRecordMap) {
                    return indexRecordMap[index];
                }
            }
            return undefined;
        }
    }

    public get getZoneInstanceIndexMap() {
        return (worldId: number): IZoneInstanceIndexMap => {
            return this[worldId] ?? {};
        }
    }

    public get getIndexRecordMap() {
        return (worldId: number, zoneId: number, instance: number): IIndexRecordMap => {
            const zoneInstanceIndexMap = this[worldId];
            if (zoneInstanceIndexMap) {
                return zoneInstanceIndexMap[`${zoneId}_${instance}`] ?? {}
            }
            return {};
        }
    }

    public get getLatestDates() {
        return (worldId: number, zoneId: number, instance: number): Date[] => {
            const indexRecordMap = this.getIndexRecordMap(worldId, zoneId, instance);
            const zone = zones.find(zone=>zone.id===zoneId);
            const validIndices: number[] = [];
            if (zone) {
                const smob = zone.mobs.find(mob=>mob.type==='elite' && mob.rank==='S');
                if (smob) {
                    const smobId = smob.id;
                    zone.mobLocations.forEach((location, index) => {
                        if (location.mobIds.includes(smobId)) {
                            validIndices.push(index);
                        }
                    });
                }
            }
            return validIndices.filter(index => indexRecordMap[index]).map(index => new Date(indexRecordMap[index].date));
        }
    }
/*
    public get getValidLocationRecordsCount() {
        return (worldId: number, zoneId: number, instance: number, thresholdTime: number): number => {
            const indexRecordMap = this.getIndexRecordMap(worldId, zoneId, instance);
            const checkedEliteLocationsCount = Object.values(indexRecordMap).filter((record: RecordState) => {
                return new Date(record.date).getTime() > thresholdTime
            }).length;
            return checkedEliteLocationsCount
        }
    }
*/
    // mutation
    @Mutation
    public SET_RECORD(payload: { worldId: number, zoneId: number, instance: number, index: number, record: RecordState }) {
        if (!this[payload.worldId]) {
            Vue.set(this, payload.worldId, {})
        }
        const zoneInstance = `${payload.zoneId}_${payload.instance}`;
        if (!this[payload.worldId][zoneInstance]) {
            Vue.set(this[payload.worldId], zoneInstance, {})
        }
        Vue.set(this[payload.worldId][zoneInstance], payload.index, payload.record);
    }

    @Mutation
    public SET_RECORDS_OF_WORLD(payload: { worldId: number, records: IZoneInstanceIndexMap }) {
        Vue.set(this, payload.worldId, payload.records)
        if (Object.keys(this[payload.worldId]).length == 0) {
            Vue.delete(this, payload.worldId);
        }
    }

    @Mutation
    public SET_RECORDS_OF_ZONE_INSTANCE(payload: { worldId: number, zoneId: number, instance: number, records: IIndexRecordMap }) {
        if (!this[payload.worldId]) {
            Vue.set(this, payload.worldId, {})
        }
        const zoneInstance = `${payload.zoneId}_${payload.instance}`;
        Vue.set(this[payload.worldId], zoneInstance, payload.records)
        if (Object.keys(this[payload.worldId][zoneInstance]).length == 0) {
            Vue.delete(this[payload.worldId], zoneInstance);
            if (Object.keys(this[payload.worldId]).length == 0) {
                Vue.delete(this, payload.worldId);
            }
        }
    }

    @Mutation
    public DELETE_RECORD(payload: { worldId: number, zoneId: number, instance: number, index: number }) {
        const zoneInstance = `${payload.zoneId}_${payload.instance}`;
        if (this[payload.worldId] && this[payload.worldId][zoneInstance]) {
            Vue.delete(this[payload.worldId][zoneInstance], payload.index);
            if (Object.keys(this[payload.worldId][zoneInstance]).length == 0) {
                Vue.delete(this[payload.worldId], zoneInstance);
                if (Object.keys(this[payload.worldId]).length == 0) {
                    Vue.delete(this, payload.worldId);
                }
            }
        }
    }

    @Mutation
    public DELETE_ALL() {
        Object.keys(this).filter(key => key != 'vrs').map(Number).forEach(key => {
            Vue.delete(this, key)
        })
    }

    // actions
    @Action({})
    public async deleteAll() {
        // TBD
    }

}

export const locationrecordsModule = getModule(LocationRecordState);