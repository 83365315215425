



















































import Vue from "vue";

export default Vue.extend({
  name: "SMobFilter",
  props: {
    groups: Array
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
  },
  mounted() {
    //    this.worldId = 47;
  }
});
