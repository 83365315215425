import { settingsModule } from '@/store/modules/settings';
import { IndexMap, LatestDatesMap, MobReport, ReportMap, sharelocationrecordsModule, SocketCache, UserCache } from '@/store/modules/sharelocationrecords';
import { userModule } from '@/store/modules/user';
import { worldModule } from '@/store/modules/world';
import { io, Socket } from 'socket.io-client'

export class SocketClient {
    socket?: Socket;
    id?: string;
    group?: string;
    worldId?: number;
    zoneInstances: string[];

    constructor() {
        this.zoneInstances = [];
    }

    connect(): void {
        this.disconnect(); // disconnect existing connection first
        if (userModule.group != "") {
            this.id = userModule.id;
            this.group = userModule.group;
            this.worldId = worldModule.id;
            this.zoneInstances = worldModule.displayZoneInstaces;
            this.socket = io({
                path: `/socket`,
                query: {
                    token: JSON.stringify({
                        id: this.id,
                        group: this.group,
                        worldId: this.worldId,
                        zoneInstances: this.zoneInstances
                    })
                }
            });
            this.socket.on('connect', () => {
                if (this.socket) {
                    userModule.SET_SOCKET(this.socket.id);
                }
            });
            this.socket.on('disconnect', () => {
                userModule.SET_SOCKET("");
                sharelocationrecordsModule.CLEAR_SOCKETS();
            });
            this.socket.on('group_members_list', (members: SocketCache[]) => {
                sharelocationrecordsModule.CLEAR_SOCKETS()
                members.forEach(socket=>sharelocationrecordsModule.SET_SOCKET(socket));
            });
            this.socket.on('group_member_add', (member: SocketCache) => {
                sharelocationrecordsModule.SET_SOCKET(member)
            });
            this.socket.on('group_member_status', (member: SocketCache) => {
                sharelocationrecordsModule.SET_SOCKET(member)
            });
            this.socket.on('group_member_delete', (memberId: string) => {
                sharelocationrecordsModule.DELETE_SOCKET(memberId)
            });
            this.socket.on('users', (users: UserCache[]) => {
                users.forEach(user=>sharelocationrecordsModule.SET_USER(user));
            });
            this.socket.on('world_data', (worldId: number, latestDatesMap: LatestDatesMap, zoneInstacesMap: IndexMap) => {
                sharelocationrecordsModule.SET_LATEST_DATES(latestDatesMap);
                sharelocationrecordsModule.SET_ZONEINSTANCES(zoneInstacesMap);
            });
            this.socket.on('world_zone_instance_data', (worldId: number, zoneInstance: string, dates: string[]) => {
                sharelocationrecordsModule.SET_LATEST_DATES_OF_ZONEINSTANCE({zoneInstance, dates});
            });

            this.socket.on('zone_instance_data', (worldId: number, zoneInstance: string, data: ReportMap) => {
                sharelocationrecordsModule.SET_ZONEINSTANCE({zoneInstance, data});
            });

            this.socket.on('zone_instance_index_data', (worldId:number, zoneInstance: string, index:number, reports:MobReport[]) => {
                sharelocationrecordsModule.SET_RECORDS({zoneInstance, index, reports});
            });

        }
    }

    disconnect(): void {
        if (this.socket && this.socket.connected) {
            this.socket.disconnect();
            this.socket = undefined;
            this.id = undefined;
            this.group = undefined;
            this.worldId = undefined;
            this.zoneInstances = [];
        }
    }

    switchWorld(worldId: number, zoneInstances: string[]): void {
        this.worldId = worldId;
        this.zoneInstances = zoneInstances;
        if (this.socket && this.group) {
            this.socket.emit('switch_world', worldId, zoneInstances)
        }
    }

    joinZoneInstance(zoneInstance: string): void {
        console.debug('joinZoneInstance')
        this.zoneInstances.push(zoneInstance);
        this.zoneInstances = [...new Set(this.zoneInstances)]; // unique
        if (this.socket && this.group) {
            this.socket.emit('join_zone_instance', zoneInstance)
        }
    }

    leaveZoneInstance(zoneInstance: string): void {
        console.debug('leaveZoneInstance')
        this.zoneInstances = this.zoneInstances.filter(zi => !(zi == zoneInstance))
        if (this.socket && this.group) {
            this.socket.emit('leave_zone_instance', zoneInstance)
        }
    }

    updateZoneInstances(): void {
        this.zoneInstances = settingsModule.displayMobMap[worldModule.id] ?? [];
        if (this.socket && this.group) {
            this.socket.emit('update_zone_instances', this.zoneInstances)
        }
    }

    public setRecord(zoneId: number, instance: number, index: number, reportedAt: string, mobId: number, state: number): void {
        if (this.socket && this.group) {
            this.socket.emit('set_record', zoneId, instance, index, reportedAt, mobId, state)
        }
    }

    public deleteRecord(zoneId: number, instance: number, index: number): void {
        if (this.socket && this.group) {
            this.socket.emit('delete_record', zoneId, instance, index)
        }
    }

    public setAbsence(zoneId: number, instance: number, index: number, absenceAt: Date): void {
        if (this.socket && this.group) {
            this.socket.emit('set_absence', zoneId, instance, index, absenceAt.toISOString())
        }
    }

    public deleteAbsence(zoneId: number, instance: number, index: number): void {
        if (this.socket && this.group) {
            this.socket.emit('delete_absence', zoneId, instance, index)
        }
    }

    public getUsers(userIds: string[]): void {
        if (this.socket && this.group) {
            this.socket.emit('get_users', userIds);
        }
    }


}

export const client = new SocketClient();


