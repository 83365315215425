import { fieldinstancesModule } from "@/store/modules/fieldinstances";
import { overlayModule } from "@/store/modules/overlay";
import { overlaySettingsModule } from "@/store/modules/overlaySettings";
import axios from "axios";
import OverlayAPI from "ffxiv-overlay-api";
import { CombatDataEvent, ICenturionEvent, ICombatDataEvent, ILocationNotifiedEvent, IMobFAEvent, IMobLocationEvent, IMobStateChangedEvent, IMobTriggerEvent, IPlayerLoggedInEvent, IZoneChangedEvent, IZoneInstanceChangedEvent, LocationNotifiedEvent, MobFAEvent, MobLocationEvent, MobStateChangedEvent, MobTriggerEvent, PlayerLoggedInEvent, PlayerLoggedOutEvent, ZoneChangedEvent, ZoneInstanceChangedEvent } from "./centurionEvents"

const client = axios.create({
  baseURL: "/api/",
  headers: {
    "Content-Type": "application/json"
  }
})

export class OverlayController {
  public overlay: OverlayAPI = new OverlayAPI({
    silentMode: true
  });
  public errorCode = 0;
  public count = 0;

  init(): void {
    this.overlay.removeAllListener("CenturionPlayerLoggedInEvent");
    this.overlay.removeAllListener("CenturionPlayerLoggedOutEvent");
    this.overlay.removeAllListener("CenturionZoneChangedEvent");
    this.overlay.removeAllListener("CenturionZoneInstanceChangedEvent");
    this.overlay.removeAllListener("CenturionLocationNotifiedEvent");
    this.overlay.removeAllListener("CenturionMobFAEvent");
    this.overlay.removeAllListener("CenturionMobTriggerEvent");
    this.overlay.removeAllListener("CenturionMobLocationEvent");
    this.overlay.removeAllListener("CenturionMobStateChangedEvent");
    this.overlay.removeAllListener("CenturionCombatDataEvent");

    this.overlay.addListener(
      "CenturionPlayerLoggedInEvent",
      (e: IPlayerLoggedInEvent) => {
        const ev = new PlayerLoggedInEvent(e);
        console.log(ev.toString(), ev);
        overlayModule.login(ev.playerName);
      });
    this.overlay.addListener(
      "CenturionPlayerLoggedOutEvent",
      (e: ICenturionEvent) => {
        const ev = new PlayerLoggedOutEvent(e);
        console.log(ev.toString(), ev);
        // logout from Centurion
        overlayModule.logout();
        //this.party = [];
        //this.targets = [];
      });
    this.overlay.addListener(
      "CenturionZoneChangedEvent",
      (e: IZoneChangedEvent) => {
        const ev = new ZoneChangedEvent(e);
        console.log(ev.toString(), ev);
        // setCurrentWorld
        fieldinstancesModule.fetch();
        overlayModule.setZone({ worldId: ev.worldId, zoneId: ev.zoneId })
      });
    this.overlay.addListener(
      "CenturionZoneInstanceChangedEvent",
      (e: IZoneInstanceChangedEvent) => {
        const ev = new ZoneInstanceChangedEvent(e);
        console.log(ev.toString(), ev);
        // setCurrentWorld
        overlayModule.setZoneInstance(ev.instance)
      });
    this.overlay.addListener(
      "CenturionLocationNotifiedEvent",
      (e: ILocationNotifiedEvent) => {
        const ev = new LocationNotifiedEvent(e);
        console.log(ev.toString(), ev);
        overlayModule.notifyLocation(e);
      });
    this.overlay.addListener(
      "CenturionMobFAEvent",
      (e: IMobFAEvent) => {
        const ev = new MobFAEvent(e);
        console.log(ev.toString(), ev);
      });
    this.overlay.addListener(
      "CenturionMobTriggerEvent",
      (e: IMobTriggerEvent) => {
        const ev = new MobTriggerEvent(e);
        console.log(ev.toString(), ev);
        if (overlaySettingsModule.tts.includes("SBpop")) {
          if (ev.triggerType === "SSChallengeStarted") {
            this.overlay.callHandler({
              call: "CenturionSay",
              text: "配下が湧きました",
            });
          }
          else if (ev.triggerType === "SSChallengeFailed") {
            this.overlay.callHandler({
              call: "CenturionSay",
              text: "配下が帰還しました",
            });
          }
        }
      });
    this.overlay.addListener(
      "CenturionMobLocationEvent",
      async (e: IMobLocationEvent) => {
        const ev = new MobLocationEvent(e);
        console.log(ev.toString(), ev);
        if (ev.zone) {
          const mob = ev.zone.mobs.find(m => m.id === ev.mobId);
          const pos = ev.zone.toLocalPosition(ev);

          const index = ev.zone.getEliteLocationIndex(pos);
          if (index >= 0) {
            overlayModule.updateEliteLocationIndex({ id: ev.id, zoneId: ev.zoneId, mobId: ev.mobId, locationIndex: index });
          }
          if (mob && overlaySettingsModule.notifyHuntNet.includes("MobLocation")) {
            await client.post(`zones/${e.zoneId}/moblocations`,
              {
                type: mob.type,
                mobId: e.mobId,
                x: pos.x,
                y: pos.y,
                z: pos.z
              })
          }
        }
      });
    this.overlay.addListener(
      "CenturionMobStateChangedEvent",
      (e: IMobStateChangedEvent) => {
        const ev = new MobStateChangedEvent(e);
        console.log(ev.toString(), ev);
        const mob = ev.mob;
        if (mob) {
          // TTS on found and repop
          if (ev.state === "Found" && overlaySettingsModule.tts.includes(mob.rank)) {
            this.overlay.callHandler({
              call: "CenturionSay",
              text: mob.tts,
            });
          }
          else if (ev.state === "Killed" && mob.rank === "B" && overlaySettingsModule.tts.includes("Brepop")) {
            setTimeout(() => {
              this.overlay.callHandler({
                call: "CenturionSay",
                text: "Bモブリポップ",
              });
            }, 13000);
          }
          else if (ev.state === "StartCombat" && ["S", "SS"].includes(mob.rank) && overlaySettingsModule.tts.includes("Sstart")) {
            this.overlay.callHandler({
              call: "CenturionSay",
              text: "戦闘開始",
            });
          }
          if (mob.type === 'elite') {
            overlayModule.processEliteState(ev);
          }
        }
      });
    this.overlay.addListener(
      "CenturionCombatDataEvent",
      (e: ICombatDataEvent) => {
        const ev = new CombatDataEvent(e);
        overlayModule.SET_PLAYER(ev.playerChar);
        overlayModule.SET_PARTY(ev.partyChars);
        overlayModule.SET_TARGETS(ev.targetChars);
        /*
        this.transports = e.type3.filter(
          e =>
            e.name.startsWith("<specify name here>") ||
            e.name.startsWith("船頭") ||
            e.name.startsWith("乗船")
        );
        if (this.count % 150 == 0) {
          const a = {
            aethrytes: this.aetherytes.map(item => {
              return {
                id: item.bNpcId,
                name: item.name,
                x: item.x,
                y: item.y,
                z: item.z
              }
            }),
            transports: this.transports.map(item => {
              return {
                id: item.bNpcId,
                name: item.name,
                x: item.x,
                y: item.y,
                z: item.z
              }
            })
          }
          //console.log(JSON.stringify(a))
        }
        this.count++;
        */
        overlayModule.refreshAbsence();
        overlayModule.updateNotifiedLocations({ zoneId: ev.zoneId, targets: ev.targetChars });
      });

    this.overlay.startEvent();
    this.overlay.callHandler({
      call: "CenturionSetZoneMobs",
      data: { "10": [2, 4, 5, 6], "20": [1, 2, 3] },
    });
    this.overlay.callHandler({
      call: "CenturionGetVersion",
    }).then(value => {
      if (value == null) {
        console.error("CenturionGetVersion returned null");
        this.errorCode = 1;
      }
      else if (value.version != "1.0.2") {
        console.error(`CenturionGetVersion expected 1.0.2, actual ${value.version}`);
        this.errorCode = 2;
      }
    });
    this.overlay.callHandler({
      call: "CenturionInitClient",
    });
  }

  shutdown(): void {
    this.overlay.removeAllListener("CenturionPlayerLoggedInEvent");
    this.overlay.removeAllListener("CenturionPlayerLoggedOutEvent");
    this.overlay.removeAllListener("CenturionZoneChangedEvent");
    this.overlay.removeAllListener("CenturionZoneInstanceChangedEvent");
    this.overlay.removeAllListener("CenturionLocationNotifiedEvent");
    this.overlay.removeAllListener("CenturionMobFAEvent");
    this.overlay.removeAllListener("CenturionMobTriggerEvent");
    this.overlay.removeAllListener("CenturionMobLocationEvent");
    this.overlay.removeAllListener("CenturionMobStateChangedEvent");
    this.overlay.removeAllListener("CenturionCombatDataEvent");
    this.overlay.endEncounter();
  }
}

export default new OverlayController();
