import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export function canPreserveDefault(key: string, vrs: string, store = "store"): boolean {
  let result = false;
  try {
    const value = localStorage.getItem(store) as string;
    const obj = JSON.parse(value);
    if (obj[key] && obj[key].vrs == vrs) {
      result = true;
    }
    else {
      throw `key: ${key} does not exist`
    }
  }
  catch (error) {
    console.warn(`Preserve state failed: ${error}`);
  }
  return result;
}

const options: StoreOptions<RootState> = {
  state: {
    helloMessage: 'hello ffxiv',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    key: 'store',
    paths: [
      'world',
      'fieldinstances',
      'settings',
      'sTimeTableView',
    ],
    storage: localStorage
  }), createPersistedState({
    key: 'locations',
    paths: [
      'locationrecords',
      'locationabsence'
    ],
    storage: localStorage
  }), createPersistedState({
    key: 'srecords',
    paths: [
      'smobrecords'
    ],
    storage: localStorage
  }), createPersistedState({
    key: 'temp',
    paths: [
      'user',
      'sharelocationrecords',
      'overlay',
    ],
    storage: localStorage
  }), createPersistedState({
    key: 'overlay',
    paths: [
      'overlaySettings'
    ],
    storage: localStorage
  }),]
}

export const store = new Vuex.Store<RootState>(options);
