import { DEFAULTZONE, IZone } from "ffxivhuntdata";
import { IPoint } from "ffxivhuntdata/dist/data/zones";
import urlCursor from "@/assets/images/060443.png";
import urlParty from "@/assets/images/060421.png";
import urlEnemy from "@/assets/images/060004.png";
import urlEnemyMini from "@/assets/images/060422.png";
import urlAetheryte from "@/assets/images/060453.png";
import urlShip from "@/assets/images/060456.png";
import urlGate from "@/assets/images/060467.png";
import urlReport from "@/assets/images/060561.png";
import urlUp from "@/assets/images/060954.png";
import urlDown from "@/assets/images/060955.png";
import urlSS from "@/assets/images/ss.png";
import urlSB from "@/assets/images/sb.png";
import url00001 from "@/assets/images/00001.png";
import url00010 from "@/assets/images/00010.png";
import url00011 from "@/assets/images/00011.png";
import url00100 from "@/assets/images/00100.png";
import url00101 from "@/assets/images/00101.png";
import url00110 from "@/assets/images/00110.png";
import url00111 from "@/assets/images/00111.png";
import url01000 from "@/assets/images/01000.png";
import url01001 from "@/assets/images/01001.png";
import url01010 from "@/assets/images/01010.png";
import url01011 from "@/assets/images/01011.png";
import url01100 from "@/assets/images/01100.png";
import url01101 from "@/assets/images/01101.png";
import url01110 from "@/assets/images/01110.png";
import url01111 from "@/assets/images/01111.png";
import url10000 from "@/assets/images/10000.png";
import url10001 from "@/assets/images/10001.png";
import url10010 from "@/assets/images/10010.png";
import url10011 from "@/assets/images/10011.png";
import url10100 from "@/assets/images/10100.png";
import url10101 from "@/assets/images/10101.png";
import url10110 from "@/assets/images/10110.png";
import url10111 from "@/assets/images/10111.png";
import url11000 from "@/assets/images/11000.png";
import url11001 from "@/assets/images/11001.png";
import url11010 from "@/assets/images/11010.png";
import url11011 from "@/assets/images/11011.png";
import url11100 from "@/assets/images/11100.png";
import url11101 from "@/assets/images/11101.png";
import url11110 from "@/assets/images/11110.png";
import url11111 from "@/assets/images/11111.png";
import urlcheck1 from "@/assets/images/check1.png";
import urlcheck2 from "@/assets/images/check2.png";
import urlcheck3 from "@/assets/images/check3.png";

import { IZoneInstanceSMobInfo, LocationRecord, worldModule } from "@/store/modules/world"
import { settingsModule } from "@/store/modules/settings";
import dayjs from "dayjs";

interface Position {
    x: number;
    y: number;
}

export interface IZoneInstance {
    zone: IZone;
    instance: number;
    key: string;
    link: string;
    name: string;
    narrowDown: string;
    showNarrowDown: boolean;
    image: HTMLImageElement;
    scale: number
    offset: Position;
    readyToDraw: boolean;
    smobInfo: IZoneInstanceSMobInfo | undefined;
}

export class ZoneInstance implements IZoneInstance {
    constructor(zone: IZone, instance: number, multiInstance: boolean) {
        this.zone = zone;
        this.instance = instance;
        this.multiInstance = multiInstance;
        this.image = new Image()
        this.scale = 1.0;
        this.offset = { x: 0, y: 0 }
        this.readyToDraw = false;
        this.image.onload = () => {
            console.debug("activating image", this.image)
            this.readyToDraw = true;
        }
    }
    zone: IZone;
    instance: number;
    multiInstance: boolean;
    image: HTMLImageElement;
    scale: number
    offset: Position;
    readyToDraw: boolean;

    get link(): string {
        return `/mobmaps/${this.zone.id}/${this.instance}`
    }
    get key(): string {
        return `${this.zone.id}_${this.instance}`
    }
    get name(): string {
        return this.multiInstance ? `${this.zone.name}(${this.instance})` : `${this.zone.name}`
    }
    get narrowDown(): string {
        return this.smobInfo ? this.smobInfo.narrowDown : ''
    }
    get showNarrowDown(): boolean {
        return this.smobInfo ? this.smobInfo.showNarrowDown : false
    }
    // private
    get smobInfo(): IZoneInstanceSMobInfo | undefined {
        return worldModule.getSMobInfo(this.zone.id, this.instance);
    }
}


export class MobMapHandler {
    canvas: HTMLCanvasElement;
    zoneInstance: IZoneInstance;
    mouseState: { isDown: boolean }
    touchState: { isTouched: boolean, lastDistance: number }
    lastCPos: Position; // last canvas position of mouse/touch action
    focusedLocationIndex: number;
    //    scale: number
    //    offset: Position;
    debugMessage: string;
    icon: {
        cursor: HTMLImageElement,
        party: HTMLImageElement,
        enemy: HTMLImageElement,
        enemyMini: HTMLImageElement,
        aetheryte: HTMLImageElement,
        ship: HTMLImageElement,
        gate: HTMLImageElement,
        report: HTMLImageElement,
        up: HTMLImageElement,
        down: HTMLImageElement,
        ss: HTMLImageElement,
        sb: HTMLImageElement,
        elite: { [id: number]: HTMLImageElement },
        check: { [id: number]: HTMLImageElement }
    };
    /*    display: {
            S: number,
            A: number,
            B: number,
            A2: number,
            B2: number,
            SS: number
        };*/
    doAnimate: boolean;
    showLabel: boolean;
    callback?: (index: number) => void;

    constructor(
    ) {
        this.canvas = document.createElement("canvas");
        this.zoneInstance = new ZoneInstance(DEFAULTZONE, 1, false);
        this.mouseState = {
            isDown: false,
        }
        this.touchState = {
            isTouched: false,
            lastDistance: 0.0,
        }
        this.lastCPos = { x: 0, y: 0 }
        this.focusedLocationIndex = -1;
        //        this.scale = 1.0;
        //        this.offset = { x: 0, y: 0 }
        this.debugMessage = '';
        this.icon = {
            cursor: new Image(),
            party: new Image(),
            enemy: new Image(),
            enemyMini: new Image(),
            aetheryte: new Image(),
            ship: new Image(),
            gate: new Image(),
            report: new Image(),
            up: new Image(),
            down: new Image(),
            ss: new Image(),
            sb: new Image(),
            elite: {},
            check: {},
        };
/*        this.display = {
            S: 0,
            A: 0,
            B: 0,
            A2: 0,
            B2: 0,
            SS: 0
        };
*/        this.doAnimate = true;
        this.showLabel = false;
        this.addEventHandlers();
    }

    animate = (doAnimate: boolean): void => {
        this.doAnimate = doAnimate;
    }

    handleMouseDown = (evt: MouseEvent): void => {
        evt.preventDefault();
        this.lastCPos = { x: evt.offsetX, y: evt.offsetY };
        const mPos = this.canvas2map(this.lastCPos);
        this.mouseState.isDown = true
        const selectedIndex = this.zoneInstance.zone.getEliteLocationIndex(mPos, 0.8);
        if (selectedIndex >= 0) {
            const isValid = this.zoneInstance.zone.isValidLocationIndex(selectedIndex, settingsModule.displayRanks);
            const callback = this.callback;
            if (isValid && callback) {
                callback(selectedIndex);
            }
        }
    }

    handleMouseUp = (evt: MouseEvent): void => {
        this.canvas.classList.remove("mouseMoving");
        this.lastCPos = { x: evt.offsetX, y: evt.offsetY };
        //const mPos = this.canvas2map(this.lastCPos);
        this.mouseState.isDown = false
    }

    handleMouseOut = (): void => {
        this.mouseState.isDown = false;
        this.focusedLocationIndex = -1;
    }

    handleMouseMove = (evt: MouseEvent): void => {
        const lastMPos = this.canvas2map(this.lastCPos);
        this.lastCPos = { x: evt.offsetX, y: evt.offsetY };
        const mPos = this.canvas2map(this.lastCPos);
        if (this.mouseState.isDown && this.focusedLocationIndex == -1) {
            const diffx = mPos.x - lastMPos.x;
            const diffy = mPos.y - lastMPos.y;
            this.zoneInstance.offset.x -= diffx;
            this.zoneInstance.offset.y -= diffy;
            this.adjustOffsets();
        }
        else {
            const selectedIndex = this.zoneInstance.zone.getEliteLocationIndex(mPos, 0.8)
            if (selectedIndex >= 0) {
                const isValid = this.zoneInstance.zone.isValidLocationIndex(selectedIndex, settingsModule.displayRanks);
                if (isValid) {
                    this.focusedLocationIndex = selectedIndex;
                    this.canvas.classList.add("locationFocused");
                }
                else {
                    this.focusedLocationIndex = -1;
                    this.canvas.classList.remove("locationFocused");
                }
            } else {
                this.focusedLocationIndex = -1;
                this.canvas.classList.remove("locationFocused");
            }
        }
    }

    zoomAt = (cPos: IPoint, factor: number): void => {
        const oldScale = this.zoneInstance.scale;
        const newScale = Math.max(Math.min(this.zoneInstance.scale * factor, 3.0), 1.0)
        this.zoneInstance.scale = newScale;
        // Calculate offsets
        const oldOffset = { x: this.zoneInstance.offset.x, y: this.zoneInstance.offset.y };
        this.zoneInstance.offset.x = oldOffset.x + (cPos.x * this.factor) * (1 / oldScale - 1 / newScale)
        this.zoneInstance.offset.y = oldOffset.y + (cPos.y * this.factor) * (1 / oldScale - 1 / newScale)
        this.adjustOffsets();
    }

    handleTouch = (evt: TouchEvent): void => {
        evt.preventDefault();
        const canvas = evt.target as HTMLCanvasElement;
        const parent = canvas.offsetParent as HTMLElement;
        if (evt.touches.length === 0) {
            const cPos = {
                x: evt.changedTouches[0].clientX - canvas.offsetLeft,
                y: evt.changedTouches[0].clientY - canvas.offsetTop - parent.offsetTop
            };

            const mPos = this.canvas2map(cPos);
            //this.debugMessage = `touch0: c(${cPos.x.toFixed(1)},${cPos.y.toFixed(1)}), m(${mPos.x.toFixed(1)},${mPos.y.toFixed(1)})`;
            const selectedIndex = this.zoneInstance.zone.getEliteLocationIndex(mPos, 0.8);
            if (selectedIndex != -1 && selectedIndex == this.focusedLocationIndex) {
                if (selectedIndex >= 0) {
                    const callback = this.callback;
                    if (callback) {
                        callback(selectedIndex);
                    }
                }
            }
            this.lastCPos = { x: 0, y: 0 };
            this.touchState.lastDistance = 0.0;
            this.focusedLocationIndex = -1;
        }
        else if (evt.touches.length === 1) {
            const lastMPos = this.canvas2map(this.lastCPos);
            const cPos = {
                x: evt.touches[0].clientX - canvas.offsetLeft,
                y: evt.touches[0].clientY - canvas.offsetTop - parent.offsetTop
            };
            const mPos = this.canvas2map(cPos);
            if (evt.type == 'touchmove') {
                //this.debugMessage = `touch1: c(${cPos.x.toFixed(1)},${cPos.y.toFixed(1)}), m(${mPos.x.toFixed(1)},${mPos.y.toFixed(1)})`;
                const diffx = mPos.x - lastMPos.x;
                const diffy = mPos.y - lastMPos.y;
                this.zoneInstance.offset.x -= diffx;
                this.zoneInstance.offset.y -= diffy;
                this.adjustOffsets();
            }
            else {
                //this.debugMessage = `touch1: c(${cPos.x.toFixed(1)},${cPos.y.toFixed(1)}), m(${mPos.x.toFixed(1)},${mPos.y.toFixed(1)})`;
            }
            const selectedIndex = this.zoneInstance.zone.getEliteLocationIndex(mPos, 0.8);
            if (selectedIndex >= 0) {
                const isValid = this.zoneInstance.zone.isValidLocationIndex(selectedIndex, settingsModule.displayRanks);
                this.focusedLocationIndex = isValid ? selectedIndex : -1;
            }
            else {
                this.focusedLocationIndex = -1;
            }
            this.lastCPos = { x: cPos.x, y: cPos.y };
            this.touchState.lastDistance = 0.0;
        }
        else if (evt.touches.length === 2) {
            const cPos0 = {
                x: evt.touches[0].clientX - canvas.offsetLeft,
                y: evt.touches[0].clientY - canvas.offsetTop - parent.offsetTop
            };
            const cPos1 = {
                x: evt.touches[1].clientX - canvas.offsetLeft,
                y: evt.touches[1].clientY - canvas.offsetTop - parent.offsetTop
            };
            const cPos = {
                x: (cPos0.x + cPos1.x) / 2,
                y: (cPos0.y + cPos1.y) / 2
            }
            const distance = Math.sqrt(
                (cPos1.x - cPos0.x) * (cPos1.x - cPos0.x) +
                (cPos1.y - cPos0.y) * (cPos1.y - cPos0.y));
            if (evt.type == 'touchmove') {
                const factor = distance / this.touchState.lastDistance;
                //this.debugMessage = `touch2: c(${cPos.x.toFixed(1)},${cPos.y.toFixed(1)}), factor(${factor.toFixed(1)})`;
                this.zoomAt(cPos, factor);
            }
            this.lastCPos = cPos;
            this.touchState.lastDistance = distance;
        }
    }

    handleWheel = (evt: WheelEvent): void => {
        const cPos = { x: evt.offsetX, y: evt.offsetY };
        const factor = (evt.deltaY < 0) ? 1.1 : (evt.deltaY > 0) ? 1 / 1.2 : 1.0;
        this.zoomAt(cPos, factor);
    }

    addEventHandlers = (): void => {
        this.canvas.addEventListener('mousedown', this.handleMouseDown, false);
        this.canvas.addEventListener('mouseup', this.handleMouseUp, false);
        this.canvas.addEventListener('mouseout', this.handleMouseOut, false);
        this.canvas.addEventListener('mousemove', this.handleMouseMove, false);
        this.canvas.addEventListener("wheel", this.handleWheel, false);
        this.canvas.addEventListener('touchstart', this.handleTouch, false);
        this.canvas.addEventListener('touchend', this.handleTouch, false);
        this.canvas.addEventListener('touchmove', this.handleTouch, false);
    }

    removeEventHandlers = (): void => {
        this.canvas.removeEventListener('mousedown', this.handleMouseDown, false);
        this.canvas.removeEventListener('mouseup', this.handleMouseUp, false);
        this.canvas.removeEventListener('mouseout', this.handleMouseOut, false);
        this.canvas.removeEventListener('mousemove', this.handleMouseMove, false);
        this.canvas.removeEventListener("wheel", this.handleWheel, false);
        this.canvas.removeEventListener('touchstart', this.handleTouch, false);
        this.canvas.removeEventListener('touchend', this.handleTouch, false);
        this.canvas.removeEventListener('touchmove', this.handleTouch, false);
    }

    adjustOffsets = (): void => {
        const factorX = this.canvas.width / Math.min(this.canvas.width, this.canvas.height)
        const factorY = this.canvas.height / Math.min(this.canvas.width, this.canvas.height)
        const factorXX = factorX / this.zoneInstance.scale
        //this.debugMessage = `factorX=${factorX}, factorXX=${factorXX} offsetX=${this.zoneInstance.offset.x}`
        if (factorXX > 1) {
            // align middle
            this.zoneInstance.offset.x = this.zoneInstance.zone.scale.xRange * (1 - factorXX) / 2
        }
        else {
            this.zoneInstance.offset.x = Math.max(Math.min(this.zoneInstance.offset.x, this.zoneInstance.zone.scale.xRange * factorXX * (this.zoneInstance.scale / factorX - 1)), 0);
        }
        this.zoneInstance.offset.y = Math.max(Math.min(this.zoneInstance.offset.y, this.zoneInstance.zone.scale.yRange * factorY / this.zoneInstance.scale * (this.zoneInstance.scale / factorY - 1)), 0);
    }

    loadImage = async (src: string): Promise<HTMLImageElement> => {
        return await new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (e) => reject(e);
            img.src = src;
        });
    }

    setSize = (width: number, height: number): void => {
        this.canvas.width = width;
        this.canvas.height = height;
        this.adjustOffsets()
    }

    setZoneInstance = (zoneInstance: IZoneInstance): void => {
        if (this.zoneInstance.zone.id != zoneInstance.zone.id ||
            this.zoneInstance.instance != zoneInstance.instance) {
            console.debug("current zoneInstance changed")
            this.zoneInstance = zoneInstance
            if (!this.zoneInstance.readyToDraw) {
                console.debug("loading image");
                this.zoneInstance.image.src = `https://res.cloudinary.com/lanaklein14/image/upload/v1624232409/small/${this.zoneInstance.zone.id}.jpg`
            }
        }
    }

    initialize = async (display: {
        S: number,
        A: number,
        B: number,
        A2: number,
        B2: number,
        SS: number
    }, callback: (index: number) => void): Promise<void> => {
        //        this.display = display;
        this.callback = callback;
        this.icon = {
            cursor: await this.loadImage(urlCursor),
            party: await this.loadImage(urlParty),
            enemy: await this.loadImage(urlEnemy),
            enemyMini: await this.loadImage(urlEnemyMini),
            aetheryte: await this.loadImage(urlAetheryte),
            ship: await this.loadImage(urlShip),
            gate: await this.loadImage(urlGate),
            report: await this.loadImage(urlReport),
            up: await this.loadImage(urlUp),
            down: await this.loadImage(urlDown),
            ss: await this.loadImage(urlSS),
            sb: await this.loadImage(urlSB),
            elite: {},
            check: {}
        };
        this.icon.elite[0b00001] = await this.loadImage(url00001);
        this.icon.elite[0b00010] = await this.loadImage(url00010);
        this.icon.elite[0b00011] = await this.loadImage(url00011);
        this.icon.elite[0b00100] = await this.loadImage(url00100);
        this.icon.elite[0b00101] = await this.loadImage(url00101);
        this.icon.elite[0b00110] = await this.loadImage(url00110);
        this.icon.elite[0b00111] = await this.loadImage(url00111);
        this.icon.elite[0b01000] = await this.loadImage(url01000);
        this.icon.elite[0b01001] = await this.loadImage(url01001);
        this.icon.elite[0b01010] = await this.loadImage(url01010);
        this.icon.elite[0b01011] = await this.loadImage(url01011);
        this.icon.elite[0b01100] = await this.loadImage(url01100);
        this.icon.elite[0b01101] = await this.loadImage(url01101);
        this.icon.elite[0b01110] = await this.loadImage(url01110);
        this.icon.elite[0b01111] = await this.loadImage(url01111);
        this.icon.elite[0b10000] = await this.loadImage(url10000);
        this.icon.elite[0b10001] = await this.loadImage(url10001);
        this.icon.elite[0b10010] = await this.loadImage(url10010);
        this.icon.elite[0b10011] = await this.loadImage(url10011);
        this.icon.elite[0b10100] = await this.loadImage(url10100);
        this.icon.elite[0b10101] = await this.loadImage(url10101);
        this.icon.elite[0b10110] = await this.loadImage(url10110);
        this.icon.elite[0b10111] = await this.loadImage(url10111);
        this.icon.elite[0b11000] = await this.loadImage(url11000);
        this.icon.elite[0b11001] = await this.loadImage(url11001);
        this.icon.elite[0b11010] = await this.loadImage(url11010);
        this.icon.elite[0b11011] = await this.loadImage(url11011);
        this.icon.elite[0b11100] = await this.loadImage(url11100);
        this.icon.elite[0b11101] = await this.loadImage(url11101);
        this.icon.elite[0b11110] = await this.loadImage(url11110);
        this.icon.elite[0b11111] = await this.loadImage(url11111);
        this.icon.check[1] = await this.loadImage(urlcheck1);
        this.icon.check[2] = await this.loadImage(urlcheck2);
        this.icon.check[3] = await this.loadImage(urlcheck3);

        console.warn("calling animation")
        this.animation();
    }

    focusRect = (): void => {
        const focusRect = this.zoneInstance.zone.focusRect;
        if (focusRect.width / focusRect.height > this.canvas.width / this.canvas.height) {
            // Focus based on x axis
            this.zoneInstance.scale = (this.canvas.width * this.factor) / (focusRect.width)
            this.zoneInstance.offset.x = focusRect.x - this.zoneInstance.zone.scale.xMin
            this.zoneInstance.offset.y = focusRect.y + focusRect.height / 2 - this.zoneInstance.zone.scale.yMin - (this.canvas.height / 2 * this.factor / this.zoneInstance.scale)
        }
        else {
            // Focus based on y axis
            this.zoneInstance.scale = (this.canvas.height * this.factor) / focusRect.height
            this.zoneInstance.offset.y = focusRect.y - this.zoneInstance.zone.scale.yMin
            this.zoneInstance.offset.x = focusRect.x + focusRect.width / 2 - this.zoneInstance.zone.scale.xMin - (this.canvas.width / 2 * this.factor / this.zoneInstance.scale)
        }
        this.adjustOffsets();
    }

    animation = (): void => {
        if (!this.doAnimate) {
            return;
        }
        const ctx = this.canvas.getContext("2d") as CanvasRenderingContext2D;
        if (ctx && this.zoneInstance.readyToDraw) {
            // Fill Background
            ctx.fillStyle = "#000000";
            //ctx.fillStyle = "rgba(0, 0, 255, 0.5)";
            ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);


            ctx.save()
            // Base transformation
            ctx.scale(1 / this.factor, 1 / this.factor)
            ctx.scale(this.zoneInstance.scale, this.zoneInstance.scale)
            ctx.translate(-this.zoneInstance.offset.x, -this.zoneInstance.offset.y)

            const scale = this.zoneInstance.zone.scale;
            ctx.translate(-scale.xMin, -scale.yMin)

            this.drawImage(ctx);

            //this.drawPoints(ctx);
            //this.drawLines(ctx);
            //this.drawFocusRect(ctx);

            const zoneId = this.zoneInstance.zone.id;
            const instance = this.zoneInstance.instance;
            const recordsMap: { [index: number]: LocationRecord } = worldModule.getLocationRecordMap(zoneId, instance);
            this.drawMobPos(ctx, recordsMap);
            this.drawRebellion(ctx)
            this.drawSymbols(ctx);
            this.drawAetherytes(ctx);
            this.drawAbandonedElites(ctx, recordsMap);

            ctx.restore();

            this.drawCopyRight(ctx);

            this.drawDebugMessage(ctx);
        }
        window.requestAnimationFrame(this.animation);
    };

    drawImage = (ctx: CanvasRenderingContext2D): void => {
        const scale = this.zoneInstance.zone.scale;
        ctx.save()
        ctx.drawImage(this.zoneInstance.image, this.zoneInstance.image.x, this.zoneInstance.image.y, this.zoneInstance.image.width, this.zoneInstance.image.height
            , scale.xMin, scale.yMin, scale.xRange, scale.yRange);
        ctx.restore();
    }

    drawAbandonedElites = (ctx: CanvasRenderingContext2D, locationRecordMap: {[index: number]: LocationRecord}): void => {
        if (!this.showLabel) {
            return;
        }
        const zoneId = this.zoneInstance.zone.id;
        const abandonedMap = worldModule.getEliteAbandonedMap(zoneId, locationRecordMap);
        Object.keys(abandonedMap).map(Number).forEach(mobId => {
            const index = abandonedMap[mobId].index;
            const abandonedAt = new Date(abandonedMap[mobId].abandonedAt);
            if (!this.zoneInstance.zone.isValidLocationIndex(index, settingsModule.displayRanks) ||
                abandonedAt.getTime() + 240 * 60 * 1000 < (new Date()).getTime()) {
                return;
            }
            const mobColors = this.zoneInstance.zone.getMobColors(mobId);
            const loc = this.zoneInstance.zone.mobLocations[index];
            ctx.save();
            ctx.translate(loc.x, loc.y);

            const scale = this.factor / this.zoneInstance.scale;
            ctx.scale(scale, scale)
            ctx.translate(0, -20);
            ctx.strokeStyle = `#000000ff`;
            ctx.fillStyle = mobColors.bgColor;
            ctx.fillRect(-25, -8, 50, 16);
            ctx.font = "12px sans-serif";
            ctx.textAlign = "center";
            ctx.fillStyle = "white";
            const fromNow = dayjs(abandonedAt).fromNow();
            ctx.fillText(`${fromNow}`, 0, 4);

            ctx.restore();
        })
    }

    drawMobPos = (ctx: CanvasRenderingContext2D, locationRecordMap: {[index: number]: LocationRecord}): void => {
        this.zoneInstance.zone.mobLocations.forEach(
            (loc, index) => {
                if (loc.type != 'elite') {
                    return
                }
                if (!this.zoneInstance.zone.isValidLocationIndex(index, settingsModule.displayRanks)) {
                    return;
                }
                ctx.save();
                ctx.translate(loc.x, loc.y);

                const screenScale = this.factor / this.zoneInstance.scale
                const scale = screenScale * Math.min(
                    1 / screenScale / 10, 1//this.zoneInstance.scale / 1.7 
                ) * 0.8
                ctx.scale(scale, scale)
                let elite = 0;
                const elites = this.zoneInstance.zone.mobs.filter(
                    (m: { type: string }) => m.type === "elite"
                );
                if (elites.length === 3) {
                    if (
                        loc.mobIds.includes(elites[0].id) &&
                        settingsModule.display.S != undefined
                    ) {
                        elite |= 16;
                    }
                    if (
                        loc.mobIds.includes(elites[1].id) &&
                        settingsModule.display.A != undefined
                    ) {
                        elite |= 8;
                    }
                    if (
                        loc.mobIds.includes(elites[2].id) &&
                        settingsModule.display.B != undefined
                    ) {
                        elite |= 2;
                    }
                } else {
                    //type === 1
                    if (
                        loc.mobIds.includes(elites[0].id) &&
                        settingsModule.display.S != undefined
                    ) {
                        elite |= 16;
                    }
                    if (
                        loc.mobIds.includes(elites[1].id) &&
                        settingsModule.display.A != undefined
                    ) {
                        elite |= 8;
                    }
                    if (
                        loc.mobIds.includes(elites[2].id) &&
                        settingsModule.display.A2 != undefined
                    ) {
                        elite |= 4;
                    }
                    if (
                        loc.mobIds.includes(elites[3].id) &&
                        settingsModule.display.B != undefined
                    ) {
                        elite |= 2;
                    }
                    if (
                        loc.mobIds.includes(elites[4].id) &&
                        settingsModule.display.B2 != undefined
                    ) {
                        elite |= 1;
                    }
                }

                ctx.save();
                if (this.focusedLocationIndex === index) {
                    ctx.scale(1.2, 1.2);
                }
                ctx.globalAlpha = 1.0;
                const icon = this.icon.elite[elite];
                ctx.drawImage(
                  icon,
                  -icon.width / 2,
                  -icon.height / 2
                );
                ctx.restore();

                const record = locationRecordMap[index];
                if (record) {
                  const flag = record.flag;
                  if (flag > 1) {
                    const check = this.icon.check[flag];
                    ctx.globalAlpha = 1.0;
                    ctx.drawImage(
                      check,
                      -check.width / 2,
                      -check.height / 2
                    );
                  }
                }

//                ctx.strokeStyle = `#000000${opacity}`;

                if (this.showLabel) {
                    ctx.save();
                    ctx.fillStyle = "#000000ff";
                    const text = `${loc.label}(${loc.x.toFixed(0)},${loc.y.toFixed(0)})`
                    ctx.font = "14px sans-serif";
                    ctx.textAlign = "center";
                    ctx.fillText(text, 0, 30);
                    ctx.restore();
                }

    

                ctx.restore();
            }
        );
    };

    drawRebellion = (
        ctx: CanvasRenderingContext2D
    ): void => {
        if (settingsModule.display.SS != 0) {
            return;
        }
        const screenScale = this.factor / this.zoneInstance.scale
        const scale = screenScale * Math.min(
            1 / screenScale / 10, 1
        ) * 0.8;
        const sss = this.zoneInstance.zone.mobs.filter(
            (m: { type: string }) => m.type === "ss"
        );
        if (sss.length < 2) {
            return;
        }
        const ssId = sss[0].id;
        const sbId = sss[1].id;

        this.zoneInstance.zone.mobLocations.forEach(
            (loc) => {
                if (loc.type != 'ss') {
                    return;
                }
                const icon =
                    loc.mobIds.includes(ssId) ? this.icon.ss :
                        loc.mobIds.includes(sbId) ? this.icon.sb : undefined;
                if (!icon) {
                    return;
                }

                ctx.save();
                ctx.translate(loc.x, loc.y);
                ctx.scale(scale, scale)
                ctx.drawImage(
                    icon,
                    -icon.width / 2,
                    -icon.height / 2
                );

                if (this.showLabel) {
                    ctx.fillStyle = "#000000ff";
                    const text = `(${loc.x.toFixed(0)},${loc.y.toFixed(0)})`
                    ctx.font = "14px sans-serif";
                    ctx.textAlign = "center";
                    ctx.fillText(text, 0, 30);
                }

                ctx.restore();
            });
    };

    drawAetherytes = (
        ctx: CanvasRenderingContext2D
    ): void => {
        this.zoneInstance.zone.aetherytes.forEach((a: { x: number; y: number }) => {
            ctx.save();
            ctx.translate(a.x, a.y);

            const screenScale = this.factor / this.zoneInstance.scale
            const scale = screenScale * Math.min(
                1 / screenScale / 10, 1
            )
            ctx.scale(scale, scale)

            ctx.drawImage(
                this.icon.aetheryte,
                -this.icon.aetheryte.width / 2,
                -this.icon.aetheryte.height / 2
            );
            ctx.restore();
        });
    };

    drawSymbols = (ctx: CanvasRenderingContext2D): void => {
        this.zoneInstance.zone.symbols.forEach(
            (s: { x: number; y: number; type: string }) => {
                ctx.save();
                ctx.translate(s.x, s.y);

                const screenScale = this.factor / this.zoneInstance.scale
                const scale = screenScale * Math.min(
                    1 / screenScale / 10, 1
                )
                ctx.scale(scale, scale)

                const imgSymbol =
                    s.type === "transportation" ? this.icon.ship : this.icon.gate;
                ctx.drawImage(
                    imgSymbol,
                    -imgSymbol.width / 2,
                    -imgSymbol.height / 2
                );
                ctx.restore();
            }
        );
    };

    drawLines = (ctx: CanvasRenderingContext2D): void => {
        ctx.lineWidth = 0.02;
        ctx.strokeStyle = "#ff0000";
        const scale = this.zoneInstance.zone.scale;
        for (let i = scale.xMin; i <= scale.xMax; i += 0.1) {
            ctx.beginPath();
            ctx.moveTo(i, scale.yMin);
            ctx.lineTo(i, scale.yMax);
            ctx.moveTo(scale.xMin, i);
            ctx.lineTo(scale.xMax, i);
            ctx.stroke();
            ctx.closePath()
        }
    }

    drawPoints = (ctx: CanvasRenderingContext2D): void => {
        ctx.strokeStyle = "#ff0000"
        ctx.fillStyle = "#ff0000"
        for (let x = 1; x <= 41.9; x += 10) {
            for (let y = 1; y <= 41.9; y += 10) {
                ctx.beginPath()
                ctx.arc(x, y, 0.5, 0, 2 * Math.PI);
                ctx.fill();
                ctx.closePath()
            }
        }
    }

    drawFocusRect = (ctx: CanvasRenderingContext2D): void => {
        const rect = this.zoneInstance.zone.focusRect;
        ctx.strokeStyle = "#ff0000"
        ctx.lineWidth = 0.2
        ctx.beginPath()
        ctx.strokeRect(rect.x, rect.y, rect.width, rect.height);
        ctx.closePath()
    }

    drawCopyRight = (ctx: CanvasRenderingContext2D): void => {
        ctx.save();
        ctx.translate(this.canvas.width / 2, this.canvas.height - 0);
        const scale = Math.min(this.canvas.width / 450, 1);
        ctx.scale(scale, scale);
        ctx.fillStyle = "#330000ff";
        const copyright =
            "(C) SQUARE ENIX CO., LTD. All Rights Reserved.";
        ctx.font = "12px sans-serif";
        ctx.textAlign = "center";
        ctx.fillText(copyright, 0, -10);
        ctx.restore();
    }

    drawDebugMessage = (ctx: CanvasRenderingContext2D): void => {
        ctx.save();
        ctx.translate(this.canvas.width / 2, this.canvas.height - 0);
        const scale = Math.min(this.canvas.width / 450, 1);
        ctx.scale(scale, scale);
        ctx.fillStyle = "#330000ff";
        ctx.font = "12px sans-serif";
        ctx.textAlign = "center";
        if (this.debugMessage != '') {
            ctx.fillText(this.debugMessage, 0, -25);
        }
        ctx.restore();
    }


    get factor(): number {
        return Math.max(this.zoneInstance.zone.scale.xRange / this.canvas.width, this.zoneInstance.zone.scale.yRange / this.canvas.height);
    }

    canvas2map = (pos: Position): Position => {
        return {
            x: this.zoneInstance.offset.x + this.zoneInstance.zone.scale.xMin + pos.x * this.factor / this.zoneInstance.scale,
            y: this.zoneInstance.offset.y + this.zoneInstance.zone.scale.yMin + pos.y * this.factor / this.zoneInstance.scale
        };
    }

    map2canvas = (pos: Position): Position => {
        return {
            x: (pos.x - this.zoneInstance.zone.scale.xMin - this.zoneInstance.offset.x) * this.zoneInstance.scale / this.factor,
            y: (pos.y - this.zoneInstance.zone.scale.yMin - this.zoneInstance.offset.y) * this.zoneInstance.scale / this.factor
        };
    }
}

