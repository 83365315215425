import { Mutation, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import { canPreserveDefault, store } from "@/store";
import { worlds } from "ffxivhuntdata";
import { filterWorldsRequireUpdate, getSMobRecords, ISMobRecord, SMobRecord } from "@/libs/serverProxy";
import { IMobRecordPayload } from "../../../../src/interfaces/smobrecords"
import Vue from "vue";

// state's interface
export interface IWorldCache {
    worldId: number;
    dcName: string;
    etag: string;
    records: IMobRecordPayload[];
}

export interface ISMobRecordsState {
    vrs: string;
    worlds: IWorldCache[];
}

const name = 'smobrecords';
const vrs = '0.2';

/*
try {
    store.unregisterModule(name)
} catch (error) {
    console.warn(`ignore ${error}`)
}
*/

const jpWorlds = worlds.filter(w => w.dcRegion === 1);

@Module({ dynamic: true, store, name, namespaced: true, preserveState: canPreserveDefault(name, vrs, "srecords") })
class SMobRecordsState extends VuexModule implements ISMobRecordsState {
    // state
    vrs = vrs;
    worlds = jpWorlds.map(w => {
        return {
            worldId: w.id,
            dcName: w.dcName,
            etag: "",
            records: []
        }
    });

    // getter
    get smobRecordsOfWorlds() {
        return (worldIds: number[]): ISMobRecord[] => {
            const worlds = this.worlds.filter(w => worldIds.includes(w.worldId));
            return worlds.map(w => w.records).flat().map((item: IMobRecordPayload) => new SMobRecord(item));
        }
    }

    get smobRecord() {
        return (worldId: number, zoneId: number, instance: number): ISMobRecord|null => {
            const world = this.worlds.find(w => w.worldId === worldId);
            if (world) {
                const record = world.records.find((r: IMobRecordPayload) => r.zoneId === zoneId && r.instance === instance);
                if (record) {
                    return new SMobRecord(record);
                }
            }
            return null;
        }
    }

    // mutation
    @Mutation
    public SET_RECORDS(payload: { worldId: number, etag: string, records: IMobRecordPayload[] }) {
        const index = this.worlds.findIndex(w => w.worldId === payload.worldId);
        if (index >= 0) {
            console.debug(`updating mob records of index ${index}`, this.worlds[index])
            Vue.set(this.worlds[index], "etag", payload.etag)
            Vue.set(this.worlds[index], "records", payload.records)
        }
    }

    @Action({ rawError: true })
    public async fetchRecords(worldIds: number[]) {
        let worlds = this.worlds.filter(w => worldIds.includes(w.worldId)).map(w => {
            return {
                worldId: w.worldId,
                etag: (w.records.length > 0 ? w.etag : "")
            }
        });
        if (worlds.length > 1) {
            worlds = await filterWorldsRequireUpdate(worlds);
        }
        await Promise.all(worlds.map(async item => {
            const result = await getSMobRecords(item.worldId, item.etag);
            if (result.update) {
                this.SET_RECORDS({ worldId: item.worldId, etag: result.etag, records: result.records })
            }
        }))
    }
}

export const smobrecordsModule = getModule(SMobRecordsState);
