








































import Vue from "vue";
import { World, worlds } from "ffxivhuntdata";
import { worldModule } from "@/store/modules/world";

export default Vue.extend({
  name: "WorldSelector",
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dataCenters(): { name: string; selectedIndex: number; worlds: World[] }[] {
      return [
        ...new Set(worlds.filter((w) => w.dcRegion === 1).map((w) => w.dcName)),
      ].map((dc) => {
        const worldsInDc = worlds.filter((w) => w.dcName === dc);
        const worldIndex = worldsInDc.findIndex((w) => w.id === this.worldId);
        return {
          name: dc,
          selectedIndex: worldIndex >= 0 ? worldIndex : -1,
          worlds: worldsInDc.sort((a, b) => a.name.localeCompare(b.name)),
        };
      }).sort((a, b) => a.name.localeCompare(b.name));
    },
    selectedWorld(): World {
      const world = worlds.find((w) => w.id === this.worldId);
      return world != null ? world : worlds[0];
    },
    selectedDc(): string {
      const dc = this.dataCenters.find((dc) =>
        dc.worlds.map((w) => w.id).includes(this.worldId)
      );
      return dc != null ? dc.name : "";
    },
    worldId: {
      get(): number {
        return worldModule.id;
      },
      set(value: number): void {
        worldModule.setWorld(value);
      },
    },
  },
});
