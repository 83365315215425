import { Mutation, VuexModule, getModule, Module } from "vuex-module-decorators";
import { canPreserveDefault, store } from "@/store";

// state's interface
export interface ISTimeTableViewState {
  vrs: string,
  multiFilter: boolean;
  isDcMode: boolean;
  availableFilterKeys: string[];
  selectedFilterKeys: string[];
}

const name = 'sTimeTableView';
const vrs = '0.1';

@Module({ dynamic: true, store, name, namespaced: true, preserveState: canPreserveDefault(name, vrs) })
class STimeTableViewState extends VuexModule implements ISTimeTableViewState {
  // state
  vrs = vrs;
  multiFilter = false;
  isDcMode = false;
  availableFilterKeys: string[] = [];
  selectedFilterKeys: string[] = [];

  // mutation
  @Mutation
  public SET_MULTI_FILTER(value: boolean) {
    this.multiFilter = value;
  }

  @Mutation
  public SET_DC_MODE(isDcMode: boolean) {
      this.isDcMode = isDcMode;
  }

  @Mutation
  public SET_AVAILABLE_FILTER_KEYS(keys: string[]) {
    this.availableFilterKeys = keys;
  }

  @Mutation
  public SET_SELECTED_FILTER_KEYS(keys: string[]) {
    this.selectedFilterKeys = keys;
  }
}

export const sTimeTableViewModule = getModule(STimeTableViewState);