




























import Vue from "vue";
import overlayController, { OverlayController } from "../libs/overlayController";
import { setLang, zones } from "ffxivhuntdata";
import OverlayCanvas from "../components/OverlayCanvas.vue";
import MapLocationCanvas from "../components/MapLocationCanvas.vue"
import { overlaySettingsModule } from "@/store/modules/overlaySettings";
import { userModule } from "@/store/modules/user";
import { i18n } from "@/i18n";
import { overlayModule } from "@/store/modules/overlay";
import { worldModule } from "@/store/modules/world";

interface Data {
  overlay: OverlayController;
  startdraw: boolean,
  intervalId: number,
  timerId: number,
  count: number
}

export default Vue.extend({
  name: "Overlay",
  components: {
    OverlayCanvas,
    MapLocationCanvas
  },
  data(): Data {
    return {
      overlay: overlayController,
      startdraw: zones.find(zone => zone.id === overlayModule.zoneId) != null,
      intervalId: 0,
      timerId: 0,
      count: 0,
    };
  },
  created() {
    i18n.locale = 'ja';
    setLang('ja');
    this.overlay.init();
    this.intervalId = window.setInterval(async () => {
      await overlayModule.refreshNotifiedLocations();
      await overlayModule.refreshElites();
      await overlayModule.refresh();
    }, 1000);
    this.timerId = window.setInterval(async () => {
      await this.refresh();
    }, 60 * 1000);
  },
  async mounted() {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "hidden";
    addEventListener('storage', this.handleStore, false);
  },
  beforeDestroy() {
    removeEventListener('storage', this.handleStore, false);
    clearInterval(this.intervalId);
    clearInterval(this.timerId);
  },

  destroyed() {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "";
    this.overlay.shutdown();
  },

  computed: {
    groupName() {
      return userModule.groupName;
    },
    playerName(): string {
      return overlayModule.playerName;
    },
    showHeader: {
      get(): boolean {
        return overlaySettingsModule.showHeader;
      },
      set(value: boolean) {
        overlaySettingsModule.SET_SHOW_HEADER(value);
      },
    },
    withheader(): boolean {
      return this.showHeader && overlayModule.zone.id !== 0
    },
    // location(): string {
    //   const x = overlayModule.player.x;
    //   const y = overlayModule.player.y;
    //   const z = overlayModule.player.z;
    //   return `(${toFixedFloor(x)}, ${toFixedFloor(y)}, ${toFixedFloor(z)}), (${toFixedFloor(x, 3)}, ${toFixedFloor(y, 3)}, ${toFixedFloor(z, 3)}))`;
    // },
  },
  methods: {
    openOverlaySettingsWindow() {
      window.open("/overlaySettings", "Overlay Settings", "width=800, height=600");
    },

    handleStore(e: StorageEvent) {
      if (e.key === 'overlay') {
        overlaySettingsModule.syncStore(e.newValue);
      }
    },
    async refresh() {
      await worldModule.fetchSMobRecordsForCurrentWorld()
    },
  }
});
