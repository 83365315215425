

















import Vue from "vue";
import NavigationDrawer from "@/components/NavigationDrawer.vue"
import WorldSelector from "@/components/WorldSelector.vue"
import Profile from "@/components/Profile.vue";
import EorzeaClock from "@/components/EorzeaClock.vue";

export default Vue.extend({
  name: "IndexHeader",
  components: {
    NavigationDrawer,
    WorldSelector,
    Profile,
    EorzeaClock,
  },
  data() {
    return {
      drawer: false
    }
  },
  props: {
    title: String,
    displayWorldSelector: Boolean
  },
  computed: {
  },
  watch: {
  }

});
