











































import Vue from "vue";
import overlayController, { OverlayController } from "../libs/overlayController";
import NavigationDrawer from "@/components/NavigationDrawer.vue"
import WorldSelector from '@/components/WorldSelector.vue'
import EorzeaClock from '@/components/EorzeaClock.vue';
import Profile from '@/components/Profile.vue';
import { zones } from "ffxivhuntdata";
import IndexFooter from "@/components/IndexFooter.vue"
import { fieldinstancesModule } from "@/store/modules/fieldinstances";
import { worldModule } from "@/store/modules/world";
import dayjs from "dayjs";
import { IZoneInstance, ZoneInstance } from "@/libs/mobmapHandler";
import { settingsModule } from "@/store/modules/settings";
import { getUser } from "@/libs/serverProxy";
import { userModule } from "@/store/modules/user";

interface Data {
  drawer: boolean,
  tab: number,
  overlay: OverlayController;
  timerId: number
}


export default Vue.extend({
  name: "MobMaps",
  components: {
    NavigationDrawer,
    WorldSelector,
    Profile,
    EorzeaClock,
    IndexFooter
  },
  data(): Data {
    return {
      drawer: false,
      tab: 0,
      overlay: overlayController,
      timerId: 0
    };
  },
  created() {
    this.overlay.init();
  },
  async mounted() {
    const user = await getUser();
    userModule.loadUser(user);

    await fieldinstancesModule.fetch();
    
    console.debug("MobMaps mounted", this.$route.query)
    if (this.$route.query.worldid) {
      try {
        const id = parseInt(this.$route.query.worldid as string, 10);
        worldModule.setWorld(id)
        this.$router.push(this.$route.path)
      }
      catch {
        console.error("ignore error");
      }
    }
    await this.refresh();
    this.timerId = window.setInterval(() => {
      this.refresh();
    }, 60 * 1000);
    console.debug("add store listener")
    addEventListener('storage', this.handleStore, false);
  },
  beforeDestroy() {
    console.debug("remove store listener")
    removeEventListener('storage', this.handleStore, false);
    clearInterval(this.timerId);
  },
  computed: {
    title(): string {
      return this.$route.meta != null ? this.$t(this.$route.meta.title) as string : '';
    },
    disableWorldSelector(): boolean {
      return this.$route.meta != null && this.$route.meta.disableWorldSelector;
    },
    allZoneInstances(): IZoneInstance[] {
      const results: IZoneInstance[] = [];
      zones.forEach(zone => {
        const instanceCount = fieldinstancesModule.getInstanceCount(zone.id);
        for (let i = 1; i <= instanceCount; i++) {
          results.push(new ZoneInstance(zone, i, instanceCount !== 1));
        }
      })
      return results;
    },
    zoneInstances(): IZoneInstance[] {
      const zoneInstancesOfCurrentWorld = worldModule.displayZoneInstaces;
      return this.allZoneInstances.filter(zi => zoneInstancesOfCurrentWorld.includes(`${zi.zone.id}_${zi.instance}`))
    },
    showAllZones: {
      get(): boolean {
        return settingsModule.showAllZones;
      },
      set(value: boolean): void {
        settingsModule.SET_SHOW_ALL_ZONES(value)
      }
    },
  },
  methods: {
    handleStore(e: StorageEvent) {
      if (e.key === 'locations') {
        worldModule.syncLocationRecords(e.newValue);
      }
    },
    async refresh() {
      console.debug(`${dayjs().format('YYYY-MM-DD HH:mm:ss')} refresh called`);
      await worldModule.fetchSMobRecordsForCurrentWorld()
    },
    addTab(zoneInstance: IZoneInstance) {
      worldModule.addZoneInstance(`${zoneInstance.zone.id}_${zoneInstance.instance}`)
      /*      this.zoneInstances = this.zoneInstances.filter(zi => zi.key != zoneInstance.key);
            this.zoneInstances.push(zoneInstance);*/
    },
    removeTab(zoneInstance: IZoneInstance) {
      worldModule.deleteZoneInstance(`${zoneInstance.zone.id}_${zoneInstance.instance}`)
      //this.zoneInstances = this.zoneInstances.filter(zi => zi.key != zoneInstance.key);
      this.$router.push(`/mobmaps`, () => {/**/ })
    },
  }
});
