import { Mutation, VuexModule, getModule, Module, Action } from "vuex-module-decorators";
import { store } from "@/store";
import Vue from "vue";

// notify <pos> <flag>
// seconds

// tts
// SS
// SB
// haika
// S
// A
// B
// B repop

// アマジナ鉱山社の技師
// 卵FATE
// ゲルゲイFATE

// 高低差表示
// displayUpDown: boolean; // false
// trueなら各MobLocationのzとplayer.zを比較して↑↓マークを付加する

export interface IPlayerToken {
    playerName: string;
    token: string;
    enabled: boolean;
}

// state's interface
export interface IOverlaySettingsState {
    vrs: string;
    scale: number;
    mapTransparent: boolean;
    showHeader: boolean;
    displayUpDown: boolean;
    playerShareMap: IPlayerToken[];
    notifyHuntNet: string[];
    tts: string[];
    posNotify: boolean;
    posNotifyPopupSeconds: number;
    posNotifyMarkingSeconds: number;
    iconFontScale: number;
    narrowDown: boolean;
    displayAbandoned: boolean;
    displayAbandonedMinutes: number;
    debugMode: boolean;
}

const name = 'overlaySettings';
const vrs = '0.2';

const defaultState: IOverlaySettingsState = {
    vrs: vrs,
    scale: 1.0,
    mapTransparent: false,
    showHeader: true,
    displayUpDown: false,
    playerShareMap: [],
    notifyHuntNet: ["SS", "S", "A"], // "MobLocation"
    tts: ["SS", "SB", "S", "A", "B", "SBpop", "Brepop", "Sstart"],
    posNotify: true,
    posNotifyPopupSeconds: 5,
    posNotifyMarkingSeconds: 180,
    iconFontScale: 1.0,
    narrowDown: true,
    displayAbandoned: true,
    displayAbandonedMinutes: 120,
    debugMode: false
}

function merge(def: IOverlaySettingsState, key: string, store: string): boolean {
    let result = false;
    try {
        const value = localStorage.getItem(store) as string;
        const obj = JSON.parse(value)[key];
        const merged: {[key:string]: IOverlaySettingsState} = {};
        merged[key] = { ...def, ...obj };
        localStorage.setItem(store, JSON.stringify(merged));
        result = true;
    }
    catch (error) {
        console.warn(`Preserve state failed: ${error}`);
        const merged: {[key:string]: IOverlaySettingsState} = {};
        merged[key] = def;
        localStorage.setItem(store, JSON.stringify(merged));
    }
    return result;
}

/*
try {
  store.unregisterModule(name)
} catch (error) {
  console.warn(`ignore ${error}`)
}
*/

@Module({ dynamic: true, store, name, namespaced: true, preserveState: merge(defaultState, 'overlaySettings', 'overlay') })
class OverlaySettingsState extends VuexModule implements IOverlaySettingsState {
    // state
    vrs = vrs;
    scale = 1.0;
    mapTransparent = false;
    showHeader = true;
    displayUpDown = false;
    playerShareMap: {
        playerName: string;
        enabled: boolean;
        token: string;
    }[] = [];
    notifyHuntNet = ["SS", "S", "A"]; // "MobLocation"
    tts = ["SS", "SB", "S", "A", "B", "SBpop", "Brepop", "Sstart"];
    posNotify = true;
    posNotifyPopupSeconds = 5;
    posNotifyMarkingSeconds = 180;
    iconFontScale = 1.0;
    narrowDown = true;
    displayAbandoned = true;
    displayAbandonedMinutes = 120;
    debugMode = false;

    // getter with params
    public get getToken() {
        return (playerName: string): string | undefined => {
            const map = this.playerShareMap.find(m => m.playerName == playerName && m.enabled);
            return map && map.token;
        }
    }

    // mutation
    @Mutation
    public SET_SCALE(value: number) {
        this.scale = value;
    }

    @Mutation
    public SET_MAP_TRANSPARENT(enable: boolean) {
        this.mapTransparent = enable;
    }

    @Mutation
    public SET_SHOW_HEADER(enable: boolean) {
        this.showHeader = enable;
    }

    @Mutation
    public SET_DISPLAY_UP_DOWN(enable: boolean) {
        this.displayUpDown = enable;
    }

    @Mutation
    public SET_PLAYER_SHARE_MAP(values: IPlayerToken[]) {
        Vue.set(this, "playerShareMap", values)
    }

    @Mutation
    public SET_NOTIFY_HUNT_NET(values: string[]) {
        Vue.set(this, "notifyHuntNet", values);
    }

    @Mutation
    public SET_TTS(values: string[]) {
        Vue.set(this, "tts", values);
    }

    @Mutation
    public SET_POS_NOTIFY(enable: boolean) {
        this.posNotify = enable;
    }

    @Mutation
    public SET_POS_NOTIFY_POPUP_SECONDS(value: number) {
        this.posNotifyPopupSeconds = value;
    }

    @Mutation
    public SET_POS_NOTIFY_MARKING_SECONDS(value: number) {
        this.posNotifyMarkingSeconds = value;
    }

    @Mutation
    public SET_ICON_FONT_SCALE(value: number) {
        this.iconFontScale = value;
    }

    @Mutation
    public SET_NARROW_DOWN(enable: boolean) {
        this.narrowDown = enable;
    }

    @Mutation
    public SET_DISPLAY_ABANDONED(enable: boolean) {
        this.displayAbandoned = enable;
    }

    @Mutation
    public SET_DISPLAY_ABANDONED_MINUTES(value: number) {
        this.displayAbandonedMinutes = value;
    }

    @Mutation
    public SET_DEBUG_MODE(enable: boolean) {
        this.debugMode = enable;
    }

    @Action({ rawError: true })
    public syncStore(json: string | null) {
        const newValue = (JSON.parse(json ?? "{}"))["overlaySettings"] ?? {}
        overlaySettingsModule.SET_MAP_TRANSPARENT(newValue.mapTransparent);
        overlaySettingsModule.SET_DISPLAY_UP_DOWN(newValue.displayUpDown);
        overlaySettingsModule.SET_TTS(newValue.tts);
        overlaySettingsModule.SET_NOTIFY_HUNT_NET(newValue.notifyHuntNet);
        overlaySettingsModule.SET_POS_NOTIFY(newValue.posNotify);
        overlaySettingsModule.SET_POS_NOTIFY_POPUP_SECONDS(newValue.posNotifyPopupSeconds);
        overlaySettingsModule.SET_POS_NOTIFY_MARKING_SECONDS(newValue.posNotifyMarkingSeconds);
        overlaySettingsModule.SET_PLAYER_SHARE_MAP(newValue.playerShareMap);
        overlaySettingsModule.SET_ICON_FONT_SCALE(newValue.iconFontScale);
        overlaySettingsModule.SET_NARROW_DOWN(newValue.narrowDown);
        overlaySettingsModule.SET_DISPLAY_ABANDONED(newValue.displayAbandoned);
        overlaySettingsModule.SET_DISPLAY_ABANDONED_MINUTES(newValue.displayAbandonedMinutes);
        overlaySettingsModule.SET_DEBUG_MODE(newValue.debugMode);
    }
}

export const overlaySettingsModule = getModule(OverlaySettingsState);