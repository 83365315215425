import { Mutation, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import { store, canPreserveDefault } from "@/store";
import Vue from "vue";
import { FieldInstance, getFieldInstances } from "@/libs/serverProxy";

// state's interface
export interface IFieldInstancesState {
    vrs: string;
    [zoneId: number]: number;
}

const name = 'fieldinstances';
const vrs = '0.1';

/*
try {
    store.unregisterModule(name)
} catch (error) {
    console.warn(`ignore ${error}`)
}
*/

@Module({ dynamic: true, store, name, namespaced: true, preserveState: canPreserveDefault(name, vrs) })
class FieldInstanceState extends VuexModule implements IFieldInstancesState {
    // state
    vrs = vrs;
    [zoneId: number]: number;

    // getter with params
    public get getInstanceCount() {
        return (zoneId: number) => this[zoneId] ?? 1
    }

    // mutation
    @Mutation
    public SET(fieldInstances: FieldInstance[]) {
        Object.keys(this).filter(key => key != 'vrs').forEach(key => {
            Vue.delete(this, key)
        })
        //fieldInstances.forEach(fi => this[fi.zoneId] = fi.instanceCount);
        fieldInstances.forEach(fi => Vue.set(this, fi.zoneId, fi.instanceCount));
    }

    // actions
    @Action({})
    public async fetch() {
        const results = await getFieldInstances();
        this.SET(results);
    }
}

export const fieldinstancesModule = getModule(FieldInstanceState);
