












































import Vue from "vue";
import urlCursor from "@/assets/images/060443.png";
import urlParty from "@/assets/images/060421.png";
import urlEnemy from "@/assets/images/060004.png";
import urlEnemyMini from "@/assets/images/060422.png";
import urlAetheryte from "@/assets/images/060453.png";
import urlShip from "@/assets/images/060456.png";
import urlGate from "@/assets/images/060467.png";
import urlReport from "@/assets/images/060561.png";
import urlUp from "@/assets/images/060954.png";
import urlDown from "@/assets/images/060955.png";
import urlSS from "@/assets/images/ss.png";
import urlSB from "@/assets/images/sb.png";
import url00001 from "@/assets/images/00001.png";
import url00010 from "@/assets/images/00010.png";
import url00011 from "@/assets/images/00011.png";
import url00100 from "@/assets/images/00100.png";
import url00101 from "@/assets/images/00101.png";
import url00110 from "@/assets/images/00110.png";
import url00111 from "@/assets/images/00111.png";
import url01000 from "@/assets/images/01000.png";
import url01001 from "@/assets/images/01001.png";
import url01010 from "@/assets/images/01010.png";
import url01011 from "@/assets/images/01011.png";
import url01100 from "@/assets/images/01100.png";
import url01101 from "@/assets/images/01101.png";
import url01110 from "@/assets/images/01110.png";
import url01111 from "@/assets/images/01111.png";
import url10000 from "@/assets/images/10000.png";
import url10001 from "@/assets/images/10001.png";
import url10010 from "@/assets/images/10010.png";
import url10011 from "@/assets/images/10011.png";
import url10100 from "@/assets/images/10100.png";
import url10101 from "@/assets/images/10101.png";
import url10110 from "@/assets/images/10110.png";
import url10111 from "@/assets/images/10111.png";
import url11000 from "@/assets/images/11000.png";
import url11001 from "@/assets/images/11001.png";
import url11010 from "@/assets/images/11010.png";
import url11011 from "@/assets/images/11011.png";
import url11100 from "@/assets/images/11100.png";
import url11101 from "@/assets/images/11101.png";
import url11110 from "@/assets/images/11110.png";
import url11111 from "@/assets/images/11111.png";
import urlcheck1 from "@/assets/images/check1.png";
import urlcheck2 from "@/assets/images/check2.png";
import urlcheck3 from "@/assets/images/check3.png";

import { ICharacter } from "@/libs/centurionEvents";
import overlayController from "@/libs/overlayController";
import { Zone } from "ffxivhuntdata";
import MapHeader from "@/components/MapHeader.vue"
import { overlaySettingsModule } from "@/store/modules/overlaySettings";
import { settingsModule } from "@/store/modules/settings";
import { NotifiedLocation, overlayModule } from "@/store/modules/overlay";
import copy from 'copy-text-to-clipboard';
import { LocationRecord, worldModule } from "@/store/modules/world";
import dayjs from "dayjs";
import 'dayjs/locale/ja'
dayjs.locale('ja');

interface IPosition {
  x: number;
  y: number;
}

interface IEdgePositon extends IPosition {
  labelOffsetX: number;
  labelOffsetY: number;
  distance: number;
}

interface IRect {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

interface Data {
  canvas: HTMLCanvasElement;
  mapImage: HTMLImageElement;
  mapImage2: HTMLImageElement;
  icon: {
    cursor: HTMLImageElement,
    party: HTMLImageElement,
    enemy: HTMLImageElement,
    enemyMini: HTMLImageElement,
    aetheryte: HTMLImageElement,
    ship: HTMLImageElement,
    gate: HTMLImageElement,
    report: HTMLImageElement,
    up: HTMLImageElement,
    down: HTMLImageElement,
    ss: HTMLImageElement,
    sb: HTMLImageElement,
    elite: { [id: number]: HTMLImageElement },
    check: { [id: number]: HTMLImageElement },
  };
  globalAlpha: number;
  canvasWidth: number;
  debug: {
    frame: number;
    startTimeStamp: number;
    fps: number;
    performance: {
      [name: string]: Perf
    };
  }
}

interface Perf {
  stack: number;
  duration: number;
}

export default Vue.extend({
  name: "OverlayCanvas",
  components: {
    MapHeader
  },
  props: {
    //  notifiedLocations: Array as PropType<NotifiedLocation[]>,
  },
  data(): Data {
    return {
      canvas: document.createElement("canvas"),
      mapImage: new Image(),
      mapImage2: new Image(),
      icon: {
        cursor: new Image(),
        party: new Image(),
        enemy: new Image(),
        enemyMini: new Image(),
        aetheryte: new Image(),
        ship: new Image(),
        gate: new Image(),
        report: new Image(),
        up: new Image(),
        down: new Image(),
        ss: new Image(),
        sb: new Image(),
        elite: {},
        check: {},
      },
      globalAlpha: 0.0,
      canvasWidth: 300,
      debug: {
        frame: 0,
        startTimeStamp: 0,
        fps: 30,
        performance: {
          drawTotal: { stack: 0, duration: 0 },
          drawMap: { stack: 0, duration: 0 },
          drawDetection: { stack: 0, duration: 0 },
          drawSymbols: { stack: 0, duration: 0 },
          drawAetherytes: { stack: 0, duration: 0 },
          drawMobPos: { stack: 0, duration: 0 },
          drawRebellion: { stack: 0, duration: 0 },
          drawMobPosLocations: { stack: 0, duration: 0 },
          drawNotifiedLocations: { stack: 0, duration: 0 },
          drawParty: { stack: 0, duration: 0 },
          drawSelf: { stack: 0, duration: 0 },
          drawTargets: { stack: 0, duration: 0 },
        }
      }
    };
  },
  mounted() {
    const parent = document.getElementById('canvasWrapper') as HTMLElement;
    parent.appendChild(this.canvas);

    if (this.canvas) {
      this.canvas.addEventListener("wheel", this.handleWheel);
    }
    if (this.zone.id != 0) {
      this.drawCanvas();
    }
    window.addEventListener('resize', this.updateCanvasWidth);
    this.updateCanvasWidth();
  },
  beforeDestroy() {
    if (this.canvas) {
      this.canvas.removeEventListener("wheel", this.handleWheel);
    }
    window.removeEventListener('resize', this.updateCanvasWidth);
  },
  computed: {
    notifiedLocations(): NotifiedLocation[] {
      return overlayModule.notifiedLocations;
    },
    self(): ICharacter {
      return overlayModule.player;
    },
    border(): IRect {
      const s = this.zone.scale;
      const width = s.xRange / this.scale;
      const height = s.yRange / this.scale;

      let left = this.self.x - width / 2;
      let top = this.self.y - height / 2;
      const offsetLeft = left >= s.xMin ? Math.min(s.xMax - (left + width), 0) : Math.max(0, s.xMin - left);
      const offsetTop = top >= s.yMin ? Math.min(s.yMax - (top + height), 0) : Math.max(0, s.yMin - top);
      left += offsetLeft;
      top += offsetTop;
      return {
        left: left,
        top: top,
        right: left + width,
        bottom: top + height
      }
    },
    party(): ICharacter[] {
      return overlayModule.party;
    },
    targets(): ICharacter[] {
      return overlayModule.targets;
    },
    zoneId(): number {
      return overlayModule.zoneId;
    },
    display() {
      return settingsModule.display;
    },
    showHeader: {
      get(): boolean {
        return overlaySettingsModule.showHeader;
      },
      set(value: boolean) {
        overlaySettingsModule.SET_SHOW_HEADER(value);
      },
    },
    zone(): Zone {
      return overlayModule.zone;
    },
    zoneType() {
      return overlayModule.zone.type;
    },
    mobName() {
      return overlayModule.zone.mobNames;
    },
    scale: {
      get(): number {
        return overlaySettingsModule.scale;
      },
      set(value: number) {
        overlaySettingsModule.SET_SCALE(value);
      }
    },
    ctx(): CanvasRenderingContext2D {
      return this.canvas.getContext("2d") as CanvasRenderingContext2D;
    },
    factor(): number {
      return this.zone.scale.xRange / this.canvasWidth;
    },
    overlayError(): string {
      if (overlayController.errorCode == 1) {
        return this.$t('centurionPluginNotInstalled') as string;
      }
      else if (overlayController.errorCode == 2) {
        return this.$t('centurionPluginVersionMismatch') as string;
      }
      return "";
    },
    fieldInstanceIcon(): string {
      return overlayModule.fieldInstanceIcon;
    },
    displayFieldInstanceWarning(): boolean {
      return overlayModule.displayFieldInstanceWarning;
    },
    textZoom(): number {
      return Math.min(this.canvasWidth / 450, 1);
    },
    textColor(): string {
      return overlaySettingsModule.mapTransparent ? "#bbf2ef" : "#330000";
    },
    debugMode(): boolean {
      return overlaySettingsModule.debugMode;
    }
  },
  watch: {
    zone() {
      if (this.zone.id != 0) {
        this.drawCanvas();
      }
    },
  },
  methods: {
    copyDownloadUrl() {
      copy(`${window.location.origin}/download/Centurion.zip`);
    },
    handleWheel(e: { deltaY: number }) {
      if (e.deltaY < 0) {
        //e.deltaY == -100
        const newScale = this.scale * 1.1;
        this.scale = Math.min(newScale, 8.0);
      } else if (e.deltaY > 0) {
        //e.deltaY == 100
        const newScale = this.scale / 1.2;
        this.scale = Math.max(newScale, 1.0);
      }
    },
    updateCanvasWidth() {
      this.canvasWidth = window.innerWidth;
    },
    async loadImage(src: string): Promise<HTMLImageElement> {
      return await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    },

    getAlphaOfTimestamp(
      timestamp: number,
      frequency = 1.0,
      max = 1.0,
      min = 0.0
    ) {
      const rad =
        2 * Math.PI * frequency * 0.001 * (timestamp % (1000 / frequency));
      return (Math.cos(rad) / 2 + 0.5) * (max - min) + min;
    },

    drawImage() {
      const scale = this.zone.scale;
      const image = overlaySettingsModule.mapTransparent ? this.mapImage2 : this.mapImage;
      this.ctx.save();
      this.ctx.drawImage(image, image.x, image.y, image.width, image.height
        , scale.xMin, scale.yMin, scale.xRange, scale.yRange);
      this.ctx.restore();
    },

    drawAetherytes() {
      this.zone.aetherytes.forEach((a: { x: number; y: number }) => {
        this.ctx.save();
        this.ctx.translate(a.x, a.y);

        this.ctx.scale(this.factor / this.scale, this.factor / this.scale);

        this.ctx.drawImage(
          this.icon.aetheryte,
          -this.icon.aetheryte.width / 2,
          -this.icon.aetheryte.height / 2
        );
        this.ctx.restore();
      });
    },
    /*
        drawLines() {
          const s = this.zone.scale;
          this.ctx.lineWidth = 0.01;
          this.ctx.strokeStyle = "#ff0000";
          for (let i = s.xMin; i <= s.xMax; i += 0.1) {
            this.ctx.beginPath();
            this.ctx.moveTo(i, s.yMin);
            this.ctx.lineTo(i, s.yMax);
            this.ctx.moveTo(s.xMin, i);
            this.ctx.lineTo(s.xMax, i);
            this.ctx.stroke();
            this.ctx.closePath()
          }
        },
    */
    getEdgePosition(target: IPosition, offsetYBase = 25): IEdgePositon | null {
      const self = this.self;
      const rect = this.border;
      const points: IPosition[] = [];
      const result: IEdgePositon = {
        x: 0,
        y: 0,
        labelOffsetX: 0,
        labelOffsetY: 0,
        distance: Math.sqrt((target.x - self.x) * (target.x - self.x) * 2500 + (target.y - self.y) * (target.y - self.y) * 2500)
      };
      if (target.x < rect.left) {
        points.push({
          x: rect.left,
          y: self.y - (self.x - rect.left) * (self.y - target.y) / (self.x - target.x)
        });
      }
      if (target.y < rect.top) {
        points.push({
          x: self.x - (self.y - rect.top) * (self.x - target.x) / (self.y - target.y),
          y: rect.top
        });
      }
      if (target.x > rect.right) {
        points.push({
          x: rect.right,
          y: (rect.right - self.x) * (target.y - self.y) / (target.x - self.x) + self.y
        });
      }
      if (target.y > rect.bottom) {
        points.push({
          x: (rect.bottom - self.y) * (target.x - self.x) / (target.y - self.y) + self.x,
          y: rect.bottom
        });
      }
      const point = points.length > 0 ? points.reduce((a: IPosition, b: IPosition) => {
        const diffAx = a.x - self.x;
        const diffAy = a.y - self.y;
        const diffBx = b.x - self.x;
        const diffBy = b.y - self.y;
        return (diffAx * diffAx + diffAy * diffAy) < (diffBx * diffBx + diffBy * diffBy) ? a : b;
      }) : null
      if (point != null) {
        result.x = point.x;
        result.y = point.y;
        const leftPixel = (result.x - rect.left) * this.scale / this.factor;
        const topPixel = (result.y - rect.top) * this.scale / this.factor;
        const rightPixel = (rect.right - result.x) * this.scale / this.factor;
        const bottomPixel = (rect.bottom - result.y) * this.scale / this.factor;
        if (leftPixel < 40) {
          result.labelOffsetX = 40 - Math.max(leftPixel, 0);
        }
        else if (rightPixel < 40) {
          result.labelOffsetX = (Math.max(rightPixel, 0) - 40);
        }
        if (topPixel < offsetYBase) {
          result.labelOffsetY = (offsetYBase - Math.max(topPixel, 0));
        }
        else if (bottomPixel < offsetYBase) {
          result.labelOffsetY = (Math.max(bottomPixel, 0) - offsetYBase);
        }
      }
      return point != null ? result : null;
    },
    /*
        drawBorders() {
          const target = { x: 35.0, y: 35.0 };
          const point = this.getEdgePosition(target);
    
          this.ctx.lineWidth = 0.2;
          this.ctx.strokeStyle = "#ff0000";
          this.ctx.beginPath();
          this.ctx.rect(this.border.left, this.border.top, this.border.right - this.border.left, this.border.bottom - this.border.top);
          this.ctx.stroke();
          this.ctx.closePath()
    
          if (point != null) {
            this.ctx.fillStyle = "#ffff00";
            this.ctx.beginPath();
            this.ctx.arc(point.x, point.y, 1.0, 0, 2 * Math.PI, false);
            this.ctx.fill();
            this.ctx.closePath();
          }
          else {
            this.ctx.fillStyle = "#ffff00";
            this.ctx.beginPath();
            this.ctx.arc(target.x, target.y, 1.0, 0, 2 * Math.PI, false);
            this.ctx.fill();
            this.ctx.closePath();
          }
    
        },
    */
    drawSymbols() {
      this.zone.symbols.forEach(
        (s: { x: number; y: number; type: string }) => {
          this.ctx.save();
          this.ctx.translate(s.x, s.y);

          this.ctx.scale(this.factor / this.scale, this.factor / this.scale);

          const imgSymbol =
            s.type === "transportation" ? this.icon.ship : this.icon.gate;
          this.ctx.drawImage(
            imgSymbol,
            -imgSymbol.width / 2,
            -imgSymbol.height / 2
          );
          this.ctx.restore();
        }
      );
    },

    drawNotifiedLocations() {
      this.notifiedLocations
        .filter((a) => a.zoneId === this.zone.id)
        .forEach((a) => {
          this.ctx.save();

          const scale = Math.max(this.factor / 3 / this.scale, Math.min(1 / 20, 0.2 / this.scale))
          const radius = 10 * 1.3;
          this.ctx.lineWidth = radius * 0.5;

          const index = a.nearMobLocationIndex;
          if (index >= 0 && this.zone.isValidLocationIndex(index, settingsModule.displayRanks)) {
            const pos = this.zone.mobLocations[index];
            const onBorderPos = this.getEdgePosition(pos);
            //const onBorderPos = this.getEdgePosition(pos, 25+8);
            if (onBorderPos) {
              this.ctx.translate(onBorderPos.x, onBorderPos.y);
              this.ctx.scale(scale, scale)
              this.ctx.globalAlpha = this.globalAlpha;
              this.ctx.fillStyle = "orange";
              this.ctx.beginPath();
              this.ctx.arc(0, 0, radius, 0, 2 * Math.PI, false);
              this.ctx.closePath();
              this.ctx.fill();
              // offset
              this.ctx.save();

              const iconFontScale = overlaySettingsModule.iconFontScale ?? 1.0;
              this.ctx.scale(iconFontScale * this.factor / this.scale / scale, iconFontScale * this.factor / this.scale / scale);
              //this.ctx.scale(this.factor / this.scale / scale, this.factor / this.scale / scale);

              this.ctx.globalAlpha = 1.0;
              this.ctx.translate(onBorderPos.labelOffsetX, onBorderPos.labelOffsetY);
              this.ctx.strokeStyle = `#000000ff`;
              this.ctx.fillStyle = "orange";
              this.ctx.fillRect(-23, -8, 46, 16);
              //this.ctx.fillRect(-23, -16, 46, 32);
              this.ctx.font = "12px sans-serif";
              this.ctx.textAlign = "center";
              this.ctx.fillStyle = "white";
              this.ctx.fillText(`${onBorderPos.distance.toFixed(0)}m`, 0, 4);
              //this.ctx.fillText(`100.0%`, 0, -4);
              //this.ctx.fillText(`${onBorderPos.distance.toFixed(0)}m`, 0, 12);

              this.ctx.restore();
            }
            else {
              this.ctx.translate(pos.x, pos.y);
              this.ctx.scale(scale, scale)
              this.ctx.globalAlpha = this.globalAlpha;
              this.ctx.strokeStyle = "orange";
              this.ctx.beginPath();
              this.ctx.arc(0, 0, radius, 0, 2 * Math.PI, false);
              this.ctx.closePath();
              this.ctx.stroke();
            }
          } else {
            const onBorderPos = this.getEdgePosition(a);
            //const onBorderPos = this.getEdgePosition(pos, 25+8);
            if (onBorderPos) {
              this.ctx.translate(onBorderPos.x, onBorderPos.y);
              this.ctx.scale(scale, scale)
              this.ctx.globalAlpha = this.globalAlpha;
              this.ctx.fillStyle = "orange";
              this.ctx.beginPath();
              this.ctx.arc(0, 0, radius, 0, 2 * Math.PI, false);
              this.ctx.closePath();
              this.ctx.fill();
              // offset
              this.ctx.save();

              const iconFontScale = overlaySettingsModule.iconFontScale ?? 1.0;
              this.ctx.scale(iconFontScale * this.factor / this.scale / scale, iconFontScale * this.factor / this.scale / scale);
              //this.ctx.scale(this.factor / this.scale / scale, this.factor / this.scale / scale);

              this.ctx.globalAlpha = 1.0;
              this.ctx.translate(onBorderPos.labelOffsetX, onBorderPos.labelOffsetY);
              this.ctx.strokeStyle = `#000000ff`;
              this.ctx.fillStyle = "orange";
              this.ctx.fillRect(-23, -8, 46, 16);
              //this.ctx.fillRect(-23, -16, 46, 32);
              this.ctx.font = "12px sans-serif";
              this.ctx.textAlign = "center";
              this.ctx.fillStyle = "white";
              this.ctx.fillText(`${onBorderPos.distance.toFixed(0)}m`, 0, 4);
              //this.ctx.fillText(`100.0%`, 0, -4);
              //this.ctx.fillText(`${onBorderPos.distance.toFixed(0)}m`, 0, 12);

              this.ctx.restore();
            }
            else {
              this.ctx.translate(a.x, a.y);
              this.ctx.scale(this.factor / this.scale, this.factor / this.scale);
              this.ctx.globalAlpha = this.globalAlpha;
              this.ctx.drawImage(
                this.icon.report,
                -this.icon.report.width / 2,
                -this.icon.report.height / 2
              );
            }
          }
          this.ctx.restore();
        });
    },

    drawMobPosLocations(locationRecordMap: {[index: number]: LocationRecord}) {
      const drawnIndices: number[] = [];
      Object.values(overlayModule.elites)
        .filter((a) => a.locationIndex >= 0 && !a.lost)
        .forEach((a) => {
          this.ctx.save();

          const scale = Math.max(this.factor / 3 / this.scale, Math.min(1 / 20, 0.2 / this.scale))
          const radius = 10 * 1.3;
          this.ctx.lineWidth = radius * 0.4;

          const mobColors = this.zone.getMobColors(a.mobId);

          const index = a.locationIndex;
          if (index >= 0 && this.zone.isValidLocationIndex(index, settingsModule.displayRanks)) {
            drawnIndices.push(index);
            const pos = this.zone.mobLocations[index];
            this.ctx.translate(pos.x, pos.y);
            this.ctx.scale(scale, scale)
            this.ctx.globalAlpha = this.globalAlpha;
            this.ctx.strokeStyle = mobColors.bgColor;
            this.ctx.beginPath();
            this.ctx.arc(0, 0, radius, 0, 2 * Math.PI, false);
            this.ctx.closePath();
            this.ctx.stroke();
            // instance 番号を表示
            //this.ctx.globalAlpha = this.globalAlpha;
            //this.ctx.font = "20px sans-serif";
            //this.ctx.textAlign = "left";
            //this.ctx.textBaseline = "top";
            //this.ctx.fillStyle = "#330000ff";
            //this.ctx.fillText(`${a.instance}`, 0, 10);
          }
          this.ctx.restore();
        });
      if (!overlaySettingsModule.displayAbandoned) {
        return;
      }
      const zoneId = overlayModule.zoneId;
      //const instance = overlayModule.instance;
      const abandonedMap = worldModule.getEliteAbandonedMap(zoneId, locationRecordMap);
      Object.keys(abandonedMap).map(Number).forEach(mobId => {
        const index = abandonedMap[mobId].index;
        const abandonedAt = new Date(abandonedMap[mobId].abandonedAt);
        if (!this.zone.isValidLocationIndex(index, settingsModule.displayRanks) ||
          drawnIndices.includes(index) ||
          abandonedAt.getTime() + overlaySettingsModule.displayAbandonedMinutes * 60 * 1000 < (new Date()).getTime()) {
          return;
        }
        this.ctx.save();

        const scale = Math.max(this.factor / 3 / this.scale, Math.min(1 / 20, 0.2 / this.scale))
        const radius = 10 * 1.3;
        this.ctx.lineWidth = radius * 0.4;

        const mobColors = this.zone.getMobColors(mobId);
        const loc = this.zone.mobLocations[index];
        this.ctx.translate(loc.x, loc.y);

        this.ctx.save();
        this.ctx.scale(scale, scale)
        this.ctx.globalAlpha = this.globalAlpha;
        this.ctx.strokeStyle = mobColors.bgColor;
        this.ctx.beginPath();
        this.ctx.arc(0, 0, radius, 0, 2 * Math.PI, false);
        this.ctx.closePath();
        this.ctx.stroke();
        this.ctx.restore();

        this.ctx.save();
        this.ctx.scale(this.factor / this.scale, this.factor / this.scale)
        this.ctx.translate(0, -20);
        this.ctx.globalAlpha = 0.7;
        this.ctx.strokeStyle = `#000000ff`;
        this.ctx.fillStyle = mobColors.bgColor;
        this.ctx.fillRect(-25, -8, 50, 16);
        this.ctx.font = "12px sans-serif";
        this.ctx.textAlign = "center";
        this.ctx.fillStyle = "white";
        const fromNow = dayjs(abandonedAt).locale('ja').fromNow();
        this.ctx.fillText(`${fromNow}`, 0, 4);
        this.ctx.restore();

        this.ctx.restore();
      })

    },

    drawMobPos(locationRecordMap: {[index: number]: LocationRecord}) {
      this.zone.mobLocations.forEach(
        (loc, index) => {
          if (loc.type != 'elite') {
            return;
          }
          if (!this.zone.isValidLocationIndex(index, settingsModule.displayRanks)) {
            return;
          }
          this.ctx.save();
          this.ctx.translate(loc.x, loc.y);

          const scale = Math.max(this.factor / 3 / this.scale, Math.min(1 / 20, 0.2 / this.scale)) * 0.8
          this.ctx.scale(scale, scale);

          let radius = 10;
          this.ctx.lineWidth = radius / 20;

          let elite = 0;

          const elites = this.zone.mobs.filter(
            (m: { type: string }) => m.type === "elite"
          );
          if (elites.length === 3) {
            if (
              loc.mobIds.includes(elites[0].id) &&
              this.display.S != undefined
            ) {
              elite |= 16;
            }
            if (
              loc.mobIds.includes(elites[1].id) &&
              this.display.A != undefined
            ) {
              elite |= 8;
            }
            if (
              loc.mobIds.includes(elites[2].id) &&
              this.display.B != undefined
            ) {
              elite |= 2;
            }
          } else {
            //type === 1
            if (
              loc.mobIds.includes(elites[0].id) &&
              this.display.S != undefined
            ) {
              elite |= 16;
            }
            if (
              loc.mobIds.includes(elites[1].id) &&
              this.display.A != undefined
            ) {
              elite |= 8;
            }
            if (
              loc.mobIds.includes(elites[2].id) &&
              this.display.A2 != undefined
            ) {
              elite |= 4;
            }
            if (
              loc.mobIds.includes(elites[3].id) &&
              this.display.B != undefined
            ) {
              elite |= 2;
            }
            if (
              loc.mobIds.includes(elites[4].id) &&
              this.display.B2 != undefined
            ) {
              elite |= 1;
            }
          }
          this.ctx.globalAlpha = this.scale > 2.0 ? 0.5 : 1.0;
          const icon = this.icon.elite[elite];
          this.ctx.drawImage(
            icon,
            -icon.width / 2,
            -icon.height / 2
          );
          if (overlaySettingsModule.narrowDown) {
            // 0: no check, 1: green check, 2, grey check
            //const record = worldModule.getLocationRecord(zoneId, instance, index);
            const record = locationRecordMap[index];
            if (record) {
              const flag = record.flag;
              if (flag === 2 || flag === 3) {
                const check = this.icon.check[flag];
                this.ctx.globalAlpha = 1.0;
                this.ctx.drawImage(
                  check,
                  -check.width / 2,
                  -check.height / 2
                );
              }
            }

          }
          this.ctx.restore();
        }
      );
    },

    drawRebellion() {
      if (settingsModule.display.SS != 0) {
        return;
      }
      const scale = Math.max(this.factor / 3 / this.scale, Math.min(1 / 20, 0.2 / this.scale)) * 0.8;
      const sss = this.zone.mobs.filter(
        (m: { type: string }) => m.type === "ss"
      );
      if (sss.length < 2) {
        return;
      }
      const ssId = sss[0].id;
      const sbId = sss[1].id;
      this.zone.mobLocations.forEach(
        (loc) => {
          if (loc.type != 'ss') {
            return;
          }
          const icon =
            loc.mobIds.includes(ssId) ? this.icon.ss :
              loc.mobIds.includes(sbId) ? this.icon.sb : undefined;
          if (!icon) {
            return;
          }

          this.ctx.save();
          this.ctx.translate(loc.x, loc.y);
          this.ctx.scale(scale, scale);
          this.ctx.drawImage(
            icon,
            -icon.width / 2,
            -icon.height / 2
          );
          this.ctx.restore();
        });
    },

    drawTargets() {
      this.targets.forEach((m) => {
        this.ctx.save();
        const scale = Math.max(this.factor / 3 / this.scale, Math.min(1 / 20, 0.2 / this.scale))
        const radius = 10 * 1.3;
        this.ctx.lineWidth = radius * 0.5;

        const mobColors = this.zone.getMobColors(m.bNpcNameId);

        const pos = m;
        //const onBorderPos = this.getEdgePosition(pos);
        const onBorderPos = this.getEdgePosition(pos, 25 + 8);
        if (onBorderPos) {
          this.ctx.translate(onBorderPos.x, onBorderPos.y);
          this.ctx.scale(scale, scale)
          this.ctx.globalAlpha = this.globalAlpha;
          this.ctx.fillStyle = mobColors.bgColor;
          this.ctx.beginPath();
          this.ctx.arc(0, 0, radius, 0, 2 * Math.PI, false);
          this.ctx.closePath();
          this.ctx.fill();
          // offset
          this.ctx.save();

          const iconFontScale = overlaySettingsModule.iconFontScale ?? 1.0;
          this.ctx.scale(iconFontScale * this.factor / this.scale / scale, iconFontScale * this.factor / this.scale / scale);
          //this.ctx.scale(this.factor / this.scale / scale, this.factor / this.scale / scale);

          this.ctx.globalAlpha = 1.0;
          this.ctx.translate(onBorderPos.labelOffsetX, onBorderPos.labelOffsetY);
          this.ctx.strokeStyle = `#000000ff`;
          this.ctx.fillStyle = mobColors.bgColor;
          //this.ctx.fillRect(-23, -8, 46, 16);
          this.ctx.fillRect(-23, -16, 46, 32);
          this.ctx.font = "12px sans-serif";
          this.ctx.textAlign = "center";
          this.ctx.fillStyle = "white";
          // this.ctx.fillText(`${onBorderPos.distance.toFixed(0)}m`, 0, 4);
          this.ctx.fillText(`${onBorderPos.distance.toFixed(0)}m`, 0, -4);
          this.ctx.fillText(`${(m.hpp * 100).toFixed(1)}%`, 0, 12);

          this.ctx.restore();
        }
        else {
          this.ctx.translate(m.x, m.y);
          this.ctx.scale(this.factor / this.scale, this.factor / this.scale);
          this.ctx.globalAlpha = this.globalAlpha;
          this.ctx.drawImage(
            this.icon.enemy,
            -this.icon.enemy.width / 2,
            -this.icon.enemy.height / 2
          );
          this.ctx.globalAlpha = 1.0;
          const iconFontScale = overlaySettingsModule.iconFontScale ?? 1.0;
          this.ctx.scale(iconFontScale, iconFontScale);
          this.ctx.translate(0.0, -20.0);
          // this.ctx.scale(this.factor / this.scale / scale, this.factor / this.scale / scale);
          this.ctx.strokeStyle = `#000000ff`;
          this.ctx.fillStyle = mobColors.bgColor;
          this.ctx.fillRect(-23, -8, 46, 16);
          this.ctx.font = "12px sans-serif";
          this.ctx.textAlign = "center";
          this.ctx.fillStyle = "white";
          this.ctx.fillText(`${(m.hpp * 100).toFixed(1)}%`, 0, 4);
          if (this.scale < 2.0) {
            this.ctx.fillStyle = mobColors.bgColor;
            this.ctx.fillRect(-23, -24, 46, 16);
            const distance = Math.sqrt((this.self.x - m.x) * (this.self.x - m.x) * 2500 + (this.self.y - m.y) * (this.self.y - m.y) * 2500);
            this.ctx.fillStyle = "white";
            if (distance < 100) {
              this.ctx.save();
              this.ctx.translate(-12, -16);
              const atan = Math.atan2(m.x - this.self.x, this.self.y - m.y);
              this.ctx.rotate(atan);
              this.ctx.beginPath();
              this.ctx.moveTo(-2, 6);
              this.ctx.lineTo(-2, 0);
              this.ctx.lineTo(-6, 0);
              this.ctx.lineTo(0, -6);
              this.ctx.lineTo(6, 0);
              this.ctx.lineTo(2, 0);
              this.ctx.lineTo(2, 6);
              this.ctx.closePath();
              this.ctx.fill();
              this.ctx.restore();
              this.ctx.fillText(`${distance.toFixed(0)}m`, 8, -12);
            }
            else {
              this.ctx.fillText(`${distance.toFixed(0)}m`, 0, -12);
            }
          }
        }
        this.ctx.restore();
      });
    },

    drawPlayer() {
      this.ctx.save();

      this.ctx.translate(this.self.x, this.self.y);
      this.ctx.rotate(Math.PI - this.self.heading);

      this.ctx.beginPath();
      this.ctx.fillStyle = "rgba(192, 255, 192, 0.3)";
      //this.ctx.arc(0, 0, 16 * this.factor, Math.PI + 0.8, -0.8);
      this.ctx.arc(0, 0, 2.2, Math.PI + 0.8, -0.8);
      this.ctx.lineTo(0, 0);
      this.ctx.fill();
      this.ctx.closePath();

      this.ctx.scale(this.factor / this.scale, this.factor / this.scale);
      this.ctx.drawImage(
        this.icon.cursor,
        -this.icon.cursor.width / 2,
        -this.icon.cursor.height / 2
      );
      this.ctx.restore();
    },

    drawParty() {
      this.party.forEach(
        (m: { x: number; y: number }) => {
          this.ctx.save();
          this.ctx.translate(m.x, m.y);

          this.ctx.scale(this.factor / this.scale, this.factor / this.scale);

          this.ctx.drawImage(
            this.icon.party,
            -this.icon.party.width / 2,
            -this.icon.party.height / 2
          );
          this.ctx.restore();
        });
    },

    async drawCanvas() {
      this.icon.cursor = await this.loadImage(urlCursor);
      this.icon.party = await this.loadImage(urlParty);
      this.icon.enemy = await this.loadImage(urlEnemy);
      this.icon.enemyMini = await this.loadImage(urlEnemyMini);
      this.icon.aetheryte = await this.loadImage(urlAetheryte);
      this.icon.ship = await this.loadImage(urlShip);
      this.icon.gate = await this.loadImage(urlGate);
      this.icon.report = await this.loadImage(urlReport);
      this.icon.up = await this.loadImage(urlUp);
      this.icon.down = await this.loadImage(urlDown);
      this.icon.ss = await this.loadImage(urlSS);
      this.icon.sb = await this.loadImage(urlSB);
      this.icon.elite[0b00001] = await this.loadImage(url00001);
      this.icon.elite[0b00010] = await this.loadImage(url00010);
      this.icon.elite[0b00011] = await this.loadImage(url00011);
      this.icon.elite[0b00100] = await this.loadImage(url00100);
      this.icon.elite[0b00101] = await this.loadImage(url00101);
      this.icon.elite[0b00110] = await this.loadImage(url00110);
      this.icon.elite[0b00111] = await this.loadImage(url00111);
      this.icon.elite[0b01000] = await this.loadImage(url01000);
      this.icon.elite[0b01001] = await this.loadImage(url01001);
      this.icon.elite[0b01010] = await this.loadImage(url01010);
      this.icon.elite[0b01011] = await this.loadImage(url01011);
      this.icon.elite[0b01100] = await this.loadImage(url01100);
      this.icon.elite[0b01101] = await this.loadImage(url01101);
      this.icon.elite[0b01110] = await this.loadImage(url01110);
      this.icon.elite[0b01111] = await this.loadImage(url01111);
      this.icon.elite[0b10000] = await this.loadImage(url10000);
      this.icon.elite[0b10001] = await this.loadImage(url10001);
      this.icon.elite[0b10010] = await this.loadImage(url10010);
      this.icon.elite[0b10011] = await this.loadImage(url10011);
      this.icon.elite[0b10100] = await this.loadImage(url10100);
      this.icon.elite[0b10101] = await this.loadImage(url10101);
      this.icon.elite[0b10110] = await this.loadImage(url10110);
      this.icon.elite[0b10111] = await this.loadImage(url10111);
      this.icon.elite[0b11000] = await this.loadImage(url11000);
      this.icon.elite[0b11001] = await this.loadImage(url11001);
      this.icon.elite[0b11010] = await this.loadImage(url11010);
      this.icon.elite[0b11011] = await this.loadImage(url11011);
      this.icon.elite[0b11100] = await this.loadImage(url11100);
      this.icon.elite[0b11101] = await this.loadImage(url11101);
      this.icon.elite[0b11110] = await this.loadImage(url11110);
      this.icon.elite[0b11111] = await this.loadImage(url11111);
      this.icon.check[1] = await this.loadImage(urlcheck1);
      this.icon.check[2] = await this.loadImage(urlcheck2);
      this.icon.check[3] = await this.loadImage(urlcheck3);

      this.mapImage = (await this.loadImage(
        `https://res.cloudinary.com/lanaklein14/image/upload/v1624232409/small/${this.zone.id}.jpg`
      ).catch((e) => {
        console.error("onload error", e);
      })) as HTMLImageElement;

      this.mapImage2 = (await this.loadImage(
        `https://res.cloudinary.com/lanaklein14/image/upload/e_grayscale/co_white,e_make_transparent:45/co_rgb:bbf2ef,e_colorize:100/v1624233477/small/${this.zone.id}.png`
      ).catch((e) => {
        console.error("onload error", e);
      })) as HTMLImageElement;

      if (this.canvas) {
        const cvs = this.canvas;
        const ctx = this.canvas.getContext("2d") as CanvasRenderingContext2D;

        let animation = (timestamp: number) => {

          this.debug.frame++;
          if (timestamp > this.debug.startTimeStamp + 1000) {
            // duration には、1000msのうち、何ms消費していたかが入る
            // fpsが31以上出ていて、total.duration が100ms以下なら許容範囲
            // fpsが30を切り始めるとよくない
            Object.values(this.debug.performance).forEach(value => {
              value.duration = value.stack;
              value.stack = 0;
            });
            this.debug.startTimeStamp = timestamp;
            this.debug.fps = this.debug.frame;
            this.debug.frame = 0;
          }
          this.globalAlpha = this.getAlphaOfTimestamp(timestamp, 0.7);
          let maxWidth = document.documentElement.clientWidth;
          this.canvas.width = maxWidth;
          cvs.height = cvs.width;
          if (ctx && this.self) {
            ctx.fillStyle = "rgba(0, 0, 0, 0.01)";
            ctx.clearRect(0, 0, cvs.width, cvs.height);
            ctx.fillRect(0, 0, cvs.width, cvs.height);

            const scalec2a = cvs.width / this.zone.scale.xRange;
            //const scalea2i = this.zone.scale.xRange / this.mapImage.width;
            const prefferedX = this.zone.scale.xRange / (2.0 * this.scale);
            const prefferedY = this.zone.scale.yRange / (2.0 * this.scale);
            const offsetLeft = -this.zone.scale.xMin + this.self.x;
            const offsetRight =
              this.zone.scale.xRange / this.scale -
              (this.zone.scale.xMax - this.self.x);
            const offsetTop = -this.zone.scale.yMin + this.self.y;
            const offsetBottom =
              this.zone.scale.xRange / this.scale -
              (this.zone.scale.yMax - this.self.y);

            ctx.save();

            ctx.scale(scalec2a, scalec2a);
            ctx.scale(this.scale, this.scale);

            ctx.translate(1.0 - this.self.x, 1.0 - this.self.y);
            ctx.translate(
              prefferedX < offsetLeft
                ? Math.max(prefferedX, offsetRight)
                : offsetLeft,
              prefferedY < offsetTop
                ? Math.max(prefferedY, offsetBottom)
                : offsetTop
            );

            const scale = this.zone.scale;
            ctx.translate(-scale.xMin, -scale.yMin);
            performance.mark('drawTotal:start');
            performance.mark('drawMap:start');
            this.drawImage();
            performance.mark('drawMap:end');
            performance.mark('drawDetection:start');

            ctx.fillStyle =
              overlaySettingsModule.mapTransparent
                ? "rgba(200, 200, 255, 0.5)"
                : "rgba(0, 0, 255, 0.25)";
            ctx.fillRect(this.self.x - 2.2, this.self.y - 2.2, 4.4, 4.4);

            performance.mark('drawDetection:end');
            performance.mark('drawSymbols:start');
            this.drawSymbols();
            performance.mark('drawSymbols:end');
            performance.mark('drawAetherytes:start');
            this.drawAetherytes();
            performance.mark('drawAetherytes:end');
            performance.mark('drawMobPos:start');
            const zoneId = overlayModule.zoneId;
            const instance = overlayModule.instance;
            let recordsMap: { [index: number]: LocationRecord } = {};
            if (overlaySettingsModule.narrowDown) {
              recordsMap = worldModule.getLocationRecordMap(zoneId, instance);
            }
            this.drawMobPos(recordsMap);
            performance.mark('drawMobPos:end');
            performance.mark('drawRebellion:start');
            this.drawRebellion();
            performance.mark('drawRebellion:end');
            performance.mark('drawMobPosLocations:start');
            this.drawMobPosLocations(recordsMap);
            performance.mark('drawMobPosLocations:end');
            performance.mark('drawNotifiedLocations:start');
            this.drawNotifiedLocations();
            performance.mark('drawNotifiedLocations:end');
            performance.mark('drawParty:start');
            this.drawParty();
            performance.mark('drawParty:end');
            //this.drawLines();
            //this.drawBorders();


            performance.mark('drawSelf:start');
            this.drawPlayer();
            performance.mark('drawSelf:end');

            performance.mark('drawTargets:start');
            this.drawTargets();
            performance.mark('drawTargets:end');

            ctx.restore();

            performance.mark('drawTotal:end');


            Object.keys(this.debug.performance).forEach(key => {
              performance.measure(`${key}`, `${key}:start`, `${key}:end`);
              this.debug.performance[key].stack += performance.getEntriesByName(key)[0].duration;
            });
            performance.clearMarks();
            performance.clearMeasures();

          } else {
            cvs.height = 0;
          }

          if (this.zone.id != 0) {
            window.requestAnimationFrame((timestamp) => animation(timestamp));
          } else {
            cvs.height = 0;
          }
        };
        window.requestAnimationFrame((timestamp) => animation(timestamp));
      }
    },
  },
});
