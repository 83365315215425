


































































































































import Vue from "vue";
import { DataTableHeader } from "vuetify";
import EditGroup, { IMethods } from "@/components/EditGroup.vue"
import { IGuild } from "../../../src/interfaces/discord";
import { IGroupAddPayload, IGroupPayload } from "../../../src/interfaces/groups"
import { addGroup, deleteGroup, getGroups, getGuilds } from "@/libs/serverProxy";
import { userModule } from "@/store/modules/user";

interface IData {
  headers: DataTableHeader[];
  items: IGroupPayload[];
  newGroupName: string;
  newGuildId: string;
  guildItems: IGuild[];
  addDialog: boolean;
  deleteDialog: boolean;
  expanded: IGroupPayload[];
  deleteGroupId: string;
}

export default Vue.extend({
  name: 'ManageGroups',
  components: {
    EditGroup
  },
  data(): IData {
    return {
      headers: [
        { text: this.$t('groupName') as string, value: 'name' },
        { text: this.$t('guildName') as string, value: 'guildName' },
        { text: this.$t('ownerName') as string, value: 'ownerName' },
        { text: this.$t('actions') as string, value: 'data-table-expand', width: "100px", sortable: false },
      ],
      items: [],
      newGroupName: "",
      newGuildId: "",
      guildItems: [],
      addDialog: false,
      deleteDialog: false,
      expanded: [],
      deleteGroupId: "",
    };
  },
  computed: {
    isGroupValid(): boolean {
      return this.newGroupName != "" && this.newGuildId != "";
    },
  },
  watch: {
  },
  mounted() {
    this.load()
  },
  methods: {
    isOwner(item: IGroupPayload) {
      return userModule.type == "discord" && item.ownerId == userModule.userId
    },

    async loadDetails(row: { item: IGroupPayload, value: boolean }) {
      if (row.value) {
        this.$nextTick(async function () {
          const editGroup = this.$refs.editGroup as Vue & IMethods;
          if (editGroup) {
            await editGroup.load()
          }
        });
      }
    },

    async load() {
      this.guildItems = await getGuilds();
      this.items = await getGroups();
    },

    async addGroup() {
      const payload: IGroupAddPayload = {
        name: this.newGroupName,
        ownerId: userModule.userId,
        guildId: this.newGuildId,
        roleId: "",
        channelId: "",
        logChannelId: "",
        description: "",
        activeWorldIds: []
      }
      const result = await addGroup(payload)

      const guild = this.guildItems.find(guild => guild.id == this.newGuildId);
      const guildName = guild ? guild.name : `Unknown<${this.newGuildId}>`

      const group: IGroupPayload = {
        id: result.id,
        name: this.newGroupName,
        ownerId: userModule.userId,
        ownerName: userModule.userName,
        guildName,
        roleName: "",
      };
      this.items.push(group);
      this.closeAddDialog();
    },

    closeAddDialog() {
      this.newGuildId = "";
      this.newGroupName = "";
      this.addDialog = false;
    },

    deleteGroup(item: IGroupPayload) {
      this.deleteGroupId = item.id;
      this.deleteDialog = true;
    },

    async deleteItemConfirm() {
      await deleteGroup(this.deleteGroupId);
      const index = this.items.findIndex(item => item.id == this.deleteGroupId);
      if (index >= 0) {
        this.items.splice(index, 1);
      }
      this.closeDelete();
    },

    closeDelete() {
      this.deleteDialog = false;
      this.$nextTick(() => {
        this.deleteGroupId = "";
      })
    },

    updateTable(payload: IGroupPayload) {
      const index = this.items.findIndex(item => item.id == payload.id)
      if (index >= 0) {
        Vue.set(this.items, index, payload);
      }
    },

  }

});
