



























































































import { getMobTimeOfDeath, getMobTimeOfDeaths, registerHuntLog, unregisterHuntLog } from "@/libs/serverProxy";
import dayjs from "dayjs";
import { getLang, messages, worlds, zones } from "ffxivhuntdata";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import { IMobTimeOfDeathPayload } from "../../../src/interfaces/mobtods";
import WorldFilter from "@/components/WorldFilter.vue"
import { settingsModule } from "@/store/modules/settings";

interface IMobColumn {
  name: string,
  color: string,
  rank: string
}

interface IMobTimeOfDeathReportRow {
  userName: string;
  avatarUrl: string;
  reportedAt: string;
}

interface IMobTimeOfDeathRow {
  id: string;
  tod: string;
  world: string;
  mob: IMobColumn;
  location: string;
  linked: boolean;
  reportsCount: number;
  reports: IMobTimeOfDeathReportRow[];
}

interface IData {
  headers: DataTableHeader[];
  headers2: DataTableHeader[];
  items: IMobTimeOfDeathPayload[];
  expanded: IMobTimeOfDeathRow[],
}

export default Vue.extend({
  name: 'MobTimeOfDeaths',
  components: {
    WorldFilter
  },
  data(): IData {
    return {
      headers: [
        { text: this.$t('tod') as string, value: 'tod' },
        { text: this.$t('world') as string, value: 'world' },
        { text: this.$t('mob') as string, value: 'mob' },
        { text: this.$t('location') as string, value: 'location' },
        { text: this.$t('reports') as string, value: 'data-table-expand' },
        { text: this.$t('huntNet') as string, value: 'actions', sortable: false },
      ],
      headers2: [
        { text: this.$t('reportedAt') as string, value: 'reportedAt' },
        { text: this.$t('reporter') as string, value: 'reporter' }
      ],
      items: [],
      expanded: [],
    };
  },
  computed: {
    computedItems(): IMobTimeOfDeathRow[] {
      return this.items.map((item: IMobTimeOfDeathPayload) => {
        const world = worlds.find(world => world.id === item.worldId);
        let location = `Unknown Zone(${item.zoneId})`;
        let mob = {
          name: `Unknown Mob(${item.mobId})`,
          color: "#000000",
          rank: "-"
        };
        const zone = zones.find(zone => zone.id === item.zoneId);
        if (zone) {
          location = `${messages[getLang()].zone[item.zoneId]}${item.instance}`;
          if (item.index >= 0) {
            const loc = zone.mobLocations[item.index];
            location += `(${loc.x.toFixed(1)}, ${loc.y.toFixed(1)})`
          }
          const mobColor = zone.getMobColors(item.mobId);
          mob = {
            name: mobColor.mob ? mobColor.mob.name : `Unknown Mob(${item.mobId})`,
            color: mobColor.color,
            rank: mobColor.mob ? mobColor.mob.rank : `-`
          }
        }
        const result = {
          id: item.id,
          tod: dayjs(item.reportedAt).format('MM/DD(ddd) HH:mm'),
          world: world ? world.name : `Unknown World(${item.worldId})`,
          mob,
          location,
          linked: item.huntLogId !== "",
          reportsCount: item.reportsCount,
          reports: item.reports.map(report => {
            return {
              userName: report.userName != "" ? report.userName : this.$t('standaloneUser') as string,
              avatarUrl: report.avatarUrl != "" ? report.avatarUrl : "./defaultUser.png",
              reportedAt: dayjs(report.reportedAt).format('MM/DD(ddd) HH:mm'),
            }
          })
        }
        return result;
      })
    },
    worldId: {
      get(): number {
        return settingsModule.worldFilter;
      },
      set(value: number): void {
        settingsModule.SET_WORLD_FILTER(value)
      }
    }
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      const data = await getMobTimeOfDeaths(this.worldId);
      this.items = data;
    },
    async loadDetails(row: { item: IMobTimeOfDeathRow, value: boolean }) {
      if (row.value && row.item.reportsCount != row.item.reports.length) {
        const index = this.computedItems.indexOf(row.item);
        this.$nextTick(async () => {
          const payload = await getMobTimeOfDeath(row.item.id);
          Vue.set(this.items, index, payload);
        });
      }
    },
    async registerHuntLog(id: string, index: number) {
      this.$nextTick(async () => {
        const payload = await registerHuntLog(id);
        Vue.set(this.items, index, payload);
      });
    },
    async unregisterHuntLog(id: string, index: number) {
      this.$nextTick(async () => {
        const payload = await unregisterHuntLog(id);
        Vue.set(this.items, index, payload);
      });
    },
  },
  watch: {
    worldId: async function () {
      this.load();
    }
  }


});
