import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {setLang} from 'ffxivhuntdata';
Vue.use(VueI18n);

import ja from "./ja.json";
import en from "./en.json";
import dayjs from 'dayjs';

let locale = "en"
const language = window.navigator.language;
if (language) {
    const lang = language.toLowerCase().split("-");
    switch (lang[0]) {
        case "ja":
            locale = lang[0];
            break;
    }
}

setLang(locale);
dayjs.locale(locale);

export const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: "en",
    messages: {
        ja: ja,
        en: en
    },
    silentTranslationWarn: true
})
