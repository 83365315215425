import { Mutation, VuexModule, getModule, Module } from "vuex-module-decorators";
import { canPreserveDefault, store } from "@/store";
import Vue from "vue";
import { zones } from "ffxivhuntdata";

// state's interface
export interface ISettingsState {
  vrs: string,
  mapCustomInput: boolean;
  showAllZones: boolean;
  serverSettingsTab: string;
  display: {
    S: number;
    A: number;
    B: number;
    A2: number;
    B2: number;
    SS: number;
  };
  preferredGroup: string;
  displayMobMap: { [worldId: number]: string[] }
  worldFilter: number;
  weathersZoneId: number;
  weathersSmobFilter: boolean;
  mobLocationsZoneId: number;
}

const name = 'settings';
const vrs = '0.3';

/*
try {
  store.unregisterModule(name)
} catch (error) {
  console.warn(`ignore ${error}`)
}
*/

@Module({ dynamic: true, store, name, namespaced: true, preserveState: canPreserveDefault(name, vrs) })
class SettingsState extends VuexModule implements ISettingsState {
  // state
  vrs = vrs;
  mapCustomInput = false;
  mapShowLabel = false;
  showAllZones = false;
  serverSettingsTab = "tabServerResets";
  display = {
    S: 0,
    A: 0,
    B: 0,
    A2: 0,
    B2: 0,
    SS: 0,
  };
  preferredGroup = "";
  displayMobMap: { [worldId: number]: string[] } = {};
  worldFilter = 90;
  weathersZoneId = 956;
  weathersSmobFilter = false;
  mobLocationsZoneId = 956;


  // getter with params
  public get displayMobMaps() {
    return (worldId: number): string[] => {
      const zoneInstances = this.displayMobMap[worldId];
      if (zoneInstances) {
        return zoneInstances;
      }
      return [];
    }
  }

  public get displayRanks(): string[] {
    return Object.entries(this.display).filter(([, value])=>value==0).map(([key,])=>key);
  }

  // mutation
  @Mutation
  public SET_MAP_CUSTOM_INPUT(value: boolean) {
    this.mapCustomInput = value;
  }
  @Mutation
  public SET_MAP_SHOW_LABEL(value: boolean) {
    this.mapShowLabel = value;
  }
  @Mutation
  public SET_SHOW_ALL_ZONES(value: boolean) {
    this.showAllZones = value;
  }
  @Mutation
  public SET_SERVER_SETTINGS_TAB(value: string) {
    this.serverSettingsTab = value;
  }
  @Mutation
  public SET_PREFERRED_GROUP(value: string) {
    this.preferredGroup = value;
  }
  @Mutation
  public SET_WORLD_FILTER(value: number) {
    this.worldFilter = value;
  }
  @Mutation
  public SET_WEATHERS_ZONE_ID(value: number) {
    this.weathersZoneId = value;
  }
  @Mutation
  public SET_WEATHERS_SMOB_FILTER(value: boolean) {
    this.weathersSmobFilter = value;
  }
  @Mutation
  public SET_MOB_LOCATIONS_ZONE_ID(value: number) {
    this.mobLocationsZoneId = value;
  }

  @Mutation
  public SET_DISPLAY_MOBMAPS(payload: { worldId: number, zoneId: number, instance: number }) {
    Vue.set(this, "displayMobMap", {});
    if (zones.map(zone=>zone.id).includes(payload.zoneId)) {
      const zoneInstances = payload.instance > 0 ? [`${payload.zoneId}_${payload.instance}`] : []
      Vue.set(this.displayMobMap, payload.worldId, zoneInstances);
    }
  }

  @Mutation
  public ADD_DISPLAY_MOBMAP(payload: { worldId: number, zoneInstance: string }) {
    if (!this.displayMobMap[payload.worldId]) {
      Vue.set(this.displayMobMap, payload.worldId, [])
    }
    this.displayMobMap[payload.worldId].push(payload.zoneInstance)
    this.displayMobMap[payload.worldId] = [...new Set(this.displayMobMap[payload.worldId])]; // unique

  }
  @Mutation
  public DELETE_DISPLAY_MOBMAP(payload: { worldId: number, zoneInstance: string }) {
    const zoneInstances = this.displayMobMap[payload.worldId]
    if (zoneInstances) {
      Vue.set(this.displayMobMap, payload.worldId, zoneInstances.filter(zoneInstance => !(zoneInstance == payload.zoneInstance)));
    }
    if (this.displayMobMap[payload.worldId] && this.displayMobMap[payload.worldId].length == 0) {
      Vue.delete(this.displayMobMap, payload.worldId)
    }
  }

  @Mutation
  public SET_DISPLAY_S(value: number) {
    Vue.set(this.display, 'S', value);
  }
  @Mutation
  public SET_DISPLAY_A(value: number) {
    Vue.set(this.display, 'A', value);
  }
  @Mutation
  public SET_DISPLAY_B(value: number) {
    Vue.set(this.display, 'B', value);
  }
  @Mutation
  public SET_DISPLAY_A2(value: number) {
    Vue.set(this.display, 'A2', value);
  }
  @Mutation
  public SET_DISPLAY_B2(value: number) {
    Vue.set(this.display, 'B2', value);
  }
  @Mutation
  public SET_DISPLAY_SS(value: number) {
    Vue.set(this.display, 'SS', value);
  }
}

export const settingsModule = getModule(SettingsState);