




import Vue from 'vue'
import urlAetheryte from "@/assets/images/060453.png";
import urlReport from "@/assets/images/060561.png";

import { IZone, zones } from "ffxivhuntdata";
import { IMapLocation } from '@/libs/centurionEvents';
import { overlaySettingsModule } from '@/store/modules/overlaySettings';
import { overlayModule } from '@/store/modules/overlay';


interface Data {
  canvas?: HTMLCanvasElement;
  startTime: number;
}

export default Vue.extend({
  name: "MapLocationCanvas",
  props: {
//    location: Object as PropType<IMapLocation>
  },
  data(): Data {
    return {
      canvas: undefined,
      startTime: 0
    };
  },
  mounted() {
    this.canvas = this.$el.querySelector("canvas") as HTMLCanvasElement;
    this.draw();
  },
  watch: {
    lastNotifiedLocation() {
      this.draw();
    },
  },
  computed: {
    lastNotifiedLocation(): IMapLocation {
      return overlayModule.lastNotifiedLocation;
    }
  },
  methods: {
    async loadImage(src: string): Promise<HTMLImageElement> {
      return await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    },
    drawAetherytes(
      ctx: CanvasRenderingContext2D,
      scale: number,
      aetherytes: { x: number; y: number }[],
      image: HTMLImageElement
    ) {
      aetherytes.forEach(a => {
        ctx.save();
        ctx.translate(a.x, a.y);
        ctx.scale(scale, scale);
        ctx.drawImage(
          image,
          -image.width / 2,
          -image.height / 2
        );
        ctx.restore();
      });
    },
    animation(ctx: CanvasRenderingContext2D, zone: IZone, img: HTMLImageElement, icon: { aetheryte: HTMLImageElement, report: HTMLImageElement }) {
      const cvs = this.canvas as HTMLCanvasElement;
      ctx.clearRect(0, 0, cvs.width, cvs.height);
      ctx.fillStyle = "rgba(255, 0, 255, 0.5)";
      ctx.fillRect(0, 0, cvs.width, cvs.height);

      const scalec2a = cvs.width / zone.scale.xRange;
      const scalea2i = zone.scale.xRange / img.width;
      ctx.save();

      ctx.scale(scalec2a, scalec2a);

      ctx.save();
      ctx.scale(scalea2i, scalea2i);
      ctx.drawImage(img, 0, 0);
      ctx.restore();

      ctx.translate(-zone.scale.xMin, -zone.scale.yMin);

      this.drawAetherytes(ctx, scalea2i * 2, zone.aetherytes, icon.aetheryte);

      ctx.save();
      ctx.translate(this.lastNotifiedLocation.x, this.lastNotifiedLocation.y);
      ctx.scale(scalea2i * 2, scalea2i * 2);
      ctx.drawImage(
        icon.report,
        -icon.report.width / 2,
        -icon.report.height / 2
      );
      ctx.restore();

      ctx.restore();

      const div = this.$el as HTMLElement;
      if (this.startTime + overlaySettingsModule.posNotifyPopupSeconds * 1000 < (new Date()).getTime()) {
        div.style.opacity = (div.style.opacity as unknown as number - 0.03).toString();
      }
      if (div.style.opacity as unknown as number > 0.0) {
        window.requestAnimationFrame(() => this.animation(ctx, zone, img, icon));
      }
      else {
        cvs.height = 0;
      }
    },

    async draw() {

      if (!this.canvas) return;
      const zone = zones.find(z => z.id === this.lastNotifiedLocation.zoneId);
      if (!zone) return;

      this.canvas.height = this.canvas.width;
      let ctx = this.canvas.getContext("2d") as CanvasRenderingContext2D;

      if (ctx != null) {
        const icon = {
          aetheryte: await this.loadImage(urlAetheryte),
          report: await this.loadImage(urlReport),
        };
        const img = (await this.loadImage(
          `https://res.cloudinary.com/lanaklein14/image/upload/v1624232409/small/${zone.id}.jpg`
        ).catch((e) => {
          console.error("onload error", e);
        })) as HTMLImageElement;
        this.startTime = (new Date()).getTime();
        const div = this.$el as HTMLElement;
        div.style.opacity = "1.0";
        window.requestAnimationFrame(() => this.animation(ctx, zone, img, icon));
      }
    }
  },

})
