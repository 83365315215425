


























import Vue from "vue";
import dayjs from 'dayjs'
import { EODate } from 'ffxivhuntdata'

interface Data {
  now: Date
}

export default Vue.extend({
  name: 'EorzeaClock',
  data(): Data {
    return {
      now: new Date()
    };
  },
  created() {
    this.startClock()
  },
  computed: {
    nowLTString(): string {
      return `${dayjs(this.now).format('HH:mm')}`;
    },
    nowETString(): string {
      return new EODate(this.now).toTimeString();
    }
  },
  methods: {
    startClock() {
      let loop = () => {
        const current = new Date()
        if (Math.floor(this.now.getTime() / 1000) < Math.floor(current.getTime() / 1000)) {
          this.now = current;
        }
        window.requestAnimationFrame(() => loop());
      };
      window.requestAnimationFrame(() => loop());
    }
  }
})
