var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.computedItems,"dense":"","hide-default-footer":"","disable-pagination":"","mobile-breakpoint":"0","single-expand":"","expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.loadDetails},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("mobTimeOfDeath")))]),_c('v-spacer'),_c('world-filter',{model:{value:(_vm.worldId),callback:function ($$v) {_vm.worldId=$$v},expression:"worldId"}})],1)]},proxy:true},{key:"item.mob",fn:function(ref){
var item = ref.item;
return [(item.mob.rank != '')?_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","color":item.mob.color,"text-color":"white"}},[_vm._v(_vm._s(item.mob.rank))]):_vm._e(),_vm._v(_vm._s(item.mob.name)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-3",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"pa-1"},[_c('v-data-table',{attrs:{"headers":_vm.headers2,"items":item.reports,"dense":"","hide-default-footer":"","disable-pagination":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.reporter",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"30"}},[_c('img',{attrs:{"src":item.avatarUrl}})]),_vm._v(" "+_vm._s(item.userName)+" ")]}}],null,true)})],1)],1)]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var item = ref.item;
return [_c('span',[_c('v-icon',{on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v("mdi-account")]),_vm._v(_vm._s(item.reportsCount))],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.mob.rank != 'B')?_c('span',[(!item.linked)?_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"blue-grey"},on:{"click":function($event){return _vm.registerHuntLog(item.id, index)}}},[_vm._v(" "+_vm._s(_vm.$t("register"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-link-off ")])],1):_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"blue-grey"},on:{"click":function($event){return _vm.unregisterHuntLog(item.id, index)}}},[_vm._v(" "+_vm._s(_vm.$t("unregister"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-link ")])],1)],1):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("thereMobTimeOfDeath"))+" ")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }