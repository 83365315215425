import {
  Mutation,
  VuexModule,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { store, canPreserveDefault } from "@/store";
import { getLang, IZone, messages, worlds, zones } from "ffxivhuntdata";
import { ISMobRecord } from "@/libs/serverProxy";
import { smobrecordsModule } from "./smobrecords";
import { fieldinstancesModule } from "./fieldinstances";
import {
  IIndexRecordMap,
  locationrecordsModule,
  RecordState,
} from "./locationrecords";
import dayjs from "dayjs";
import { settingsModule } from "./settings";
import { client } from "@/libs/GroupClient";
import { userModule } from "./user";
import { MobReport, sharelocationrecordsModule } from "./sharelocationrecords";
import { sTimeTableViewModule } from "./sTimeTableView";
import { locationabsenceModule } from "./locationabsence";
import Vue from "vue";

export class LocationRecord {
  reports: MobReport2[];
  smobInfo?: IZoneInstanceSMobInfo;
  constructor(reports: MobReport2[], smobInfo?: IZoneInstanceSMobInfo) {
    this.reports = reports;
    this.smobInfo = smobInfo;
  }
  get myReport(): RecordState | undefined {
    const report = this.reports.find(
      (report) => report.isSelf && report.state !== 2000
    );
    return report
      ? {
        date: report.reportedAt.toISOString(),
        mobId: report.mobId,
        state: report.state,
      }
      : undefined;
  }
  get flag(): number {
    // flag 0: CENTRION report only
    // flag 1: no valid record
    // flag 2: some valid records by others
    // flag 3: has valid record by you
    const reportsWithout = this.reports.filter(
      (report) => report.state !== 2000
    );
    let flag = reportsWithout.length > 1 ? 1 : 0;
    const times = reportsWithout.map((report) =>
      new Date(report.reportedAt).getTime()
    );
    if (this.smobInfo) {
      if (this.smobInfo.isValid(Math.max(...times))) {
        flag = 2;
      }
      if (this.myReport) {
        const time = new Date(this.myReport.date).getTime();
        if (this.smobInfo.isValid(time)) {
          flag = 3;
        }
      }
    }
    return flag;
  }
}

export class MobReport2 {
  reporter: string;
  reportedAt: Date;
  mobId: number;
  state: number;
  isSelf: boolean;
  userName: string;
  avatarUrl: string;
  constructor(report: MobReport) {
    this.reporter = report.reporter;
    this.reportedAt = new Date(report.reportedAt);
    this.mobId = report.mobId;
    this.state = report.state;
    if (this.reporter === "") {
      // local mode
      this.userName = userModule.userName != "" ? userModule.userName : "You";
      this.avatarUrl =
        userModule.avatarUrl != "" ? userModule.avatarUrl : "/guest.png";
      this.isSelf = true;
    } else {
      // shared mode
      if (this.reporter === userModule.id) {
        this.userName = userModule.userName;
        this.avatarUrl =
          userModule.avatarUrl != "" ? userModule.avatarUrl : "/guest.png";
        this.isSelf = true;
      } else if (this.reporter === "CENTURION") {
        this.userName = "CENTURION";
        this.avatarUrl = "/Centurion.png";
        this.isSelf = false;
      } else {
        const user = sharelocationrecordsModule.members[this.reporter];
        if (!user) client.getUsers([report.reporter]);
        this.userName = user?.userName;
        this.avatarUrl =
          user && user.avatarUrl && user.avatarUrl != ""
            ? user.avatarUrl
            : "/guest.png";
        this.isSelf = false;
      }
    }
  }
  get formatDate(): string {
    return dayjs(this.reportedAt).format("MM/DD(ddd) HH:mm");
  }
  get mobName(): string {
    return messages[getLang()].mob[this.mobId];
  }
  get mobColor(): string {
    return "red";
  }
  getMobColor(zone: IZone): string {
    const mobColor = zone.getMobColors(this.mobId);
    return mobColor.color;
  }
  getClass(smobInfo: IZoneInstanceSMobInfo): string {
    if (this.reporter === "CENTURION") {
      return "pink lighten-4";
    }
    if (!smobInfo.isValid(this.reportedAt.getTime())) {
      return "grey lighten-1";
    }
    return this.isSelf ? "green lighten-4" : "blue lighten-4";
  }
}

export interface IZoneInstanceSMobInfo {
  serverReset: Date;
  respawnStart: Date;
  respawnEnd: Date;
  lastKilled: Date;
  checkedEliteLocationsCount: number;
  totalEliteLocationsCount: number;

  afterServerReset: boolean;
  chance: number;
  timeToStart: string;
  narrowDown: string;
  showNarrowDown: boolean;

  isValid(time: number): boolean;
}

export interface IAbandonedState {
  index: number;
  abandonedAt: string;
  abandoned: boolean;
}

export interface IEliteAbandonedMap {
  [mobId: number]: IAbandonedState;
}

class ZoneInstanceSMobInfo implements IZoneInstanceSMobInfo {
  constructor(
    public serverReset: Date,
    public respawnStart: Date,
    public respawnEnd: Date,
    public lastKilled: Date,
    public checkedEliteLocationsCount: number,
    public totalEliteLocationsCount: number
  ) { }
  get afterServerReset(): boolean {
    return this.lastKilled.getTime() <= this.serverReset.getTime();
  }
  get chance(): number {
    const now = dayjs();
    return now.isBefore(this.respawnStart)
      ? 0
      : now.isAfter(this.respawnEnd)
        ? 100
        : Math.floor(
          (100 * (now.toDate().getTime() - this.respawnStart.getTime())) /
          (this.respawnEnd.getTime() - this.respawnStart.getTime())
        );
  }
  get timeToStart(): string {
    const now = dayjs();
    return now.isAfter(this.respawnStart) ? `-` : now.to(this.respawnStart);
  }

  get showNarrowDown(): boolean {
    return (
      (this.totalEliteLocationsCount - this.checkedEliteLocationsCount) /
      this.totalEliteLocationsCount <
      0.8
    );
  }

  get narrowDown(): string {
    return this.showNarrowDown
      ? `${this.totalEliteLocationsCount - this.checkedEliteLocationsCount}/${this.totalEliteLocationsCount
      }`
      : "";
  }

  isValid(time: number): boolean {
    return (
      time > Math.max(this.lastKilled.getTime(), this.serverReset.getTime())
    );
  }
}

export interface IWorldState {
  vrs: string;
  id: number;
  name: string;
  dcName: string;
}

const name = "world";
const vrs = "0.2";

/*
try {
    store.unregisterModule(name)
} catch (error) {
    console.warn(`ignore ${error}`)
}
*/

const jpWorlds = worlds.filter((w) => w.dcRegion === 1);

@Module({
  dynamic: true,
  store,
  name,
  namespaced: true,
  preserveState: canPreserveDefault(name, vrs),
})
class WorldState extends VuexModule implements IWorldState {
  vrs = vrs;
  id = jpWorlds[0].id;
  name = jpWorlds[0].name;
  dcName = jpWorlds[0].dcName;

  // getter
  public get getSMobRecords(): ISMobRecord[] {
    const worldIds = sTimeTableViewModule.isDcMode
      ? worlds.filter((w) => w.dcName === this.dcName).map((w) => w.id)
      : [this.id];
    return smobrecordsModule.smobRecordsOfWorlds(worldIds);
  }

  public get getSMobRecordsOfCurrentWorld(): ISMobRecord[] {
    return smobrecordsModule.smobRecordsOfWorlds([this.id]);
  }

  public get getInstanceCount() {
    return fieldinstancesModule.getInstanceCount(this.id);
  }

  public get getLocationRecordMap() {
    return (
      zoneId: number,
      instance: number
    ): { [index: number]: LocationRecord } => {
      const zone = zones.find((zone) => zone.id == zoneId);
      if (!zone) {
        return {};
      }
      const isGroup = userModule.group != "";
      const smobInfo = this.getSMobInfo(zoneId, instance);
      let reportedAt = new Date(0);
      let mobId = 0;
      let state = 1000; // serverreset
      if (smobInfo) {
        reportedAt = new Date(
          Math.max(
            smobInfo.lastKilled.getTime(),
            smobInfo.serverReset.getTime()
          )
        );
        if (smobInfo.lastKilled.getTime() > smobInfo.serverReset.getTime()) {
          const mob = zone.mobs.find((mob) => mob.rank == "S" && mob.type == "elite");
          mobId = mob ? mob.id : 0;
          state = 1001; // lastKilled
        }
      }
      const baseReport = new MobReport2({
        reporter: "CENTURION",
        reportedAt: reportedAt.toISOString(),
        mobId: mobId,
        state: state,
      });

      const reportsMap: { [index: number]: LocationRecord } = {};
      if (!isGroup) {
        const absenceAtMap = locationabsenceModule.getIndexAbsenceMap(this.id, zoneId, instance);
        const recordMap = locationrecordsModule.getIndexRecordMap(this.id, zoneId, instance);
        zone.mobLocations.forEach((zone, index) => {
          if (zone.type === 'elite') {
            const reports = [];
            if (absenceAtMap && absenceAtMap[index]) {
              reports.push(
                new MobReport2({
                  reporter: "",
                  reportedAt: absenceAtMap[index],
                  mobId: 0,
                  state: 2000,
                })
              );
            }
            if (recordMap && recordMap[index]) {
              reports.push(
                new MobReport2({
                  reporter: "",
                  reportedAt: recordMap[index].date,
                  mobId: recordMap[index].mobId,
                  state: recordMap[index].state,
                })
              );
            }
            reports.push(baseReport);
            reports.sort((a, b) => {
              return (
                new Date(b.reportedAt).getTime() - new Date(a.reportedAt).getTime()
              );
            });
            reportsMap[index] = new LocationRecord(reports, smobInfo);
          }
        });
      }
      else {
        const recordMap = sharelocationrecordsModule.getReportMap(zoneId, instance);
        zone.mobLocations.forEach((zone, index) => {
          if (zone.type === 'elite') {
            const reports = recordMap && recordMap[index] ? recordMap[index].map((r) => new MobReport2(r)) : [];
            reports.push(baseReport);
            reports.sort((a, b) => {
              return (
                new Date(b.reportedAt).getTime() - new Date(a.reportedAt).getTime()
              );
            });
            reportsMap[index] = new LocationRecord(reports, smobInfo);
          }
        });
      }
      return reportsMap;
    }
  }

  public get getLocationRecord() {
    return (
      zoneId: number,
      instance: number,
      index: number
    ): LocationRecord => {
      const isGroup = userModule.group != "";
      const smobInfo = this.getSMobInfo(zoneId, instance);
      let reports = [];
      if (!isGroup) {
        const absenceAt = locationabsenceModule.getAbsenceAt(
          this.id,
          zoneId,
          instance,
          index
        );
        if (absenceAt) {
          reports.push(
            new MobReport2({
              reporter: "",
              reportedAt: absenceAt,
              mobId: 0,
              state: 2000,
            })
          );
        }
        const report = locationrecordsModule.getIndexRecordMap(
          this.id,
          zoneId,
          instance
        )[index];
        if (report) {
          reports.push(
            new MobReport2({
              reporter: "",
              reportedAt: report.date,
              mobId: report.mobId,
              state: report.state,
            })
          );
        }
      } else {
        reports = sharelocationrecordsModule
          .getLocationRecords(zoneId, instance, index)
          .map((r) => new MobReport2(r));
      }
      let reportedAt = new Date(0);
      let mobId = 0;
      let state = 1000; // serverreset
      if (smobInfo) {
        reportedAt = new Date(
          Math.max(
            smobInfo.lastKilled.getTime(),
            smobInfo.serverReset.getTime()
          )
        );
        if (smobInfo.lastKilled.getTime() > smobInfo.serverReset.getTime()) {
          const zone = zones.find((zone) => zone.id == zoneId);
          const mob =
            zone &&
            zone.mobs.find((mob) => mob.rank == "S" && mob.type == "elite");
          mobId = mob ? mob.id : 0;
          state = 1001; // lastKilled
        }
      }
      reports.push(
        new MobReport2({
          reporter: "CENTURION",
          reportedAt: reportedAt.toISOString(),
          mobId: mobId,
          state: state,
        })
      );
      reports.sort((a, b) => {
        return (
          new Date(b.reportedAt).getTime() - new Date(a.reportedAt).getTime()
        );
      });
      return new LocationRecord(reports, smobInfo);
    };
  }

  public get getEliteAbandonedMap() {
    return (zoneId: number, locationRecordMap: { [index: number]: LocationRecord }): IEliteAbandonedMap => {
      const zone = zones.find((zone) => zone.id == zoneId);
      if (!zone) {
        return {};
      }
      const mobIds = zone.mobs
        .filter((m) => m.type === "elite")
        .map((m) => m.id);
      const abandonedMap: IEliteAbandonedMap = {};
      Object.keys(locationRecordMap).map(Number).forEach((index) => {
        const reports = locationRecordMap[index] ? locationRecordMap[index].reports : [];
        mobIds.forEach((mobId) => {
          const latestReport = reports.find((r) => r.mobId === mobId);
          if (latestReport) {
            abandonedMap[mobId] =
              abandonedMap[mobId] &&
                new Date(abandonedMap[mobId].abandonedAt).getTime() >
                latestReport.reportedAt.getTime()
                ? abandonedMap[mobId]
                : {
                  index: index,
                  abandonedAt: latestReport.reportedAt.toISOString(),
                  abandoned: [0, 100].includes(latestReport.state),
                };
          }
        });
      });
      Object.keys(abandonedMap)
        .map(Number)
        .forEach((mobId) => {
          if (!abandonedMap[mobId].abandoned) {
            Vue.delete(abandonedMap, mobId);
          } else {
            const reports = locationRecordMap[abandonedMap[mobId].index] ? locationRecordMap[abandonedMap[mobId].index].reports : [];
            if (reports.length > 0) {
              const latest = reports[0];
              if (
                latest.mobId !== mobId ||
                (latest.state !== 0 && latest.state !== 100)
              ) {
                Vue.delete(abandonedMap, mobId);
              }
            }
          }
        });
      return abandonedMap;
    };
  }

  public get displayZoneInstaces(): string[] {
    return settingsModule.displayMobMaps(this.id);
  }

  public get getSMobInfo() {
    return (
      zoneId: number,
      instance: number
    ): IZoneInstanceSMobInfo | undefined => {
      const smobRecord = smobrecordsModule.smobRecord(this.id, zoneId, instance);
      if (smobRecord) {
        const serverReset = smobRecord.serverReset;
        const respawnStart = smobRecord.respawnStart;
        const respawnEnd = smobRecord.respawnEnd;
        const lastKilled = smobRecord.lastKilled;

        const zone = smobRecord.zone as IZone;
        const totalEliteLocationsCount = zone.mobLocations.filter((loc) =>
          loc.mobIds.includes(smobRecord.mobId)
        ).length;

        const latestDates: Date[] =
          userModule.group == ""
            ? locationrecordsModule.getLatestDates(this.id, zoneId, instance)
            : sharelocationrecordsModule.getLatestDates(zoneId, instance);

        const checkedEliteLocationsCount = latestDates.filter((date) => {
          return (
            date.getTime() >
            Math.max(serverReset.getTime(), lastKilled.getTime())
          );
        }).length;

        return new ZoneInstanceSMobInfo(
          serverReset,
          respawnStart,
          respawnEnd,
          lastKilled,
          checkedEliteLocationsCount,
          totalEliteLocationsCount
        );
      } else {
        const zone = zones.find((zone) => zone.id === zoneId);
        if (zone) {
          const serverReset = new Date(0);
          const respawnStart = new Date(0);
          const respawnEnd = new Date(1000 * 60 * 60 * 48);
          const lastKilled = new Date(0);

          const totalEliteLocationsCount = zone.mobLocations.filter(
            (loc) => loc.type == "elite"
          ).length;

          const latestDates: Date[] =
            userModule.group == ""
              ? locationrecordsModule.getLatestDates(this.id, zoneId, instance)
              : sharelocationrecordsModule.getLatestDates(zoneId, instance);

          const checkedEliteLocationsCount = latestDates.filter((date) => {
            return (
              date.getTime() >
              Math.max(serverReset.getTime(), lastKilled.getTime())
            );
          }).length;

          return new ZoneInstanceSMobInfo(
            serverReset,
            respawnStart,
            respawnEnd,
            lastKilled,
            checkedEliteLocationsCount,
            totalEliteLocationsCount
          );
        }
      }
      return undefined;
    };
  }

  public get hasAbsence() {
    return (zoneId: number, instance: number, index: number): boolean => {
      if (userModule.group == "") {
        return (
          locationabsenceModule.getAbsenceAt(
            this.id,
            zoneId,
            instance,
            index
          ) !== undefined
        );
      } else {
        const reports = sharelocationrecordsModule
          .getLocationRecords(zoneId, instance, index)
          .map((r) => new MobReport2(r));
        /*reports.sort((a, b) => {
                    return (new Date(b.reportedAt)).getTime() - (new Date(a.reportedAt)).getTime();
                });
                return reports.length > 0 && reports[0].state === 2000;
                */
        return reports.some((r) => r.isSelf && r.state === 2000);
      }
    };
  }

  // mutation
  @Mutation
  private SET_WORLD(worldId: number): boolean {
    const world = jpWorlds.find((w) => w.id === worldId);
    if (world && this.id != worldId) {
      this.id = world.id;
      this.name = world.name;
      this.dcName = world.dcName;
      return true;
    }
    return false;
  }

  @Action({ rawError: true })
  public async fetchSMobRecordsForCurrentWorld() {
    await smobrecordsModule.fetchRecords([this.id]);
  }
  @Action({ rawError: true })
  public async fetchSMobRecords() {
    const worldIds = sTimeTableViewModule.isDcMode
      ? worlds.filter((w) => w.dcName === this.dcName).map((w) => w.id)
      : [this.id];
    await smobrecordsModule.fetchRecords(worldIds);
  }
  @Action({ rawError: true })
  public syncLocationRecords(json: string | null) {
    const locationRecords = JSON.parse(json ?? "{}")["locationrecords"] ?? {};
    const newValue = locationRecords[this.id] ?? {};
    const oldValue = locationrecordsModule.getZoneInstanceIndexMap(this.id);
    if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
      locationrecordsModule.SET_RECORDS_OF_WORLD({
        worldId: this.id,
        records: newValue,
      });
    }
  }
  @Action({ rawError: true })
  public removeOldLocationRecords(payload: {
    zoneId: number;
    instance: number;
  }) {
    const smobRecords = smobrecordsModule.smobRecordsOfWorlds([this.id]);
    const smobRecord = smobRecords.find(
      (r) =>
        r.zone &&
        r.zone.id === payload.zoneId &&
        r.instance === payload.instance
    );
    if (smobRecord) {
      const serverReset = smobRecord.serverReset;
      const lastKilled = smobRecord.lastKilled;

      const recordMap = locationrecordsModule.getIndexRecordMap(
        this.id,
        payload.zoneId,
        payload.instance
      );
      const newValue: IIndexRecordMap = {};
      Object.keys(recordMap)
        .map(Number)
        .forEach((key) => {
          const record = recordMap[key];
          const isValid =
            new Date(record.date).getTime() >
            Math.max(serverReset.getTime(), lastKilled.getTime());
          console.debug(`${key} is Valid ? ${isValid}`);
          if (isValid) {
            newValue[key] = recordMap[key];
          }
        });
      locationrecordsModule.SET_RECORDS_OF_ZONE_INSTANCE({
        worldId: this.id,
        zoneId: payload.zoneId,
        instance: payload.instance,
        records: newValue,
      });
    }
  }

  @Action({ rawError: true })
  public addZoneInstance(zoneInstance: string) {
    console.debug("addZoneInstance", zoneInstance);
    if (!this.displayZoneInstaces.includes(zoneInstance)) {
      settingsModule.ADD_DISPLAY_MOBMAP({
        worldId: this.id,
        zoneInstance: zoneInstance,
      });
      client.joinZoneInstance(zoneInstance);
    }
  }

  @Action({ rawError: true })
  public deleteZoneInstance(zoneInstance: string) {
    settingsModule.DELETE_DISPLAY_MOBMAP({
      worldId: this.id,
      zoneInstance: zoneInstance,
    });
    client.leaveZoneInstance(zoneInstance);
  }

  @Action({ rawError: true })
  public async setWorld(worldId: number): Promise<boolean> {
    if (this.id == worldId) {
      return false;
    }
    const result = this.SET_WORLD(worldId);
    if (result) {
      // load locationrecords from latast localstorage
      try {
        const json = localStorage.getItem("locations");
        this.syncLocationRecords(json);
      } catch (error) {
        console.error(
          `error while loading locationrecords for ${this.name} ${error}`
        );
      }
    }
    this.fetchSMobRecordsForCurrentWorld();

    client.switchWorld(this.id, this.displayZoneInstaces);
    return result;
  }

  @Action({ rawError: true })
  public setRecord(payload: {
    zoneId: number;
    instance: number;
    index: number;
    record: RecordState;
  }) {
    if (userModule.group == "") {
      locationrecordsModule.SET_RECORD({
        worldId: this.id,
        zoneId: payload.zoneId,
        instance: payload.instance,
        index: payload.index,
        record: payload.record,
      });
      const record = locationrecordsModule.getRecord(
        this.id,
        payload.zoneId,
        payload.instance,
        payload.index
      );
      const absenceAt = locationabsenceModule.getAbsenceAt(
        this.id,
        payload.zoneId,
        payload.instance,
        payload.index
      );
      if (
        record &&
        absenceAt &&
        new Date(absenceAt).getTime() < new Date(record.date).getTime()
      ) {
        locationabsenceModule.DELETE_ABSENCE_AT({
          worldId: this.id,
          zoneId: payload.zoneId,
          instance: payload.instance,
          index: payload.index,
        });
      }
    } else {
      client.setRecord(
        payload.zoneId,
        payload.instance,
        payload.index,
        payload.record.date,
        payload.record.mobId,
        payload.record.state
      );
    }
  }

  @Action({ rawError: true })
  public deleteRecord(payload: {
    zoneId: number;
    instance: number;
    index: number;
  }) {
    if (userModule.group == "") {
      locationrecordsModule.DELETE_RECORD({
        worldId: this.id,
        zoneId: payload.zoneId,
        instance: payload.instance,
        index: payload.index,
      });
    } else {
      client.deleteRecord(payload.zoneId, payload.instance, payload.index);
    }
  }

  @Action({ rawError: true })
  public setAbsence(payload: {
    zoneId: number;
    instance: number;
    index: number;
    absenceAt: Date;
  }) {
    if (userModule.group == "") {
      locationabsenceModule.SET_ABSENCE_AT({
        worldId: this.id,
        zoneId: payload.zoneId,
        instance: payload.instance,
        index: payload.index,
        absenceAt: payload.absenceAt,
      });
    } else {
      client.setAbsence(
        payload.zoneId,
        payload.instance,
        payload.index,
        payload.absenceAt
      );
    }
  }

  @Action({ rawError: true })
  public deleteAbsence(payload: {
    zoneId: number;
    instance: number;
    index: number;
  }) {
    if (userModule.group == "") {
      locationabsenceModule.DELETE_ABSENCE_AT({
        worldId: this.id,
        zoneId: payload.zoneId,
        instance: payload.instance,
        index: payload.index,
      });
    } else {
      client.deleteAbsence(payload.zoneId, payload.instance, payload.index);
    }
  }
}

export const worldModule = getModule(WorldState);
