import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import { i18n } from "./i18n"

Vue.config.productionTip = false;

/*
store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify(state));
});
*/

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
  beforeCreate() {
//    this.$store.commit('initStore');
  }
}).$mount("#app");
